import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useStyles } from "./Style";
import { Stack, Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";

export default function Asynchronous({
  placeholder,
  ApiUrl,
  onChange,
  onBlur,
  className,
  value,
  Label,
  error,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      await axios(ApiUrl, {
        method: "GET",
      })
        .then((response) => {
          setOptions(response.data.data);
        })
    })() 
    
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Stack sx={{ width: "100%" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        className={classes.SelectLable}
      >
        {Label}
      </InputLabel>
      <Autocomplete
        {...props}
        id="asynchronous-demo"
        open={open}
        fullWidth={true}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={false}
        clearIcon={false}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        getOptionLabel={(option) => option?.name || ""}
        options={options}
        className={clsx(classes.Select, className)}
        onChange={onChange}
        value={value}
        classes={{ paper: classes.paper }}
        renderInput={(params) => (
          <TextField
            className={classes.input}
            placeholder={placeholder}
            onBlur={onBlur}
            {...params}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error ? <p className={classes.errorMessage}>{error}</p> : ""}
    </Stack>
  );
}
