import { Guarantee } from './Guarantee'
import { Loan } from './Loan'

export class Customer {
  #loans = { hasActiveSecureLoan: null, hasActiveUnsecureLoan: null }
  #guarantee = null

  constructor(customer) {
    this.#loans = customer.loans
    this.#guarantee = customer.guarantee || null
  }

  #isValidLoan (loanType) {
    const loan = new Loan()
    return loan.isValidLoanType(loanType)
  }

  #isValidGuarantee (guarantee) {
    const guaranteeType = new Guarantee()
    return guaranteeType.isValidGuaranteeType(guarantee)
  }

  #canApplyForLoan (loanType) {
    switch (loanType) {
      case 'secure':
        return !this.#loans.hasActiveSecureLoan
      case 'unsecure':
        return !this.#loans.hasActiveUnsecureLoan
      default:
        return false
    }
  }

  #evaluateSecureLoan (typeOfLoan) {
    if  (this.#isValidLoan(typeOfLoan) && this.#isValidGuarantee(this.#guarantee) && this.#canApplyForLoan(typeOfLoan)) {
      return {loanType: 'secure', collateral: this.#guarantee, canApply: true}
    }
    return null
  }

  #evaluateUnsecureLoan (typeOfLoan) {
    if  (this.#isValidLoan(typeOfLoan) && this.#canApplyForLoan(typeOfLoan)) {
      return { loanType: 'unsecure', collateral: null, canApply: true }
    }
    return null
  }

  applyForLoan (typeOfLoan) {
    switch (typeOfLoan) {
      case 'secure':
        return this.#evaluateSecureLoan(typeOfLoan)
      case 'unsecure':
        return this.#evaluateUnsecureLoan(typeOfLoan)
      default:
        return false
    }
  }
}