import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useState } from 'react'
import { InputSelect } from './InputSelect'
import { useStyles } from './Style'
// import clsx from 'clsx'


export const QuestionBullet = ({ questionInf = {}, dataForm, setData }) => {
  const classes = useStyles()
  const [selectOptions, setSelectOptions] = useState([])
  const [visible, setVisible] = useState(false)
  const [inputIdSelect, setInputIdSelect] = useState()
  const [multiple, setMultiple] = useState(false)
  const [activeRadio, setActiveRadio] = useState(false)


  // console.log(questionInf.selectOptions)
  const handleVisibleOptions = (options = [], selectId, multiple) => {
    // SetInputIdSelect()
    setActiveRadio(selectId)
    setData(() => {
      return {...dataForm, [questionInf.id]: {questionId: questionInf.id, selectId}}
    })
    // setInputIdSelect(selectId)
    setMultiple(multiple)
    if(options.length <= 0) {
      setVisible(false)
      setSelectOptions([])
      return
    }
    setSelectOptions(options)
    setVisible(true)
  }

  // useEffect(() => {
  //   setData({...select})
  // }, [select])
  
  // console.log(dataForm)
  // console.log(questionInf.id)
  // console.log(activeRadio)
  return (
    <>
      <Typography className={classes.QuestionTitle}>{questionInf?.question}</Typography>
      <Stack direction={'row'} className={classes.QuestionContenedor}>
        <Grid container spacing={2}>
          <Grid item>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              style={{flexDirection: 'column'}}
            >
              {questionInf.selectOptions.map((selectOpt) => (
                <div key={selectOpt.id}>
                  <FormControlLabel
                    value={selectOpt.id}
                    control={<Radio />}
                    label={selectOpt.option}
                    // onClick={() => handleCLickRadio(selectOpt.id)}
                    onClick={() => handleVisibleOptions(selectOpt?.dropdownOptions, selectOpt.id, ['no', 'partially'].includes(String(selectOpt?.option).toLocaleLowerCase()))}
                  />
                  <Grid>
                    {(activeRadio === selectOpt.id) &&  selectOpt?.dropdownOptions?.length > 0 && (
                    <InputSelect
                      QuestionId={questionInf?.id} //! id de la pregunta
                      options={selectOpt?.dropdownOptions}
                      SelectID={selectOpt.id} //!id del select
                      dataForm={dataForm}
                      onChange={setData}
                      multiple={['no', 'partially'].includes(String(selectOpt?.option).toLocaleLowerCase())}
                    />
                    )}
                  </Grid>
                  {/* {selectOpt?.dropdownOptions?.length > 0  && visible && (
                    <>
                      <InputSelect options={selectOpt?.dropdownOptions}/>
                    </>
                    // <Select>
                    //   {question.options.map(option => (
                    //     <option>option.label</option>
                    //   ))}
                    // </Select>
                  )} */}
                </div>
              ))}
            </RadioGroup>
          </Grid>
          {/* <Grid item sx={6} md={4}>
          {visible && selectOptions?.length > 0 && <InputSelect QuestionId={questionInf?.id} SelectID={inputIdSelect} dataForm={dataForm} onChange={setData} options={selectOptions} multiple={multiple} />}
          </Grid> */}
        </Grid>
      </Stack>
    </>
  )
}
