import Actions from "../Types"

const initialState = {
  referenceStatus: false,
  referenceNumber: false,
  token: "",
  // codeError: "",
  codeError: false,
  isLoading: false,
  referenceSuccess: false,
  referrenceError: false,
  referenceDeclined: false,
  referenceFinalized: false,
  referenceCode: 0,
  status: "",
  referenceLink: "/Customer/yourLoanRequest",
}

const ReferenceStatusReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.REFERENCESTATUS:
      return {
        ...state,
        referenceStatus: payload.referenceStatus,
      }
    case Actions.STATUS :
      return {
        ...state,
        status : payload
      }
    case Actions.REFERENCEVALIDATION:
      return {
        ...state,
        referenceNumber: payload.referenceNumber,
        token: payload.token,
      }
    case Actions.REFERENCESTATUSERROR:
      return {
        ...state,
        codeError: payload,
      }
    case Actions.REFERENCESERROR:
      return {
        ...state,
        referrenceError: payload,
      }
    case Actions.LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case Actions.REFERENCESUCCESS:
      return {
        ...state,
        referenceSuccess: payload,
      }
    case Actions.REFERENCEDECLINED:
      return {
        ...state,
        referenceDeclined: payload,
      }
    case Actions.REFERENCEFINALIZED:
      return {
        ...state,
        referenceFinalized: payload,
      }
    case Actions.REFERENCECODE:
      return {
        ...state,
        referenceCode: payload,
      }
    case Actions.REFERENCELINK:
      return {
        ...state,
        referenceLink: payload,
      }
    case Actions.CLEAR_STATE_REFERENCE_NUMBER:
      return initialState

    default:
      return state
  }
}

export default ReferenceStatusReducer
