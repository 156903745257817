import React from "react";
import Button from "@mui/material/Button";
import { Typography, styled } from "@mui/material";
import { useStyles } from "../../Button/Style";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import clsx from "clsx";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F07F3C",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    maxWidth: 450,
    filter: "drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))",
    marginLeft: "0",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#F07F3C",
    "&::before": {
      backgroundColor: "#F07F3C",
    },
  },
}));

const Btn = ({
  className,
  Title,
  variant,
  Tooltiptitle,
  BtnVariant,
  backgroundColor,
  endIcon,
  startIcon,
  onClick,
  style
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)

  return (
    <LightTooltip
      title={Tooltiptitle ? Tooltiptitle : ""}
      arrow
      placement="top-end"
      backgroundcolor={backgroundColor}
      open={open}
      onClick={() => setOpen(!open)}
    >
      <Button
        variant={BtnVariant}
        className={clsx(classes.Button, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        style={style}
      >
        <Typography variant={variant} className={classes.btnTitle}>
          {Title}
        </Typography>
      </Button>
    </LightTooltip>
  );
};

export default Btn;
