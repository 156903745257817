
import { Card, CardContent, Grid } from "@mui/material"
import HeaderCurrentLoan from "../../molecules/HeaderCurrentLoan/HeaderCurrentLoan"
import InfoCard from "../../molecules/InfoCard/InfoCard";
import { FiberManualRecord } from '@mui/icons-material';

//Icons
import Calendar from '../../../../assets/Icons/calendar-icon.png'
import CalendarTerm from '../../../../assets/Icons/video-time.png'
import Amount from '../../../../assets/Icons/coin.png'
import InfoError from '../../../../assets/Icons/Error-Icon.png'
import InfoSuccess from '../../../../assets/Icons/calendar-circle.png'

//styles
import { useStyles } from './CardCurrentLoanStyles'
import CurrentLoanDetails from "../../molecules/CurrentLoanDetails/CurrentLoanDetails";
import Separator from "../../atoms/Separator/Separator";

const CardCurrentLoan = (
    {
        ShowInfoCard,
        ShowContinueButton, 
        TypeInfoCard, 
        CurrentDetails, 
        LoanType, 
        LoanStatus, 
        warrantyType, 
        actionCardInfo,
        actionContinue,
        approvalLetter, 
        AppointmentDate, code}) => {
    const classes = useStyles();

    const InfoCardError = {
        Icon: InfoError,
        Title: `Sorry, your application has expired.  Please start a new application.`, 
        ShowButton: false,
        color:"#FFCFCF"
    }
    const InfoCardSuccess = {
        Icon: InfoSuccess,
        Title: `Your loan appointment is scheduled for ${AppointmentDate} branch.`, 
        ShowButton: true,
        color: "#EEF7EA"
    }
    console.table(CurrentDetails)
    return (
        <Card className={classes.card}>
            <div className={classes.greenLine}>
                {/* <FiberManualRecord /> */}
            </div>
            <CardContent className={classes.content}>
                <Grid container> 
                    <HeaderCurrentLoan warrantyType={warrantyType} LoanType={LoanType} LoanStatus={LoanStatus} Action={actionContinue} ShowContinueButton={ShowContinueButton} ApprovalLetter={approvalLetter} code={code} />
                    {
                        ShowInfoCard &&
                        <Grid item xs={12} md={12} style={{marginTop: "40px"}}>
                            <InfoCard InfoCardData={TypeInfoCard === 'Success' ? InfoCardSuccess : InfoCardError} Action={actionCardInfo}  />
                        </Grid>
                    }
                    <Separator />
                    <CurrentLoanDetails Icon={Calendar} Key={'START DATE'} Value={CurrentDetails.startDate}/>
                    <CurrentLoanDetails Icon={Amount} Key={'AMOUNT'} Value={CurrentDetails.amount}/>
                    <CurrentLoanDetails Icon={Calendar} Key={'SUBMISSION DATE'} Value={CurrentDetails.submissionDate}/>
                    <CurrentLoanDetails Icon={CalendarTerm} Key={'TERM'} Value={CurrentDetails.termn}/>
                </Grid>
            </CardContent>
        </Card>
    )
  
  }
    
  export default CardCurrentLoan