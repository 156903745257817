import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useStyles } from './MonthlyIncomeStyle'
import { InputLabel, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import Input from '../../Inputs/Input'
import InputWithTooltip from './Input'
import Radio from '@mui/material/Radio'
import ArrowButton from '../../ArrowButton/ArrowButton'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Total from '../../Inputs/Total2'
import { validation } from './vallidations'
import { useFormik, Form, FormikProvider, Field } from 'formik'
import Currency from '../../Inputs/currency2'
import { Interceptor } from '../../../Utils/Interceptor'
import { useRef } from 'react'
import { useSelector } from 'react-redux'

const MonthlyIncome = () => {
  const classes = useStyles()
  const [total, setTotal] = useState(0)
  const AxiosInterceptor = Interceptor()
  const defaultFormValues = {
    monthlyGrossIncomeFromEmployement: '0',
    pension: '0',
    rentalIncome: '0',
    monthlyNetIncomeFromBusiness: '0',
    alimony: '0',
    consultancy: '0',
    allowance: '0',
    comission: '0',
    specifyTheNatureOfOtherIncome: '',
    actualAmount: '0'
  }
  const [initialMonthlyIncome, setInitialMonthlyIncome] =
    useState(defaultFormValues)
  const [isValid, setIsValid] = useState(true)
  const [isChecked, setChecked] = useState(false)
  const [formId, setId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [error, setError] = useState('')
  const [radioButtonError, setRadioButtonError] = useState('')
  const countPage = useRef(0)
  const { steepLinks } = useSelector((state) => state.steeps)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  const getData = async () => {
    const AxiosInterceptor = Interceptor()
    await AxiosInterceptor.get('/monthly-incomes')
      .then((response) => {
        if (response.data) {
          const formValues = {
            monthlyGrossIncomeFromEmployement: parseFloat(
              response.data.monthlyGrossIncomeFromEmployement
            ).toFixed(2),
            pension: parseFloat(response.data.pension).toFixed(2),
            rentalIncome: parseFloat(response.data.rentalIncome).toFixed(2),
            monthlyNetIncomeFromBusiness: parseFloat(
              response.data.monthlyNetIncomeFromBusiness
            ).toFixed(2),
            alimony: parseFloat(response.data.alimony).toFixed(2),
            consultancy: parseFloat(response.data.consultancy).toFixed(2),
            allowance: parseFloat(response.data.allowance).toFixed(2),
            comission: parseFloat(response.data.comission).toFixed(2),
            specifyTheNatureOfOtherIncome:
              response.data.specifyTheNatureOfOtherIncome,
            actualAmount: parseFloat(response.data.actualAmount).toFixed(2)
          }
          setCustomerId(response.data.customerId)
          setId(response.data.id)
          setInitialMonthlyIncome(formValues)
          setChecked(response.data.allInformationIsAccurate)
        } else {
          setInitialMonthlyIncome(defaultFormValues)
          setChecked(false)
        }
      })
      .catch((e) => {
        if (e.response.status == 404) {
          setInitialMonthlyIncome(defaultFormValues)
          setChecked(false)
        }
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialMonthlyIncome,
    validationSchema: validation,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })
  useEffect(() => {
    let amount = 0
    if (
      formik.values.monthlyGrossIncomeFromEmployement !== '0' &&
      formik.values.monthlyGrossIncomeFromEmployement !== ''
    ) {
      amount =
        amount + parseFloat(formik.values.monthlyGrossIncomeFromEmployement)
    }

    if (formik.values.pension !== '0' && formik.values.pension !== '') {
      amount = amount + parseFloat(formik.values.pension)
    }

    if (
      formik.values.rentalIncome !== '0' &&
      formik.values.rentalIncome !== ''
    ) {
      amount = amount + parseFloat(formik.values.rentalIncome)
    }

    if (
      formik.values.monthlyNetIncomeFromBusiness !== '0' &&
      formik.values.monthlyNetIncomeFromBusiness !== ''
    ) {
      amount = amount + parseFloat(formik.values.monthlyNetIncomeFromBusiness)
    }

    if (formik.values.alimony !== '0' && formik.values.alimony !== '') {
      amount = amount + parseFloat(formik.values.alimony)
    }

    if (formik.values.consultancy !== '0' && formik.values.consultancy !== '') {
      amount = amount + parseFloat(formik.values.consultancy)
    }

    if (formik.values.allowance !== '0' && formik.values.allowance !== '') {
      amount = amount + parseFloat(formik.values.allowance)
    }

    if (formik.values.comission !== '0' && formik.values.comission !== '') {
      amount = amount + parseFloat(formik.values.comission)
    }

    if (
      formik.values.actualAmount !== '0' &&
      formik.values.actualAmount !== ''
    ) {
      amount = amount + parseFloat(formik.values.actualAmount)
    }

    setTotal(Number(amount))
  }, [formik.values])

  useEffect(() => {
    if (total > 0) {
      if (!isChecked) {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    } else {
      setIsValid(false)
    }
  }, [total, isChecked])

  const handleSubmit = () => {
    if (total > 0) {
      setError('')
      if (!isChecked) {
        setIsValid(false)
        setRadioButtonError('This field is mandatory ')
      } else {
        setRadioButtonError('')
        submit().then(function (result) {
          setIsValid(true)
        })
      }
    } else {
      setError('Monthly income amount is required to procced')
    }
  }
  const submit = async () => {
    const AxiosInterceptor = Interceptor()
    const formData = {
      monthlyGrossIncomeFromEmployement:
        parseFloat(formik.values.monthlyGrossIncomeFromEmployement) != null
          ? parseFloat(formik.values.monthlyGrossIncomeFromEmployement)
          : 0,
      pension:
        parseFloat(formik.values.pension) != null
          ? parseFloat(formik.values.pension)
          : 0,
      rentalIncome:
        parseFloat(formik.values.rentalIncome) != null
          ? parseFloat(formik.values.rentalIncome)
          : 0,
      monthlyNetIncomeFromBusiness:
        parseFloat(formik.values.monthlyNetIncomeFromBusiness) != null
          ? parseFloat(formik.values.monthlyNetIncomeFromBusiness)
          : 0,
      alimony:
        parseFloat(formik.values.alimony) != null
          ? parseFloat(formik.values.alimony)
          : 0,
      consultancy:
        parseFloat(formik.values.consultancy) != null
          ? parseFloat(formik.values.consultancy)
          : 0,
      allowance:
        parseFloat(formik.values.allowance) != null
          ? parseFloat(formik.values.allowance)
          : 0,
      comission:
        parseFloat(formik.values.comission) != null
          ? parseFloat(formik.values.comission)
          : 0,
      actualAmount:
        parseFloat(formik.values.actualAmount) != null
          ? parseFloat(formik.values.actualAmount)
          : 0,
      specifyTheNatureOfOtherIncome:
        formik.values.specifyTheNatureOfOtherIncome,
      total: parseFloat(total),
      allInformationIsAccurate: true
    }
    if (formId == '') {
      await AxiosInterceptor.post('/monthly-incomes', formData)
    } else {
      await AxiosInterceptor.put(`/monthly-incomes/${formId}`, {
        ...formData,
        customerId: customerId
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  const onChangeInput = (e, value, field) => {
    const { name } = field
    let inputValue = value
    if (value === '' || !value.length) {
      inputValue = '0'
    }
    formik.setFieldTouched(name)
    formik.setFieldValue(name, inputValue)
  }

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form>
          <Box className={classes.MonthlyIncomeContainer}>
            <Box className={classes.MonthlyIncomeHeader}>
              <Typography>
                PLEASE COMPLETE THE INFORMATION BELOW WITH YOUR MONTHLY INCOME
              </Typography>
            </Box>
            <Stack className={classes.errorMessage}>
              <Typography>{error}</Typography>
            </Stack>
            <Stack direction='column' spacing={4} mt={4} mb={5}>
              <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                <Field name='monthlyGrossIncomeFromEmployement'>
                  {({ field, form, meta }) => (
                    <>
                      <Currency
                        Lable='MONTHLY GROSS INCOME FROM EMPLOYMENT'
                        error={meta.touched && meta.error}
                        onChange={(e, value) => onChangeInput(e, value, field)}
                        name={field.name}
                        value={meta.value}
                      />
                    </>
                  )}
                </Field>
              </Stack>
              <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                <Field name='pension'>
                  {({ field, form, meta }) => (
                    <>
                      <Currency
                        Lable='MONTHLY PENSION INCOME'
                        error={meta.touched && meta.error}
                        onChange={(e, value) => onChangeInput(e, value, field)}
                        name={field.name}
                        value={meta.value}
                      />
                    </>
                  )}
                </Field>
              </Stack>
              <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                <Field name='rentalIncome'>
                  {({ field, form, meta }) => (
                    <>
                      <Currency
                        Lable='MONTHLY RENTAL INCOME'
                        error={meta.touched && meta.error}
                        onChange={(e, value) => onChangeInput(e, value, field)}
                        name={field.name}
                        value={meta.value}
                      />
                    </>
                  )}
                </Field>
              </Stack>
              <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                <Field name='monthlyNetIncomeFromBusiness'>
                  {({ field, form, meta }) => (
                    <>
                      <InputWithTooltip
                        Lable='MONTHLY NET INCOME FROM BUSINESS'
                        error={meta.touched && meta.error}
                        onChange={(e, value) => onChangeInput(e, value, field)}
                        name={field.name}
                        value={meta.value}
                      />
                    </>
                  )}
                </Field>
              </Stack>
            </Stack>
            <Accordion elevation={0} className={classes.SupplyIncome}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.paragraph}>
                  SUPPLEMENTAL MONTHLY INCOME
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction='column' spacing={4} mt={4} mb={5}>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Field name='alimony'>
                      {({ field, form, meta }) => (
                        <>
                          <Currency
                            Lable='MONTHLY ALIMONY INCOME'
                            error={meta.touched && meta.error}
                            onChange={(e, value) =>
                              onChangeInput(e, value, field)
                            }
                            name={field.name}
                            value={meta.value}
                          />
                        </>
                      )}
                    </Field>
                  </Stack>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Field name='consultancy'>
                      {({ field, form, meta }) => (
                        <>
                          <Currency
                            Lable='MONTHLY CONSULTANCY INCOME'
                            error={meta.touched && meta.error}
                            onChange={(e, value) =>
                              onChangeInput(e, value, field)
                            }
                            name={field.name}
                            value={meta.value}
                          />
                        </>
                      )}
                    </Field>
                  </Stack>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Field name='allowance'>
                      {({ field, form, meta }) => (
                        <>
                          <Currency
                            Lable='MONTHLY ALLOWANCES'
                            error={meta.touched && meta.error}
                            onChange={(e, value) =>
                              onChangeInput(e, value, field)
                            }
                            name={field.name}
                            value={meta.value}
                          />
                        </>
                      )}
                    </Field>
                  </Stack>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Field name='comission'>
                      {({ field, form, meta }) => (
                        <>
                          <Currency
                            Lable='MONTHLY COMMISSIONS'
                            error={meta.touched && meta.error}
                            onChange={(e, value) =>
                              onChangeInput(e, value, field)
                            }
                            name={field.name}
                            value={meta.value}
                          />
                        </>
                      )}
                    </Field>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} className={classes.Other}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.paragraph}>
                  OTHER (please specify)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction='column' spacing={4} mt={4} mb={5}>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Input
                      Lable='SPECIFY THE NATURE OF OTHER INCOME'
                      name='specifyTheNatureOfOtherIncome'
                      value={formik.values.specifyTheNatureOfOtherIncome}
                      error={
                        formik.touched.specifyTheNatureOfOtherIncome &&
                        formik.errors.specifyTheNatureOfOtherIncome
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Stack>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <Field name='actualAmount'>
                      {({ field, form, meta }) => (
                        <>
                          <Currency
                            Lable='MONTHLY AMOUNT'
                            error={meta.touched && meta.error}
                            onChange={(e, value) =>
                              onChangeInput(e, value, field)
                            }
                            name={field.name}
                            value={meta.value}
                          />
                        </>
                      )}
                    </Field>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Box className={classes.Total}>
              <Total total={total} />
              <small style={{ color: 'red', fontFamily: 'gillsansMedium' }}>
                {total < 900 && (
                  <b>
                    * Please note that it must be equal to or greater than 900
                  </b>
                )}
              </small>
            </Box>
            <Stack
              className={classes.RadioBtn}
              direction='row'
              alignItems='center'
              mt={4}
            >
              <Radio
                checked={isChecked}
                onClick={() => setChecked(!isChecked)}
              />

              <InputLabel className={classes.RadioLabel}>
                I confirm that all the information provided above is true and
                accurate.
              </InputLabel>
            </Stack>
            <Stack className={classes.otherErrorMessage}>
              <Typography>{radioButtonError}</Typography>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
      <ArrowButton
        disabled={total < 900}
        link={isValid ? '/Customer/MonthlyCreditObligations' : ''}
        back={
          sessionStorage.getItem('loan') === 'secure' &&
          sessionStorage.getItem('warrantyTypeID') === '1'
            ? '/Customer/MonthlyInstallments'
            : sessionStorage.getItem('loan') === 'secure' &&
              sessionStorage.getItem('warrantyTypeID') === '2'
            ? '/Customer/vehicle-status'
            : sessionStorage.getItem('loan') === 'secure' &&
              sessionStorage.getItem('warrantyTypeID') === '3'
            ? '/Customer/land-details'
            : sessionStorage.getItem('loan') === 'refinancing' ? '/Customer/refinance-request' : '/Customer/yourLoanRequest'
        }
        msg='Please note that the total monthly income must be equal to or greater than BZ$900.'
        onClick={() => {
          AxiosInterceptor.post('/customers/step', {
            currentStep:
              sessionStorage.getItem('warrantyTypeID') === '3' &&
              sessionStorage.getItem('loan') === 'secure'
                ? 4
                : 4
          })
            .then((response) => {
              console.log(response)
            })
            .catch((e) => console.log(e))
          formik.submitForm()
        }}
      />
    </Box>
  )
}

export default MonthlyIncome
