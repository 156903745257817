import React from 'react'
import { Box } from '@mui/system'
import { useStyles } from './Style'
import InputLabel from '@mui/material/InputLabel'
import { styled, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor = '#F07F3C' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor,
    color: 'white',
    fontSize: 13,
    maxWidth: 350,
    filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
    // marginLeft: "-13rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor,
    '&::before': {
      backgroundColor: backgroundColor,
    },
  },
}))
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'BZD',
})
const Total = ({
  Text,
  className,
  Lable,
  style,
  iconTolltip = true,
  messageToltip = '',
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  return (
    <Box>
      <InputLabel
        shrink
        // htmlFor="bootstrap-input"
        className={classes.LableEstimatedMonthly}
      >
        {Lable}
        {iconTolltip ? (
          <span>
            <LightTooltip
              title={messageToltip}
              arrow
              placement="top"
              sx={{ width: '350px', maxWidth: '350px' }}
              open={open}
              onClick={() => setOpen(!open)}
            >
              <Typography variant={'p'} className={classes.btnTitle}>
                <InfoIcon style={{ marginLeft: 10, color: '#F07F3C' }} />
              </Typography>
            </LightTooltip>
          </span>
        ) : (
          ''
        )}
      </InputLabel>
      <Box className={[classes.MainContainer, className].join(' ')}>
        <Typography variant="h6" className={classes.value} style={style}>
          {formatter.format(Text)}
        </Typography>
      </Box>
    </Box>
  )
}

export default Total
