import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	Container: {
		padding: "2.07rem 1.28rem",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		["@media (max-width: 1100px)"]: {
			width: "auto",
		},
	},
	SelectOptionWrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "4.19rem",
		background: "#F07F3C",
		textAlign: "center",
		"@media (max-width: 509px)": {
			padding: "30px 0px",
		},
	},
	SelectText: {
		"&.MuiTypography-root": {
			fontSize: "1.5rem",
			fontWeight: "400",
			lineHeight: "1.8rem",
			letterSpacing: "0.2rem",
			color: "#FFF",
			fontFamily: theme.fonts.family.primary,
		},
	},
	CardWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		marginTop: 45,
		margin: "auto",
	},
}));
