import React, { useState, useEffect } from 'react'
import { useStyles } from '../styles/'
import { Box, Stack } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import LoanDetails from '../loan-details'
import Appointments from '../appointments'

const LoanCalculator = () => {
  const { loanOptions, values } = useSelector((state) => state.cashLoanCollateral)

  const dispatch = useDispatch()
  const classes = useStyles()

  //! Validar que el monto y los terminos sean mayores o iguales a los requeirdos para enviarlo a la API
  // ejemplo de datos a enviar
  // const formData = {
  //     requestedAmount: Number(amount),
  //     requestedTerms: Number(terms),
  //     interestRate: 1,
  //     debtCancellationSchemeRequested: false
  //   }

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  useEffect(() => {
    // TODO: si el email fue enviado, se limpia el token, y se oculta el boton de siguiente
    // if (dataSchedule.emailSent ?? false) {
    //   setShowButtonLink(false)
    //   LocalStorageService.clearToken()
    // }
  }, [])
  // TODO: validar que el monto y los terminos sean mayores a 0, si es asi, se habilita el boton de siguiente

  const [requiredAppointment, setRequiredAppointment] = useState({
    required: false,
    message: ''
  })

  useEffect(() => {
    if (loanOptions.amount > 50000 && loanOptions.terms > 120) {
      setRequiredAppointment({
        required: true,
        message: '$50,000 and more than 120 months'
      })
      return
    } else if (loanOptions.amount <= 50000 && loanOptions.terms > 120) {
      setRequiredAppointment({
        required: true,
        message: '120 months'
      })
      return
    } else if (loanOptions.amount > 50000 && loanOptions.terms <= 120) {
      setRequiredAppointment({
        required: true,
        message: '$50,000'
      })
      return
    } else {
      setRequiredAppointment({
        required: false,
        message: ''
      })
      return
    }
  }, [loanOptions.amount, loanOptions.terms, values])

  return (
    <Box>
      <Box className={classes.LoanContainer}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width='100%'>
          <LoanDetails />
        </Stack>
        {requiredAppointment.required && (
          <Appointments message={requiredAppointment.message} />
        )}
        {!requiredAppointment.required && (
          null
        )}
      </Box>

      {/*
        // <ArrowButton link="/Customer/monthlyIncome" />
          //<ArrowButton link={nextLink} back="/Customer/letsMeet" />
         */}
    </Box>
  )
}

export default LoanCalculator
