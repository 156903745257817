import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  SecondButton: {
    "&.MuiButtonBase-root": {
      height: "52.22px",
      width: '100%',

      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        // backgroundColor: '#F4EBFE !important',
        // color: theme.colors.primary.Darkblue
      },
      "& .MuiTypography-root": {
        fontSize: "14.96px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 1100px)": {
        margin: "0",
      },
      "@media (max-width: 500px)": {
        width: "100%",
        marginBottom: 20,
      },
    },
  },
  
}));

