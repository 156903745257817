import Actions from "../Types";
import { Interceptor } from "../../Utils/Interceptor";


export function ScheduleRequest(data) {
   
  const AxiosInterceptor = Interceptor();
  return async (dispatch, getState) => {
    const url = "/loan-requests/appointment";
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        if(response.data) {
        
          // dispatch({
          //   type: Actions.SCHEDULE,
          //   payload: response.data,
          // })
          dispatch({
            type: Actions.SCHEDULELOANDREQUEST,
            payload: response.data,
          })
         
        } 
      })
      .catch((e) => {
      
      }).finally(() => {
        dispatch({ type: Actions.LOADING, payload: false })
      })
  };
}
