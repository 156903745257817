import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useStyles } from "./TermsAndConditionsStyle";
import Recomend from "../../assets/Images/Recomend.png";
import { ContentData } from "./ContentTextData";
import { List } from "./ListData";
import Button from "../Button/Button";
import { Link, useParams, useNavigate  } from "react-router-dom";

const TermsAndConditions = (props) => {
  const classes = useStyles();
  const { loanRequestId } = useParams();
  const Paragraph = (data) => {
  }

  const navigate = useNavigate()

  return (
			<Box className={classes.TermsAndConditionsContainer}>
				<Box className={classes.TermsAndConditionsHeader}>
					<Typography>TERMS AND CONDITIONS</Typography>
				</Box>
				<Stack direction="column" justifyContent="center" alignItems="center">
					<Stack className={classes.BenefitList}>
						<Box className={classes.Header}>
							<Typography>DEBT CANCELLATION SCHEME</Typography>
						</Box>
						<Box className={classes.BenefitListHeader}>
							<Typography>Debt Cancellation Scheme benefits:</Typography>
						</Box>
						<Stack direction="column" mt={4} spacing={4} className={classes.Lists}>
							{List.map((items) => (
								<Stack direction="row" spacing={1} alignItems="start" className={classes.ListItem}>
									<img className={classes.ListItemImage} src={items.image} alt="" />
									<Typography>{items.text}</Typography>
								</Stack>
							))}
						</Stack>
					</Stack>
					<Stack direction="column" className={classes.Content} spacing={4}>
						{ContentData.map((data, index) => (
							<Typography key={index}>
								{data.id === "1"
									? `TERMS AND CONDITIONS: ${data.text}`
									: data.text}
							</Typography>
						))}
					</Stack>
					<Link to={loanRequestId === "secure" ? "/customer/MonthlyInstallments" :  "/Customer/yourLoanRequest"} className={classes.Links}>
						<Button className={classes.BackBtn} Title="BACK" onClick={() => navigate(-1)} />
					</Link>
				</Stack>
			</Box>
		);
};

export default TermsAndConditions;
