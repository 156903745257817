import React from 'react'
import { useStyles } from './SmallProgressBarStyle'

const SmallProgressBar = ({ progress }) => {
  const classes = useStyles()

  const fillerStyles = {
    height: '17px',
    width: `${progress}%`,
    textAlign: 'right',
    borderRadius: '2.6875rem',
    background: 'linear-gradient(90deg, #275DA6 0%, #8DA6FF 100%)',
    position: 'absolute',
    top: '-8%'
  }

  return (
    <div className={classes.containerStyles}>
      <span className={classes.labelStyles}>{`${Math.round(progress)}%`}</span>
      <div style={{ position: 'relative', margin: '2% 0' }}>
        <div className={classes.grayBgStyles} />
        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>0%</span> <span>100%</span>
        </p>
        <div style={fillerStyles}></div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <div style={{width: '2.25rem', height: '0.875rem', flexShrink: '0', background: '#EBEBEB' }}></div>
          <p className={classes.Label}>Total requested amount</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <div style={{width: '2.25rem', height: '0.875rem', flexShrink: '0', background: '#275DA6'}}></div>
          <p className={classes.Label}>Cash Collateral</p>
        </div>
      </div>
    </div>
  )
}

export default SmallProgressBar
