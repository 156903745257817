import React, { useEffect, useState } from "react"
import { Box, Typography, useTheme } from "@mui/material"
import VerificationInput from "../Inputs/VerificationInput"
import Image from "../../assets/Images/Group31.png"
import clock from "../../assets/Images/clock.png"
import { useStyles } from "./ReferencePopUpStyle.js"
import Button from "@mui/material/Button"
import { referenceValidation } from "../../Store/Actions/ReferenceValidation"
import { useDispatch, useSelector } from "react-redux"
import PopupAlert from "./PopupAlert"
import PopupSuccess from "./PopupSuccess"
import ArrowButton from "../ArrowButton/ArrowButton"
import Actions from "../../Store/Types"
import { useRef } from "react"
import LocalStorageService from "../../Utils/LocalStoreServices"
import InfoStatusReference from "./InfoStatusReference"

export const ConfirmReference = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [pin, setPin] = useState("")
  const classes = useStyles()
  const countPage = useRef(0)

  // const phone = useSelector((state) => state.LetsMeetFormReducer.phone);
  const success = useSelector(
    (state) => state.ReferenceStatusReducer.referenceSuccess
  )
  const { referenceDeclined: declined, referenceFinalized: finalized } =
    useSelector((state) => state.ReferenceStatusReducer)


  useEffect(() => {
    if (countPage.current === 0) {
      LocalStorageService.clearToken()
      countPage.current = 1
      dispatch({ type: Actions.REFERENCESUCCESS, payload: false })
      dispatch({ type: Actions.REFERENCEDECLINED, payload: false })
      dispatch({ type: Actions.REFERENCEFINALIZED, payload: false })
    }
  }, [])

  const getBBLRef = () => {
    LocalStorageService.clearToken()
    dispatch(referenceValidation(`BBL${pin}`))
  }

  return (
    <Box mt={10}>
      <PopupAlert />
      <Box className={classes.PopupContainer}>
        <Box className={classes.PopupHeader}></Box>

        <Box className={classes.PopupMainSection}>
          <Box className={classes.PopupHeaderLogo}>
            <img className={classes.Logo} src={Image} alt="Logo" />
          </Box>
          <Typography className={classes.PopupText}>
            Please enter the reference number
          </Typography>
          <Box className={classes.Code}>
            <Typography variant="h5">BBL -</Typography>
            <VerificationInput
              length={6}
              onChange={(e) => setPin(e)}
              sx={{
                borderRadius: "8px",
                fontSize: "16px",
              }}
            />
          </Box>
          <Box className={classes.PopupExpireTime}></Box>
          <Box className={classes.PopupBtn}>
            <Button
              className={classes.PopupButton}
              onClick={getBBLRef}
              sx={{ fontSize: "13px" }}
            >
              CONFIRM
            </Button>
          </Box>
        </Box>
      </Box>
      {success && (
        <Box>
          <PopupSuccess />
        </Box>
      )}
      {declined && (
        <Box>
          <InfoStatusReference />
        </Box>
      )}
      {finalized && (
        <Box>
          <InfoStatusReference />
        </Box>
      )}
    </Box>
  )
}
