import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  Contenedor: {
    margin: '2rem 2rem 0 2rem'
  },
  QuestionTitle: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: '27px',
    color: '#706F6F',
  },
  FeedBackTitle: {
    fontFamily: 'Gill Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15.041px',
    lineHeight: '18px',
    textAlign: 'center',
    letterSpacing: '0.2em',
    color: '#005FAA',
    marginBottom: '4rem !important'
  },
  QuestionContenedor: {
    marginBottom: '3rem !important'
  },
  Btn: {
    minWidth: '200px',
    width: '250px'
  }
}))
