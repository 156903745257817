import React from 'react'
import AlertModal from '../../Components/AlertModal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AgeLimit = () => {
  const { ageLimit } = useSelector((state) => state.LoanRequestReducer)
  const navigate = useNavigate()
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
    localStorage.clear()
  }, [])

  React.useEffect(() => {
    if (ageLimit?.message) {
      setShowModal(true)
    } else {
      setShowModal(false)
      navigate('/')
    }
  }, [ageLimit])

  return (
    <>
      {showModal && (
        <AlertModal
          errorMessage={ageLimit?.message}
          title="Age Limit"
          handleClose={() => navigate('/')}
          showModal={true}
        />
      )}
    </>
  )
}

export default AgeLimit
