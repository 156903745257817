import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasUrbanAreas = createAsyncThunk(
  'appUrbanAreas/UrbanAreas',
  async (id) => {
    const response = await Jwt.getUrbanAreas(id)
    return response.data
  }
)

const initialState = {
    totalCount: 0,
    filteredCount: 0,
    urbanAreas: []
}

export const appUrbanAreasSlice = createSlice({
  name: 'appUrbanAreas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasUrbanAreas.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount
      state.filteredCount = action.payload.filteredCount
      state.urbanAreas = action.payload.urbanAreas
    })
  }
})

// export const { handleGetBrands } = appUrbanAreasSlice.actions

export default appUrbanAreasSlice.reducer
