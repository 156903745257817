// const mockData = {
//   20: {
//     id: 20,
//     type: 'UPDATE',
//     select: {
//       id: 2,
//       assetId: 2
//     },
//     text: {
//       value: 9119.01
//     },
//     customerId: 'pIBbdwgvQ3KK935u2sgS7Q-510'
//   }
// }

const initialAmountAdapter = (data = {}) => {
  if (!data) return {}
  const initialValues = {}
  Object.keys(data).forEach((key) => {
    const input = data[key]
    // initialValues[`assets-${key}`] = input?.select
    initialValues[`amount-${key}`] = input?.text?.value
  })

  return initialValues
}

export default initialAmountAdapter
