import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './ScheduleAppointmentStyle3'
import Calendar from '../../../assets/Images/Calendar.png'
import { Typography } from '@material-ui/core'
import Button from '../../Button/Button'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ModalRefFriend from '../SignDocuments/ModalRefFriend'
import { useState } from 'react'
import { useEffect } from 'react'

const AppointmentPending = ({ customer, appointment }) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    sessionStorage.setItem('customer_schedule', JSON.stringify({}))
  }, [])


  // ! COMPONENTE USADO EN /Customer/ScheduleAppointment
  const handleOpen = () => {
    setOpenModal(true)
  }
  return (
    <Box>
        <ModalRefFriend open={openModal} setOpen={setOpenModal} link={`/?q=${sessionStorage.getItem('code')}`}/>
        {customer?.lastName && (
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouText}>
            <strong>
              Dear {`${customer?.firstName ? customer?.firstName : ''} ${customer?.lastName ? customer?.lastName : ''}`}
            </strong>{' '}
            <br />
            Your appointment has been successfully scheduled. Please visit
            {` ${appointment?.branchName}`} branch on{' '}
            {moment(appointment?.appointmentDate).format('DD-MM-YYYY')}{' at '}
            {moment(appointment?.appointmentTime).format('LT')} Thank you for
            giving us the opportunity to serve you.
          </Typography>
          <Box className={classes.ThankYouBtn}>
            {/* <Link to="/" className={classes.Link}> */}
              <Button Title="THANK YOU" onClick={ handleOpen }/>
            {/* </Link> */}
          </Box>
        </Box>
      </Box>
        )}
    </Box>
  )
}

export default AppointmentPending
