import * as yup from 'yup'

export const validationSchema = yup.object({
  File: yup.mixed().when('jobLetter', (val, schema) => {
    if (val?.id == 1) {
      return yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    } else {
      return yup.mixed().notRequired()
    }
  }),
  File2: yup.mixed().when('jobLetter', (val, schema) => {
    if (val?.id == 3) {
      return yup
        .mixed()
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    } else {
      return yup.mixed().notRequired()
    }
  }),
  email: yup.string().when('jobLetter', (val, schema) => {
    if (val?.id == 2) {
      return yup
        .string('Email should be string')
        .email('Incorrect email format. Please correct')
        .required('Email is Required')
    } else return yup.string().notRequired()
  }),
  confirm_email: yup.string().when('jobLetter', (val, schema) => {
    if (val?.id == 2) {
      return yup
        .string('Email should be string')
        .email('Incorrect email format. Please correct')
        .required('Email is Required')
        .oneOf([yup.ref('email')], 'E-mails do not match, please correct.')
    } else return yup.string().notRequired()
  }),
  bank: yup.object('').when('salary', (val, schema) => {
    // console.log(val)
    if (val?.id === 2) {
      return yup.object('').required('**Please select financial institution')
    } else return yup.string().notRequired()
  }),
  jobLetter: yup.mixed().when('salary', (val, schema) => {
    if (val?.id !== 4) return yup.object('').required('**Please select job letter')
    else return yup.string().notRequired()
  }),
  // jobLetter: yup.object('').required('**Please select job letter'),
  salary: yup.object('').required('**Please select salary'),
})
export const convertToUpload = (theBlob, filename) => {
  if (theBlob != null) {
    var arr = theBlob.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  } else {
    return null
  }
}
