import React, { useState, useLayoutEffect, useEffect, Fragment } from 'react'
import { useStyles } from './ProcessStepsStyle'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import {
  ProcessArray,
  ProcessArraySecuredCash,
  ProcessArraySecuredVehicle,
  ProcessArraySecuredLand,
  ProcessArrayDebtRefinance
} from './ProcessArray'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { getLinks } from './utils'
import { useDispatch } from 'react-redux'
import { setSteepLinks } from './store'
// import { useSelector } from 'react-redux'

const ListItem = ({ steps, setProgrress }) => {
  const classes = useStyles();
  const Location = useLocation();

  useEffect(() => {
    if (Location.pathname.includes(steps.Link)) {
      steps.id !== 1 &&
        setProgrress(
          (
            steps.id *
            Number(
              sessionStorage.getItem('loan') === 'secure'
                ? sessionStorage.getItem('warrantyType') === 'cash'
                  ? 11.2
                  : sessionStorage.getItem('warrantyType') === 'vehicle'
                  ? 12.5
                  : 12.5 //this is for land
                : sessionStorage.getItem('loan') === 'unsecure'
                ? 12.5
                : 11.2
            )
          ).toFixed(1)
        )
    }

    if (Location.pathname.includes('/Customer/SignDocuments')) {
      setProgrress(100)
    }
  }, [Location])

  return (
    <Box
      key={steps}
      className={clsx(
        Location.pathname.includes(steps.Link)
          ? classes.Active
          : classes.StepWrapper
      )}
    >
      <img src={steps.Icon} alt='' className={classes.Icons} />
      <Typography
        variant='subtitle1'
        className={clsx(
          Location.pathname.includes(steps.Link)
            ? classes.ActiveTitle
            : classes.Title
        )}
      >
        {steps.Title}
      </Typography>
      <ArrowDropDownIcon className={classes.DownArrowIcon} />
    </Box>
  )
}

const ProcessSteps = ({ setProgrress, steps, tabs }) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const { height, width } = useWindowDimensions();
  const Location = useLocation();
  const FINAL_LINKS = ['/customer/approved-below-7k', '/Customer/ScheduleAppointment/MakeAppointment', 'Customer/ScheduleAppointment/ScheduleApproved']
  // console.log(Location.pathname)

  React.useEffect(() => {
    FINAL_LINKS.forEach(link => {
      if (Location.pathname === link) return setProgrress(100)
    })
  }, [Location])


  React.useEffect(() => {
    const loan = sessionStorage.getItem('loan') || 'unsecure'
    const warrantyType = sessionStorage.getItem('warrantyType') || ''
    const steepLinks = getLinks( loan, warrantyType )
    dispatch(setSteepLinks(steepLinks))
  }, [])


  return (
    <Box className={classes.MainContainer}>
      {sessionStorage.getItem('loan') === 'unsecure' &&
        ProcessArray.map((steps) => {
          return (
            <Fragment key={steps.id}>
              {width > 1100 ? (
                <ListItem steps={steps} setProgrress={setProgrress} />
              ) : (
                <>
                  {width <= 1100 && Location.pathname.includes(steps.Link) && (
                    <ListItem steps={steps} setProgrress={setProgrress} />
                  )}
                </>
              )}
            </Fragment>
          )
        })}

      {sessionStorage.getItem('loan') === 'secure' &&
        sessionStorage.getItem('warrantyType') === 'cash' &&
        ProcessArraySecuredCash.map((steps) => {
          return (
            <Fragment key={steps.id}>
              {width > 1100 ? (
                <ListItem steps={steps} setProgrress={setProgrress} />
              ) : (
                <>
                  {width <= 1100 && Location.pathname.includes(steps.Link) && (
                    <ListItem steps={steps} setProgrress={setProgrress} />
                  )}
                </>
              )}
            </Fragment>
          )
        })}

      {sessionStorage.getItem('loan') === 'secure' &&
        sessionStorage.getItem('warrantyType') === 'vehicle' &&
        ProcessArraySecuredVehicle.map((steps) => {
          return (
            <Fragment key={steps.id}>
              {width > 1100 ? (
                <ListItem steps={steps} setProgrress={setProgrress} />
              ) : (
                <>
                  {width <= 1100 && Location.pathname.includes(steps.Link) && (
                    <ListItem steps={steps} setProgrress={setProgrress} />
                  )}
                </>
              )}
            </Fragment>
          )
        })}

      {sessionStorage.getItem('loan') === 'secure' &&
        sessionStorage.getItem('warrantyType') === 'land' &&
        ProcessArraySecuredLand.map((steps) => {
          return (
            <Fragment key={steps.id}>
              {width > 1100 ? (
                <ListItem steps={steps} setProgrress={setProgrress} />
              ) : (
                <>
                  {width <= 1100 && Location.pathname.includes(steps.Link) && (
                    <ListItem steps={steps} setProgrress={setProgrress} />
                  )}
                </>
              )}
            </Fragment>
          )
        })}

      {sessionStorage.getItem('loan') === 'refinancing' &&
        ProcessArrayDebtRefinance.map((steps) => {
          return (
            <Fragment key={steps.id}>
              {width > 1100 ? (
                <ListItem steps={steps} setProgrress={setProgrress} />
              ) : (
                <>
                  {width <= 1100 && Location.pathname.includes(steps.Link) && (
                    <ListItem steps={steps} setProgrress={setProgrress} />
                  )}
                </>
              )}
            </Fragment>
          )
        })}
    </Box>
  )
}

export default ProcessSteps

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
