import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  //* crearemos el diseNos de la caja tooltip que pareza comouna tarjeta de chat con un borde redondeado y una de las esquinas apuntandoal infoicon
  tooltip: {
    position: "relative", // Agrega posición relativa para el seudoelemento
    backgroundColor: "#fff !important",
    color: "#275DA6 !important",
    maxWidth: 320,
    left: "95%",
    textTransform: "none !important",
    fontSize: "14px !important",
    boxShadow: theme.shadows[1],
    border: "1px solid #275DA6 !important",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    "& .MuiTooltip-arrow": {
      display: "none", // Oculta la flecha por defecto
    },

    "@media (max-width: 600px)": {
      left: "15%",
    },

    // Agrega un triángulo pseudo-elemento
    "&::before": {
      content: '""',
      position: "absolute",
      top: "-10px", // Ajusta la posición vertical
      //left: "90%", // Alinea el triángulo en el centro
      transform: "translateX(-50%)", // Centra horizontalmente
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #275DA6", // Ajusta el color y tamaño del triángulo
    },
  },
  groupButton: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20

  },
  
}));

