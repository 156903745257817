import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasDistrictData = createAsyncThunk(
  'appDistrictData/DistrictData',
  async () => {
    const response = await Jwt.getDistrictData()
    return response.data
  }
)

const initialState = {
  totalCount: 0,
  filteredCount: 0,
  districts: [],
  data: []
}

export const appDistrictDataSlice = createSlice({
  name: 'appDistrictData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasDistrictData.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount
      state.filteredCount = action.payload.filteredCount
      state.districts = action.payload.districts
      state.data = action.payload.data
    })
  }
})

// export const { handleGetBrands } = appDistrictDataSlice.actions

export default appDistrictDataSlice.reducer
