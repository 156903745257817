// export const mockAssets = [
//   {
//       "id": 1,
//       "name": "Personal / Household Items",
//       "description": null,
//       "position": null
//   },
//   {
//       "id": 2,
//       "name": "Real Estate",
//       "description": null,
//       "position": null
//   }
// ]

const catalogAssetDeclaration = (assets = []) => {
  return assets.map((asset) => {
    return {
      id: asset?.id,
      name: asset?.name,
      position: asset?.position
    }
  }).sort((a, b) => a.position - b.position)
}

export default catalogAssetDeclaration
