import React from 'react'

const FlechaBlanca = ({fill}) => {
  return (
    <svg width="76" height="14" viewBox="0 0 76 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M75.647 7.647C76.0043 7.28967 76.0043 6.71032 75.647 6.35299L69.824 0.529967C69.4666 0.172637 68.8873 0.172637 68.53 0.529967C68.1726 0.887297 68.1726 1.46664 68.53 1.82397L73.706 6.99999L68.53 12.176C68.1726 12.5333 68.1726 13.1127 68.53 13.47C68.8873 13.8274 69.4666 13.8274 69.824 13.47L75.647 7.647ZM7.99919e-08 7.915L75 7.91499L75 6.08499L-7.99919e-08 6.085L7.99919e-08 7.915Z" fill={fill}/>
</svg>
  )
}

export default FlechaBlanca