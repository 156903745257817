import React, { useState } from "react"
import { Outlet, Navigate } from "react-router-dom"
import LocalStorageService from "../Utils/LocalStoreServices"
import jwt_decode from "jwt-decode"
import moment from "moment"

const PrivateGuard = ({ children }) => {
  let AccessToken = LocalStorageService.getAccessToken()
  const status = LocalStorageService.getReferenceStatus()
  if (Number(status) === 1 || Number(status) === 2) {
    LocalStorageService.clearToken()
    AccessToken = ""
  }
  let isvalidToken = false
  if (AccessToken) {
    try {
      const decode = jwt_decode(AccessToken)
      const date = moment(new Date()).format("X")
      isvalidToken = (decode.exp - 0) >= date
      //console.log(isvalidToken, decode.exp - 18000)
    } catch (error) {
      isvalidToken = false
      sessionStorage.clear()
    }
  } else {
    isvalidToken = false
    sessionStorage.clear()
  }
  return (
    <>
      {isvalidToken ? (
        <>{children} </>
      ) : (
        <Navigate to={"/not-found"} />
         //<Navigate to={"/PersonalInformationReview"} />
      )}
    </>
  )
}
export default PrivateGuard
