import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import { FormControl } from "@mui/material";
import { useStyles } from "./Style";
import { Stack } from "@mui/system";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #DADADA",
    fontSize: 13,
    padding: "10px 12px",
    letterSpacing: "0.1em",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function CustomizedInputs({
  Lable,
  className,
  type,
  width,
  Tooltiptitle,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  id,
  Title,
  disabled,
  ...props
}) {
  const classes = useStyles();

  return (
    <Stack fullWidth={true} sx={{ width: "100%" }}>
      {/* {error ? <ErrorTooltip Title={Title} /> : ""} */}

      <FormControl
        sx={{ width: "100%" }}
        variant="standard"
        mb={4}
        className={[classes.FormWrapper, className].join(" ")}
      >
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          className={classes.InputLable}
        >
          {Lable}
        </InputLabel>
        <BootstrapInput
          {...props}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          type={type}
          fullWidth
          sx={{ width: width }}
          disabled={disabled}
        />
        
         {error ? <p className={classes.errorMessage}>{error}</p> : ""}
      </FormControl>
    </Stack>
  );
}
