import React, { Fragment, useState } from 'react'
import LoanCalculator from './components/loan-calculator'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCashLoanCollateral } from './context/'
import Spinner from '../../../components/custom-spinner/'
import { Button, Grid, Typography, Stack } from '@mui/material'
import { useStyles } from './components/styles'
import { Box } from '@mui/system'
import Input from '@mui/joy/Input'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import SmallProgressBar from '../../../../Components/SmallProgressBar/SmallProgressBar'
import { useNavigate } from 'react-router-dom'
import { Interceptor } from '../../../../Utils/Interceptor'
import { loanRequest } from '../../../../Store/Actions/LoanRequest'
import SelectAccountComponent from './components/select-account'
import ExclamationIcon from '../../../../assets/Images/exclamation-circle.png'
import LowerInterest from '../../../../assets/Images/lowe-interest.png'
import { warrantyTypeNames, loanTypeNames } from '../../../../Components/status'

const Index = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { scheduleRequired, loanOptions } = useSelector(
    (state) => state.cashLoanCollateral
  )
  const { collateralAmount, currentStep, requestedAmount, requestedTerms } =
    useSelector((state) => state.LoanRequestReducer)
  const [collateralAmountUp, setCollateralAmount] = useState(0)
  const [accountsSelected, setAccountsSelected] = useState([])
  const [currentAccounts, setCurrentAccounts] = useState([
    {
      accountNumber: '',
      accountName: '',
      accountType: '',
      accountCcy: '',
      customerRole: '',
      availableBalance: 0.0,
      maturityDate: '',
      accountHolders: [
        {
          partyID: '',
          role: ''
        }
      ]
    }
  ])
  const [pending, setPending] = useState(true)

  function deleteByElement(acc) {
    setAccountsSelected((current) =>
      current.filter((element) => {
        return element.accountNumber !== acc.accountNumber
      })
    )
  }

  const AxiosInterceptor = Interceptor()

  useEffect(() => {
    const getAccountTypes = () => {
      AxiosInterceptor.get(`/loan-requests/account-types`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('access_token')
        }
      })
        .then((response) => {
          setCurrentAccounts(response.data.accounts.accounts)
          setAccountsSelected(response.data.accountsSelected)
        })
        .catch((err) => console.log(err))
    }

    getAccountTypes()
    dispatch(getCashLoanCollateral())

    setPending(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectedAccount = (e) => {
    setAccountsSelected([...accountsSelected, e])
  }

  useEffect(() => {
    if (
      loanOptions.amount >= 1000 ||
      loanOptions.terms >= 12 ||
      // loanOptions.collateralAmount > 0 ||
      collateralAmountUp > 0 ||
      accountsSelected.length > 0 ||
      loanOptions.requestedTerms >= 12 ||
      loanOptions.requestedAmount >= 1000
    ) {
      dispatch(
        loanRequest({
          requestedAmount:
            loanOptions.amount >= 1000
              ? loanOptions.amount
              : loanOptions.requestedAmount,
          requestedTerms:
            loanOptions.terms >= 12
              ? loanOptions.terms
              : loanOptions.requestedTerms,
          interestRate: 1,
          // debtCancellationSchemeRequested: false,
          collateralAmount: Number(collateralAmountUp),
          promoCode: loanOptions.promoCode ? loanOptions.promoCode : null,
          accountTypes: accountsSelected.length > 0 && accountsSelected.map((acc) => acc.accountNumber),
          loanTypeId: loanOptions.loanTypeId
            ? loanOptions.loanTypeId
            : loanTypeNames.indexOf(sessionStorage.getItem('loan')) + 1,
          warrantyTypeId: loanOptions.warrantyTypeId
            ? loanOptions.warrantyTypeId
            : warrantyTypeNames.indexOf(sessionStorage.getItem('warrantyType')) +
              1,
          token: sessionStorage.getItem('code')
        })
      )
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountsSelected,
    // collateralAmount,
    collateralAmountUp,
    currentStep,
    dispatch,
    loanOptions.amount,
    loanOptions.requestedAmount,
    loanOptions.requestedTerms,
    loanOptions.terms
    // loanOptions.collateralAmount
  ])

  useEffect(() => {
    if (collateralAmountUp === 0 && loanOptions.collateralAmount >= 0)
      setCollateralAmount(loanOptions.collateralAmount)

    // if(collateralAmount > 0) setCollateralAmount(collateralAmount)
  }, [collateralAmountUp, loanOptions.collateralAmount])

  const handleChangeColateralAmount = (e) => {
    setCollateralAmount(e.target.value)
  }

  const [callbackData, setCallbackData] = useState()

  const handleCallback = (data) => {
    setCallbackData(data)
  }

  const loanTypeList = ['unsecure', 'secure', 'refinancing']

  const [percentage, setPercentage] = useState(0)

  const calculatePercentage = (amount = 0, collateral = 0) => {
    if (!amount || !collateral) return 0
    const percentage = (collateral * 100) / amount
    return percentage >= 100 ? 100 : percentage
  }


  useEffect(() => {
    const percentageResult = calculatePercentage(loanOptions.amount, collateralAmountUp)
    setPercentage(percentageResult)
  }, [collateralAmountUp, loanOptions.amount, callbackData])

  return (
    <Fragment>
      {pending && <Spinner open={pending} />}
      {!pending && <LoanCalculator />}
      {!pending && !scheduleRequired && (
        <Box
          className={classes.LoanContainer}
          // sx={{ width: '80%'}}
        >
          {requestedAmount <= 50000 && requestedTerms <= 120 && (
            <>
              <FormControl size='small' sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ width: '100%' }}>
                    <Button variant='contained' className={classes.FullButton}>
                      WHICH ACCOUNT(S) DO YOU WISH TO USE AS COLLATERAL?
                    </Button>
                    <p style={{ textAlign: 'justify' }}>
                      Please select the account(s) to be utilized as the cash
                      collateral for your loan application. A minimum of one
                      account should be selected and the total amount must be
                      equivalent to the portion of the loan you would like to
                      secure. You can enter the desired amount to be held as
                      collateral. If you prefer that your collateral be held on
                      one savings account only, please transfer the funds into
                      the desired account. Transfer does not apply to Term
                      Deposits.
                    </p>
                    <p
                      style={{
                        height: '4.625rem',
                        borderRadius: '0.5rem',
                        background: '#E0F2FF',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        padding: '0 1rem',
                        fontWeight: '700',
                        color: '#868686',
                        marginTop: '2rem'
                      }}
                    >
                      <img
                        src={LowerInterest}
                        alt='lower interest rate'
                        height={34}
                      />
                      Lower interest rates are guaranteed for loans that are
                      100% secure.
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectAccountComponent
                      accounTypes={currentAccounts}
                      handleCallback={handleCallback}
                      selectedAccounts={accountsSelected}
                      handleAccountSelected={handleSelectedAccount}
                      handleDeleted={deleteByElement}
                      loanTypeId={
                        loanTypeList.indexOf(sessionStorage.getItem('loan')) + 1
                      }
                    />
                  </Grid>

                  {callbackData?.totalFunds > loanOptions.amount && (
                    <Grid item xs={12} className={classes.AlertTotalFunds}>
                      <p>
                        Please note that the pledge on your account(s) will be
                        equivalent only to the approved loan amount.
                      </p>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button variant='contained' className={classes.FullButton}>
                      CASH COLLATERAL
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormLabel className={classes.Label}>
                      COLLATERAL AMOUNT
                    </FormLabel>
                    <Input
                      min={0}
                      sx={{ mt: 2 }}
                      startDecorator={'BZ $'}
                      type='number'
                      value={collateralAmountUp}
                      placeholder='Enter collateral amount'
                      onChange={handleChangeColateralAmount}
                      // onBlur
                    />
                    {collateralAmountUp > callbackData?.totalFunds && (
                      <p style={{ color: '#E20613', fontSize: '0.875rem' }}>
                        *The collateral amount cannot exceed the available funds in the account.
                      </p>
                    )}
                    {collateralAmountUp > loanOptions.amount && (
                      <p style={{ color: '#E20613', fontSize: '0.875rem' }}>
                        *Must not exceed Loan Amount
                      </p>
                    )}
                    {/* <Button
                      onClick={() => {
                          setCollateralAmount(loanOptions.amount || loanOptions.requestedAmount)
                      }}
                      style={{ marginTop: '5%' }}
                      variant='contained'
                      className={classes.FullButton}
                    >
                      FULL AMOUNT
                    </Button> */}
                    <Typography
                      color={'#706F6F'}
                      fontSize={12}
                      marginTop={'1rem'}
                      fontStyle={'italic'}
                    >
                      Please note that the loan collateral amount does not
                      include the associated loan fees. These fees will be
                      incorporated into the overall loan amount, which will be
                      presented in the collateral agreement form for you to sign
                      upon approval.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      variant='subtitle2'
                      gutterBottom
                      sx={{ color: '#706F6F !important' }}
                    >
                      Percentage (%) of Loan Security
                    </Typography>
                    <SmallProgressBar progress={ percentage } />
                  </Grid>
                </Grid>
              </FormControl>
              <Stack
                direction={{ xs: 'column' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent='center'
                alignItems='center'
                mt={5}
                mb={2}
              >
                {(collateralAmountUp > 0) &
                (collateralAmountUp <= callbackData?.totalFunds) &
                (collateralAmount <= loanOptions.amount) ? (
                  <p className={classes.AlertCashCollateral}>
                    <img src={ExclamationIcon} alt='' />
                    <span className={classes.AlertSpanText}>
                      We will be adding a pledge to your term deposit and/or
                      savings account to secure your loan once the collateral
                      agreement has been signed.
                    </span>
                  </p>
                ) : (
                  ''
                )}
                <Button
                  disabled={
                    (collateralAmountUp <= callbackData?.totalFunds) &
                    (collateralAmountUp > 0) &
                    (collateralAmountUp <= loanOptions.amount)
                      ? false
                      : true
                  }
                  variant='contained'
                  className={classes.Button}
                  onClick={() => {
                    dispatch(
                      loanRequest({
                        requestedAmount:
                          loanOptions.amount >= 1000
                            ? loanOptions.amount
                            : loanOptions.requestedAmount,
                        requestedTerms:
                          loanOptions.terms >= 12
                            ? loanOptions.terms
                            : loanOptions.requestedTerms,
                        interestRate: 1,
                        promoCode: loanOptions.promoCode ? loanOptions.promoCode : null,
                        // debtCancellationSchemeRequested: false,
                        collateralAmount: Number(collateralAmountUp),
                        accountTypes: accountsSelected.map(
                          (acc) => acc.accountNumber
                        ),
                        loanTypeId: loanOptions.loanTypeId
                          ? loanOptions.loanTypeId
                          : loanTypeNames.indexOf(
                              sessionStorage.getItem('loan')
                            ) + 1,
                        warrantyTypeId: loanOptions.warrantyTypeId
                          ? loanOptions.warrantyTypeId
                          : warrantyTypeNames.indexOf(
                              sessionStorage.getItem('warrantyType')
                            ) + 1,
                        token: sessionStorage.getItem('code')
                      })
                    )
                    AxiosInterceptor.post('/customers/step', { currentStep: 3 })
                      .then((response) => {
                        console.log(response)
                      })
                      .catch((e) => console.log(e))
                    navigate(
                      sessionStorage.getItem('warrantyType') === 'land'
                        ? '/Customer/monthlyIncome'
                        : '/Customer/MonthlyInstallments'
                    )
                  }}
                >
                  CONTINUE
                </Button>
              </Stack>
            </>
          )}
        </Box>
      )}
    </Fragment>
  )
}

export default Index
