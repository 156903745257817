import { Avatar, Grid, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useStyles } from './Style'

const QUESTION_NUMBER = {
  1: { value: 1, active: false, alt: 'Very low' },
  2: { value: 2, active: false },
  3: { value: 3, active: false },
  4: { value: 4, active: false },
  5: { value: 5, active: false },
  6: { value: 6, active: false },
  7: { value: 7, active: false },
  8: { value: 8, active: false },
  9: { value: 9, active: false },
  10: { value: 10, active: false, alt: 'Full met my expectations' },
}

export const QuestionNumber = ({ key, questionInf, dataForm, setData }) => {
  const classes = useStyles()
  //const [question, setQuestion] = useState(QUESTION_NUMBER)
  const [question, setQuestion] = useState(() => {
    const newNumber = {}
    for (let i = 1; i <= 10; i++) {
      newNumber[`${String(questionInf.id)}-${String(i)}`] = { value: `${String(questionInf.id)}-${String(i)}`, active: false, label: i }
    }
    return newNumber
  })

  const handleClick = (id) => {
    const newQuestions = { ...question }
    const answer = String(id).split('-')[1]
    newQuestions[String(id)].active = true
    Object.keys(question).forEach((key) =>
      String(key) !== String(id) ? (newQuestions[key].active = false) : null
    )
    setQuestion(newQuestions)

    setData({...dataForm, [questionInf.id]: {questionId: questionInf.id, answer }})

  }
  //console.log('question', question)

  return (
    <>
      <Typography className={classes.QuestionTitle}>{questionInf?.question}</Typography>
      <Stack direction={'row'} className={classes.QuestionContenedor} sx={{ flexWrap: 'wrap', gap: '0.5rem' }}>
        <Fragment key={questionInf.id}>
        {Object.keys(question).map((number) => (
          <Grid container spacing={2} key={`${questionInf.id}-${number}`} sx={{ flexBasis: '10%' }}>
            <Grid item>
              <Avatar
                onClick={() => handleClick(question[number].value)}
                sx={{
                  backgroundColor: `${
                    question[number].active ? '#ADDB19' : '#FFF'
                  }`,
                  color: `${question[number].active ? '#FFF' :'#C6C6C5'}`,
                  border: '1px solid #C6C6C5',
                }}
              >
                {question[number].label}
              </Avatar>
            </Grid>
            {/* <span style={{fontSize: '.8rem', color: '#C6C6C5'}}>{number.alt}</span> */}
          </Grid>
        ))}
        </Fragment>
      </Stack>
    </>
  )
}
