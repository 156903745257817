import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  LoanContainer: {
    width: "850.4px",
    margin: "0 auto 70px auto",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  Button: {
    width: 176,
    height: 39,
    "&.MuiButtonBase-root": {
      marginBottom: 30,
      backgroundColor: theme.colors.primary.Darkblue,
      color: 'white',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
  },
}));
