import {
  Alert,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Emoji from '../../../assets/Warning.png'
import { Box } from '@mui/system'
import React from 'react'
import { useStyles } from './ReferencePopUpStyle.js'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import { toast, Toaster } from 'react-hot-toast'
import { Form } from 'formik'
import { useNavigate } from 'react-router-dom'

const ModalRefFriend = ({open, setOpen, link = false}) => {

  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(false)

  const AxiosInterceptor = Interceptor()
  const classes = useStyles()
  const navigate = useNavigate()

  const handleSumit = (e) => {
    e.preventDefault()
    setLoading(true)

    const refereeName = e.target.refereeName.value
    const refereeEmail = e.target.refereeEmail.value

    const sentData = { refereeName, refereeEmail }

    AxiosInterceptor.post('/refer-a-friend', { ...sentData }).then(() => {
      toast.success('Invitation sent to your friend')
      setTimeout(() => {
        navigate('/feedback')
        setLoading(true)
        setOpen(false)
      }, 1500)
    })
  }

  return (
    <Dialog
      className={classes.AlertBoxContainerRefer}
      open={open}
      aria-labelledby="draggable-dialog-title"
      maxWidth="100vw"
    >
      <Toaster />
      <Box>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Emoji} alt="" />
          </Box>
          <Typography color="#005FAA">REFER A FRIEND</Typography>
          <Typography className={classes.ContentText}>
            <br />
            Let your friends know how easy it is to apply for a BBL Online Loan.
            After their loan is approved,
            <br />
            <strong> you will receive a loyalty payment of BZ$30.</strong>
          </Typography>
          <Box>
            <Button
              variant="contained"
              className={classes.BtnReferYES}
              // style={{ minWidth: '400px', float: 'center', marginRight: '10px' }}
              onClick={() => setShowInput(true)}
            >
              YES, I WANT TO REFER A FRIEND
            </Button>
            <Button
              variant="text"
              className={classes.BtnReferSKIP}
              // style={{ minWidth: '400px' }}
              onClick={() => navigate('/feedback')}
              // onClick={() => link ? navigate(link) : setOpen(false)}
            >
              SKIP FOR NOW
            </Button>
          </Box>
          <br />
          <br />
          {showInput && (<Box component={'form'} onSubmit={handleSumit}>
            <>
              {/* <InputLabel shrink htmlFor="bootstrap-input">
                ENTER THE FIRST AND LAST NAME
              </InputLabel> */}
              <TextField
                required
                className={classes.InputRefEmail}
                style={{
                  minWidth: '400px',
                  background: '#FFF',
                  marginRight: '10px',
                }}
                id="refereeName"
                name="refereeName"
                label="ENTER THE FIRST AND LAST NAME"
                variant="outlined"
              />
              {/* <InputLabel shrink htmlFor="bootstrap-input">
                EMAIL
              </InputLabel> */}
              <TextField
                required
                type="email"
                className={classes.InputRefEmail}
                style={{ minWidth: '400px', background: '#FFF' }}
                id="refereeEmail"
                name="refereeEmail"
                label="ENTER THE EMAIL ADDRESS"
                variant="outlined"
              />
            </>
            <br />
            <br />
            <br />
            <Grid display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                style={{ minWidth: '180px' }}
                type="submit"
                disabled={loading}
              >
                SUBMIT
              </Button>
            </Grid>
          </Box>)}
          <br />
        </Box>
      </Box>
    </Dialog>
  )
}

export default ModalRefFriend
