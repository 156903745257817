import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useStyles } from "./CardProductsAndServicesStyle"
import { useNavigate, useLocation } from "react-router-dom"


export default function CardProductsAndServices({ Title, contentCard, image, link, textLink }) {
  const classes = useStyles()
  const redirectPage = (url) => {
	window.open(url).focus();
  }
  
    return (
			<Card sx={{ maxWidth: 345, maxHeight: 500, height: 500 }}>
				<CardMedia component="img" height="257" image={image} />
				<CardContent>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						style={{ background: "#005FAA", color: "#FFF", textAlign: "center", borderRadius: 10 }}
					>
						{Title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{contentCard}
					</Typography>
				</CardContent>
				<CardActions>
					<Button size="small" style={{ color: "#F07F3C" }} onClick= {() => redirectPage(link)}>
						{textLink}
					</Button>
				</CardActions>
			</Card>
		);
}
