import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  TooltipContainer: {},
  Content: {
    textAlign: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontFamily: theme.fonts.family.tertiary,
      fontWeight: 300,
      lineHeight: 1.8,
      color: theme.colors.secondary.White,
      width: 321,
    },
  },
}));
