// const mockData = {
//   1: {
//     id: 1,
//     type: 'CREATE',
//     select: { id: 1, assetId: undefined },
//     text: { value: undefined }
//   }
// }

const stringToNumber = (value) => {
  if (typeof value === 'string') return parseFloat(value?.replace(/,/g, ''))
  return value
}

const reprocessAssetsAdapter = (data) => {
  const create = Object.keys(data).filter((key) => (data[key]?.type === 'CREATE' && data[key]?.select?.assetId !== undefined && data[key]?.text?.value !== undefined))
  const update = Object.keys(data).filter((key) => (data[key]?.type === 'UPDATE' && data[key]?.select?.assetId !== undefined && data[key]?.text?.value !== undefined))
  const assets = {
    create: create.map((key) => ({
      assetId: data[key]?.select?.assetId,
      amount: stringToNumber(data[key]?.text?.value)
    })),
    update: update.map((key) => ({
      id: data[key]?.id,
      assetId: data[key]?.select?.assetId,
      amount: stringToNumber(data[key]?.text?.value)
    }))
  }
  return assets
}

export default reprocessAssetsAdapter
