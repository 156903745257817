export const MESSAGE = {
  loaddocuments: 'You can easily access all your loan agreements by logging in to Online Banking, then selecting the Online Loans tab, and viewing your completed loan applications. ',
  signdocs: "You can easily access all your loan agreements by logging in to Online Banking, then selecting the Online Loans tab, and viewing your current loan applications.",
  employementverification: "This form is available Online. Simply login to your Online Banking account, go to 'my current loan applications' to access and download the form",
  salaryassignment: "This form is available Online. Simply login to your Online Banking account, go to 'my current loan applications' to access and download the form",
  employmentletter: "This form is available Online. Simply login to your Online Banking account, go to 'my current loan applications > MY VALID LOAN DOCUMENTS' to access and download the form",
  othermessage: "You can easily access all your loan agreements by logging in to Online Banking, then selecting the Online Loans tab, and viewing your completed loan applications.",
  customerchatmessage: "Your document was successfully uploaded. To access it, kindly log in via our website.",
  lenderchatmessage: "To retrieve the document, kindly log in via our website.",
  downloadApprovalLetter: "This form is available Online. Simply login to your Online Banking account, go to 'my current loan applications' to access and download the Approval Letter."
}