import { Alert, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Cross from "../../assets/Images/Cross.png"
import { Box } from "@mui/system"
import React from "react"
import { useStyles } from "./ReferencePopUpStyle.js"
import { useSelector, useDispatch } from "react-redux"
import Actions from "../../Store/Types"

const PopupAlert = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const Error = useSelector((state) => state.ReferenceStatusReducer.codeError)

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={Error}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>
          Invalid reference number, please try again
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={() =>
              dispatch({
                type: Actions.REFERENCESTATUSERROR,
                payload: false,
              })
            }
          >
            Re-Enter
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PopupAlert
