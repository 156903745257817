import * as yup from "yup";
function isAmountValid(value) {
  if (value) {
    return parseFloat(value) < 100000000;
 } else {
   return true;
 }
}
export const validation = yup.object({
  monthlyGrossIncomeFromEmployement: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
       return isAmountValid(value)
    }),
  pension: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  rentalIncome: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  monthlyNetIncomeFromBusiness: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  alimony: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  consultancy: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  allowance: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  commission: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
  actualAmount: yup
    .string()
    .test("digitAmount", "**Invalid amount", function (value) {
      return isAmountValid(value)
    }),
});
