import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { Button, Grid } from '@mui/material'
import SelectAccountComponent from '../initial-loan-process/screen/loan-collateral/components/select-account'
import { useStyles } from '../initial-loan-process/screen/loan-collateral/components/styles'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import { Interceptor } from '../../../src/Utils/Interceptor'
import { useNavigate } from 'react-router-dom'
import { hasLoansToBeRefinanced } from '../../Store/context/getLoansToBeRefinanced'
import { useDispatch, useSelector } from 'react-redux'
import { loanRequest } from '../../Store/Actions/LoanRequest'
import { loanTypeNames } from '../../Components/status'

const LoansToBeRefinanced = () => {
  const classes = useStyles()
  const AxiosInterceptor = Interceptor()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [accountsSelected, setAccountsSelected] = useState([])
  // const [callbackData, setCallbackData] = useState()

  const { loansForRefinance, loansSelected } = useSelector(
    (state) => state.loansToBeRefinanced
  )


  const handleSelectedAccount = (e) => {
    setAccountsSelected([...accountsSelected, e])
  }

  function deleteByElement(acc) {
    setAccountsSelected((current) =>
      current.filter((element) => {
        return element.accountID !== acc.accountID
      })
    )
  }

  const handleCallback = (data) => {
    // setCallbackData(data)
  }

  const handleSubmitData = () => {
    //postCashLoanCollateral(data)
    AxiosInterceptor.post('/customers/step', { currentStep: 3 })
      .then((response) => {
        navigate('/Customer/refinance-request')
      })
      .catch((e) => console.log(e))
    // navigate('/Customer/LoanPurpose')
  }

  useEffect(() => {
    dispatch(hasLoansToBeRefinanced())

    if(loansSelected.length){
      setAccountsSelected(loansSelected)
    }
  }, [loansSelected.length])

  useEffect(() => {
    if (accountsSelected.length) {
      dispatch(
        loanRequest({
          loansForRefinance: accountsSelected.map((acc) => acc.accountID),
          loanTypeId: loanTypeNames.indexOf(sessionStorage.getItem('loan')) + 1
        })
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsSelected])

  return (
    <>
      <Box className={classes.LoanContainer} sx={{ maxWidth: '1000px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant='contained' className={classes.FullButton}>
              ACTIVE LOANS
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SelectAccountComponent
            accounTypes={loansForRefinance}
            handleCallback={handleCallback}
            selectedAccounts={accountsSelected}
            handleAccountSelected={handleSelectedAccount}
            handleDeleted={deleteByElement}
            loanTypeId={3}
          />
        </Grid>
        <ArrowButton
          // onClickBackBtn={() => handleBackBtn()}
          onClick={() => handleSubmitData()}
        />
      </Box>
    </>
  )
}

export default LoansToBeRefinanced
