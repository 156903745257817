import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasVehicleDetails = createAsyncThunk(
  'appVehicleDetails/vehicleDetails',
  async (data) => {
    const response = await Jwt.postVehicleDetails(data)
    return response.data
  }
)

const initialState = {
  status: null,
  vehicleBrandId: null,
  vehicleModel: null,
  vehicleValue: null,
  productionYear: null,
  mileage: null,
  pending: true,
  name: null,
  address: null,
  districtId: null
}

export const appvehicleBrandSlice = createSlice({
  name: 'appvehicleDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasVehicleDetails.fulfilled, (state, action) => {
      state.status = action.payload.status
      state.vehicleBrandId = action.payload.vehicleBrandId
      state.vehicleModel = action.payload.vehicleModel
      state.productionYear = action.payload.productionYear
      state.mileage = action.payload.mileage
      state.name = action.payload.name
      state.address = action.payload.address
      state.districtId = action.payload.districtId
      state.vehicleValue = action.payload.vehicleValue
    }).addCase(hasVehicleDetails.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appvehicleBrandSlice.reducer
