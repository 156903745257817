import React, { Fragment, useEffect } from 'react'
import { Customer, PersonalInformationReview } from '../../containers'
import { useDispatch, useSelector } from 'react-redux'
import { handleLoanSelected, hasActiveLoan } from '../../context'
import Spinner from '../../../components/custom-spinner/'
import { useLocation, useNavigate } from 'react-router-dom'
import { getParams } from '../../../../Utils/getParams'

const Index = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [typeLoanSelected, setTypeLoanSelected] = React.useState(null)
  const { pending } = useSelector((state) => state.initialLoanProcess)
  const { loan: loanType, collateral } = getParams(useLocation().search, ['loan', 'collateral'])

  const navigateHome = () => navigate(`/?q=${sessionStorage.getItem('code')}`)

  const evaluateLoan = (loans) => {
    const customer = new Customer(loans)
    const loan = customer.applyForLoan(loanType)
    if (!loan?.canApply) return navigateHome()
    delete loan.canApply
    dispatch(handleLoanSelected(loan))
    setTypeLoanSelected(loan)
  }

  useEffect(() => {
    dispatch(hasActiveLoan()).then((result) => {
      if (result.error) navigateHome()
      const loans = result.payload
      const activeLoans = { loans, guarantee: collateral }
      evaluateLoan(activeLoans)
    })
  }, [])

  return (
    <Fragment>
      {pending ? (
        <Spinner open={pending} />
      ) : (
        <PersonalInformationReview />
      )}
    </Fragment>
  )
}

export default Index
