import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const SimpleBackdrop = () => {
  const [open, setOpen] = React.useState(false);

  const Loader = useSelector((state) => state.LetsMeetFormReducer.isLoading);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={Loader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default SimpleBackdrop;
