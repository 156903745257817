/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  newHeadline: {
    fontWeight: 700,
    fontFamily: theme.fonts.family.secondary,
    fontSize: '1.25rem',
    marginBottom: '1rem'
  },
  NewCardBtn: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'overlay',
    width: '50%',
    borderRadius: '9.15px',
    paddingTop: '3rem',
    paddingLeft: '1.5rem',
    paddingBottom: '1rem',
    textDecoration: 'none',
    color: '#fff',
    '&::before': {
      content: '""',
      borderRadius: '9.15px',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(90deg, #56AF31 19.52%, rgba(0, 211, 57, 0.49) 76.1%)',
      opacity: 0.7
    },
    '@media (max-width: 900px)': {
      width: '93%',
      paddingTop: 0
    }
  },
  NewCardBtn2: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'overlay',
    width: '50%',
    borderRadius: '9.15px',
    paddingTop: '3rem',
    paddingLeft: '1.5rem',
    paddingBottom: '1rem',
    textDecoration: 'none',
    color: '#fff',
    '&::before': {
      content: '""',
      borderRadius: '9.15px',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(90deg, #275DA6 21.22%, rgba(0, 108, 255, 0.45) 100%)',
      opacity: 0.7
    },
    '@media (max-width: 900px)': {
      width: '93%',
      paddingTop: 0
    }
  },
  titleCard: {
    fontSize: '2rem',
    fontFamily: theme.fonts.family.secondary,
    fontWeight: 700,
    position: 'relative',
    zIndex: 100
  },
  btnVariation: {
    border: '1px solid #fff',
    display: 'inline-block',
    padding: '0.5rem 1rem',
    borderRadius: '2rem',
    fontFamily: theme.fonts.family.secondary,
    fontSize: '12.2px',
    fontWeight: '600',
    position: 'relative',
    zIndex: 100,
    // '&:hover': {
    //   background: 'white',
    //   color: '#275DA6',
    //   fill: '#275DA6'
    // },
  },
  Wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alighItems: 'center',
    flexDirection: 'column'
  },
  PromoImg: {
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },

  CardContainer: {
    width: '992.8px',
    paddingTop: '48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1200px)': {
      width: 'auto'
    },
    '@media (max-width: 1100px)': {
      width: 'auto'
    },
    '@media (max-width: 1000px)': {
      width: 'auto'
    }
  },

  ButtonWrapper: {
    width: '100%',
    marginTop: '28.96px',
    marginBottom: '45.12px'
  },

  CardDivider: {
    '&.MuiDivider-root': {
      border: '2px solid #C6C6C6',
      background: '#C6C6C6'
    }
  },

  SecondButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '45.12px',
    gap: '20.6px',

    '@media (max-width: 1100px)': {
      flexWrap: 'wrap',
      gap: '9.12px'
    },
    '@media (max-width: 1000px)': {
      width: '100%'
    },
    '@media (max-width: 500px)': {
      display: 'block'
    }
  },

  Button: {
    '&.MuiButtonBase-root': {
      height: '52.22px',
      width: '100%',
      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: '600',
        letterSpacing: '0.2em'
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: '600',
        letterSpacing: '0.2em'
      },
      '@media (max-width: 1000px)': {
        width: '100%'
      },
      '@media (max-width: 500px)': {
        width: '100%'
      }
    }
  },
  Links: {
    textDecoration: 'none',
    width: '100%'
  },
  Button2: {
    '&.MuiButtonBase-root': {
      // height: "52.22px",
      width: '100%',
      backgroundColor: theme.colors.secondary.Green,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: '600',
        letterSpacing: '0.2em'
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: '600',
        letterSpacing: '0.2em'
      },
      '@media (max-width: 1000px)': {
        width: '100%'
      },
      '@media (max-width: 500px)': {
        width: '100%'
      }
    }
  },
  SecondButton: {
    '&.MuiButtonBase-root': {
      height: '52.22px',
      width: '100%',

      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: '#F4EBFE !important',
        color: theme.colors.primary.Darkblue
      },
      '& .MuiTypography-root': {
        fontSize: '12.96px',
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: '0.2em'
      },
      '@media (max-width: 1100px)': {
        margin: '0'
      },
      '@media (max-width: 500px)': {
        width: '100%',
        marginBottom: 20
      }
    }
  },

  ThirdButton: {
    '&.MuiButtonBase-root': {
      height: '52.22px',
      width: '469.76px',

      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: '12.96px',
        fontFamily: theme.fonts.family.primary,
        fontWeight: '600',
        letterSpacing: '0.2em'
      },
      '@media (max-width: 1100px)': {
        margin: '0'
      },
      '@media (max-width: 500px)': {
        width: '100%',
        marginBottom: 20
      }
    }
  },
  Refer: {
    textDecoration: 'none'
  },
  Text: {
    fontFamily: theme.fonts.family.primary,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#3C3C3B',
    marginTop: '25px'
  },
  LearnMore: {
    fontFamily: theme.fonts.family.primary,
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#F07F3C',
    marginTop: 10
  },
  Header: {
    fontFamily: theme.fonts.family.primary,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '21.335px',
    lineHeight: '25px',
    color: '#275DA6'
  },
  Card: {
    marginTop: '2.5rem',
    padding: 20,
    filter: 'drop-shadow(2px 2px 2px rgba(157, 157, 156, 0.251))'
  },
  CardDetails: {
    paddingBottom: 100
  },
  CardDivider: {
    width: '139.87px',
    marginTop: '10px !important',
    background: theme.colors.primary.Darkblue,
    border: `2px solid ${theme.colors.primary.Darkblue}`
  }
}))
