import Group3 from "../../assets/NonCustomerImages/Group 3.png";
import Group4 from "../../assets/NonCustomerImages/Group 4.png";
import Group5 from "../../assets/NonCustomerImages/Group 5.png";
import Group6 from "../../assets/NonCustomerImages/Group 6.png";
import Group7 from "../../assets/NonCustomerImages/Group 7.png";
import Group8 from "../../assets/NonCustomerImages/Group 8.png";
import LandDetails from '../../assets/Images/land-details.png'
import Group9 from "../../assets/NonCustomerImages/Group 9.png";
import Assets from "../../assets/NonCustomerImages/assets-circle-modified.png";

export const ProcessArray = [
  {
    id: 1,
    Title: "PERSONAL INFORMATION REVIEW",
    Icon: Group3,
    Link: "/Customer/PersonalInformationReview",
  },
  {
    id: 2,
    Title: "YOUR LOAN REQUEST",
    Icon: Group3,
    Link: "/Customer/yourLoanRequest",
  },
  {
    id: 3,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/Customer/monthlyIncome",
  },
  {
    id: 4,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/Customer/MonthlyCreditObligations",
  },
  {
    id: 5,
    Title: "LOAN PURPOSE",
    Icon: Group6,
    Link: "/Customer/LoanPurpose",
  },
  {
    id: 6,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/Customer/ProofOfIncome",
  },
  {
    id: 7,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/Customer/AssetsDeclaration",
  },
  {
    id: 8,
    Title: "UPLOAD DOCUMENTS",
    Icon: Group8,
    Link: "/Customer/ScheduleAppointment",
  },
];

export const ProcessArraySecuredCash = [
  {
    id: 1,
    Title: "PERSONAL INFORMATION REVIEW",
    Icon: Group3,
    Link: "/Customer/PersonalInformationReview",
  },
  {
    id: 2,
    Title: "LOAN COLLATERAL",
    Icon: Group5,
    Link: "/Customer/loan-collateral",
  },
  {
    id: 3,
    Title: "MONTHLY INSTALLMENTS",
    Icon: Group7,
    Link: "/Customer/MonthlyInstallments"
  },

  {
    id: 4,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/Customer/monthlyIncome"
  },
  {
    id: 5,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/Customer/MonthlyCreditObligations"
  },
  {
    id: 6,
    Title: "LOAN PURPOSE",
    Icon: Group6,
    Link: "/Customer/LoanPurpose"
  },
  {
    id: 7,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/Customer/ProofOfIncome"
  },
  {
    id: 8,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/Customer/AssetsDeclaration"
  },

  {
    id: 9,
    Title: "UPLOAD DOCUMENTS",
    Icon: Group8,
    Link: "/Customer/ScheduleAppointment",
  }
];

export const ProcessArraySecuredVehicle = [
  {
    id: 1,
    Title: "PERSONAL INFORMATION REVIEW",
    Icon: Group3,
    Link: "/Customer/PersonalInformationReview",
  },
  {
    id: 2,
    Title: "LOAN CALCULATION",
    Icon: Group5,
    Link: "/Customer/loan-calculation",
  },
  {
    id: 3,
    Title: "VEHICLE STATUS",
    Icon: Group7,
    Link: "/Customer/vehicle-status",
  },
  {
    id: 4,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/Customer/monthlyIncome",
  },
  {
    id: 5,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/Customer/MonthlyCreditObligations",
  },
  {
    id: 6,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/Customer/ProofOfIncome"
  },
  {
    id: 7,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/Customer/AssetsDeclaration",
  },
  {
    id: 8,
    Title: "UPLOAD DOCUMENTS",
    Icon: Group8,
    Link: "/Customer/ScheduleAppointment",
  }
];


export const ProcessArraySecuredLand = [
  {
    id: 1,
    Title: "PERSONAL INFORMATION REVIEW",
    Icon: Group3,
    Link: "/Customer/PersonalInformationReview",
  },
  {
    id: 2,
    Title: "LOAN CALCULATION",
    Icon: Group5,
    Link: "/Customer/loan-calculation",
  },
  {
    id: 3,
    Title: "LAND DETAILS",
    Icon: LandDetails,
    Link: "/Customer/land-details",
  },
  {
    id: 4,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/Customer/monthlyIncome",
  },
  {
    id: 5,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/Customer/MonthlyCreditObligations",
  },
  {
    id: 6,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/Customer/ProofOfIncome",
  },
  {
    id: 7,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/Customer/AssetsDeclaration",
  },
  {
    id: 8,
    Title: "UPLOAD DOCUMENTS",
    Icon: Group8,
    Link: "/Customer/ScheduleAppointment",
  }
];

export const ProcessArrayDebtRefinance = [
  {
    id: 1,
    Title: "PERSONAL INFORMATION REVIEW",
    Icon: Group4,
    Link: "/Customer/PersonalInformationReview",
  },
  {
    id: 2,
    Title: "LOANS TO BE REFINANCED",
    Icon: Group3,
    Link: "/Customer/loans-to-be-refinanced",
  },
  {
    id: 3,
    Title: "REFINANCE REQUEST",
    Icon: Group5,
    Link: "/Customer/refinance-request",
  },
  {
    id: 4,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/Customer/monthlyIncome",
  },
  {
    id: 5,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/Customer/MonthlyCreditObligations",
  },
  {
    id: 6,
    Title: "LOAN PURPOSE",
    Icon: Group6,
    Link: "/Customer/LoanPurpose",
  },
  {
    id: 7,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/Customer/ProofOfIncome",
  },
  {
    id: 8,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/Customer/AssetsDeclaration",
  },
  {
    id: 9,
    Title: "UPLOAD DOCUMENTS",
    Icon: Group8,
    Link: "/Customer/ScheduleAppointment",
  },
];