import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasCanRefinance = createAsyncThunk(
  'appCanRefinance/CanRefinance',
  async (data) => {
    const response = await Jwt.getCanRefinance(data)
    return response.data
  }
)

const initialState = {
    canRefinancing: false
}

export const appCanRefinanceSlice = createSlice({
  name: 'appCanRefinance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasCanRefinance.fulfilled, (state, action) => {
      state.canRefinancing = action.payload.canRefinancing
    })
  }
})

export default appCanRefinanceSlice.reducer
