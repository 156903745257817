import React, { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { GUARANTEES } from './enums'
import { useStyles } from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { handleSelectOption } from '../../context'

const Guarantees = () => {
  const [guaranteeType, setGuaranteeType] = useState(null)
  const { selectOption } = useSelector(state => state.initialLoanProcess)
  const { CASH, VEHICLE, LAND } = GUARANTEES
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleGuaranteeTypeChange = (newGuaranteeType) => { //! si queremos deshabilitat cuando vuelva a dar click entonces, agregar el (evento, newGuaranteeType)
    const options = { selected: 'guarantee', option: newGuaranteeType }
    dispatch(handleSelectOption(options))
  }

  useEffect(() => {
    setGuaranteeType(selectOption.guarantee)
  }, [selectOption])

  const renderToggleButton = (value, label) => (
    <ToggleButton
      value={value}
      color={guaranteeType === value ? 'primary' : "warning"}
      className={guaranteeType === value ? classes.buttonActive : classes.buttonInactive}
      onClick={() => handleGuaranteeTypeChange(value)}
      sx={{
        width: "33%"
      }}
    >
      {label}
    </ToggleButton>
  )

  return (
    <div>
      <ToggleButtonGroup
        value={guaranteeType}
        exclusive
        // onChange={handleGuaranteeTypeChange}
        // fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '40px',
          marginTop: '20px',
          "@media (max-width: 600px)" : {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center",
            // alignItems: 'stretch', // ocupen toda la columna
          }
        }}
      >
        {renderToggleButton(CASH, 'CASH')}
        {renderToggleButton(VEHICLE, 'VEHICLE')}
        {renderToggleButton(LAND, 'LAND')}
      </ToggleButtonGroup>
    </div>
  );
};

export default Guarantees;
