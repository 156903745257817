import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasGetLoanRequest = createAsyncThunk(
  'appGetLoanRequest2/GetLoanRequest2',
  async () => {
    const response = await Jwt.getLoanRequest()
    return response.data
  }
)

const initialState = {
  id: 0,
  requestedAmount: 0,
  requestedTerms: 0,
  // debtCancellationSchemeRequested: false,
  debtCancellationSchemeFee: 0,
  fees: 0,
  totalAmount: 0,
  monthlyInstallment: 0,
  // promoCode: '',
  monthlyInstallmentWithPromoCode: null,
  createdDate: '',
  customerId: '',
  interestRate: 0,
  interestRateWithPromoCode: null,
  dateSubmitted: null,
  collateralAmount: null,
  typeOfCollateral: null,
  warrantyOption: 0,
  accountTypes: null,
  currentStep: 0,
  loanTypeId: 0,
  warrantyTypeId: null,
  referenceNumber: '',
  purchasePrice: null,
  landValue: 0,
  legalFeesTypeId: null,
  legalFees: null,
  currentMonthlyInstallment: null,
  totalPreQualifiedLoanValue: 0,
  totalColCoverValue: 0,
  typeOfLoansRefSelected: null,
  billOfSale: null,
  totalLoanRequests: null,
  currentLoanBalance: null
}

export const appGetLoanRequestSlice = createSlice({
  name: 'appGetLoanRequest2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasGetLoanRequest.fulfilled, (state, action) => {
      state.id = action.payload.id
      state.requestedAmount = action.payload.requestedAmount
      state.requestedTerms = action.payload.requestedTerms
      state.debtCancellationSchemeRequested = action.payload.debtCancellationSchemeRequested
      state.debtCancellationSchemeFee = action.payload.debtCancellationSchemeFee
      state.fees = action.payload.fees
      state.totalAmount = action.payload.totalAmount
      state.monthlyInstallment = action.payload.monthlyInstallment
      state.promoCode = action.payload.promoCode
      state.monthlyInstallmentWithPromoCode = action.payload.monthlyInstallmentWithPromoCode
      state.createdDate = action.payload.createdDate
      state.customerId = action.payload.customerId
      state.interestRate = action.payload.interestRate
      state.interestRateWithPromoCode = action.payload.interestRateWithPromoCode
      state.dateSubmitted = action.payload.dateSubmitted
      state.collateralAmount = action.payload.collateralAmount
      state.typeOfCollateral = action.payload.typeOfCollateral
      state.warrantyOption = action.payload.warrantyOption
      state.accountTypes = action.payload.accountTypes
      state.currentStep = action.payload.currentStep
      state.loanTypeId = action.payload.loanTypeId
      state.warrantyTypeId = action.payload.warrantyTypeId
      state.referenceNumber = action.payload.referenceNumber
      state.purchasePrice = action.payload.purchasePrice
      state.landValue = action.payload.landValue
      state.legalFeesTypeId = action.payload.legalFeesTypeId
      state.legalFees = action.payload.legalFees
      state.currentMonthlyInstallment = action.payload.currentMonthlyInstallment
      state.totalPreQualifiedLoanValue = action.payload.totalPreQualifiedLoanValue
      state.totalColCoverValue = action.payload.totalColCoverValue
      state.typeOfLoansRefSelected = action.payload.typeOfLoansRefSelected
      state.billOfSale = action.payload.billOfSale
      state.totalLoanRequests = action.payload.totalLoanRequests
      state.currentLoanBalance = action.payload.currentLoanBalance
    })
  }
})

// export const { handleGetBrands } = appGetLoanRequestSlice.actions

export default appGetLoanRequestSlice.reducer
