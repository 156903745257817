import React, { useState } from 'react'
import { Container } from '@mui/system'
import { Button } from '../components'
import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
  InputLabel
} from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import img from '../../assets/Images/pdf.svg'

// styles
import { useStyles } from './styles'
import ModalContainer from '../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
// services
import { hasIssuedOffer } from '../../Store/context/getIssuedOffer'
import { hasDeclineReasonsIssuedOffer } from '../../Store/context/getDeclinedReasons'
import { hasDeclinedIssuedOffer } from '../../Store/context/declineIssuedOffer'
import { hasAcceptIssuedOffer } from '../../Store/context/acceptIssuedOffer'
// images
import Declined from '../../assets/Images/Group 589.png'
import SadFace from '../../assets/Images/sad-face.png'
import Confirmed from '../../assets/Images/Group 590.png'
import { MenuItem, Select, TextareaAutosize } from '@material-ui/core'
import IconBlue from '../../assets/Images/icon-blue.png'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const IssuedOffer = () => {
  const LightTooltip2 = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#0F5CAC',
      fontSize: 14,
      maxWidth: 261,
      filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
      padding: '1rem'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      '&::before': {
        backgroundColor: 'white'
      }
    }
  }))

  const [showTooltip2, setShowTooltip2] = useState(false)

  const toggleTooltip2 = () => {
    setShowTooltip2(!showTooltip2)
  }

  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    requestedAmount,
    requestedTerms,
    interestRate,
    useOfProceed,
    expirationDate,
    fees
    // document
  } = useSelector((state) => state.issuedOffer)

  const { data: declinedReasons } = useSelector(
    (state) => state.declinedReadons
  )

  const [openModal, setOpenModal] = useState(false)
  const [isDeclined, setIsDeclined] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [fullNameConfirm, setfullNameConfirm] = useState('')
  const [readDoc, setReadDoc] = useState(false)
  const [getDeclineReasons, setGetDeclineReasons] = useState(false)
  const [selectedDeclinedReason, setselectedDeclinedReason] = useState(0)
  const [otherReasonMsg, setOtherReasonMsg] = useState('')
  // const [downloadDoc, setDownloadDoc] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    dispatch(hasIssuedOffer())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BZD'
  })

  const lettersList = 'abcdefg'

  return (
    <>
      <Container>
        <Button
          className={classes.SecondButton}
          Title='Issued Offer'
          variant='subtitle1'
          BtnVariant='contained'
        />

        <Card className={classes.CardStyles}>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>LOAN AMOUNT</FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={formatter.format(requestedAmount)}
            />
          </div>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>
              AMORTIZATION PERIOD
            </FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={`${requestedTerms} months`}
            />
          </div>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>INTEREST RATE</FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={`${(Number(interestRate) * 100).toFixed()} %`}
            />
          </div>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>
              TOTAL FEES{' '}
              <LightTooltip2
                onClick={toggleTooltip2}
                open={showTooltip2}
                placement='right'
                title={
                  'These fees are subject to change.'
                }
              >
                <img
                  onMouseEnter={() => setShowTooltip2(true)}
                  onMouseLeave={() => setShowTooltip2(false)}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleTooltip2()
                  }}
                  src={IconBlue}
                  height={'15px'}
                  alt=''
                />
              </LightTooltip2>
            </FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={formatter.format(fees)}
            />
          </div>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>PURPOSE</FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={useOfProceed}
            />
          </div>
          <div className={classes.FormStyles}>
            <FormLabel className={classes.LabelStyles}>EXPIRY DATE</FormLabel>
            <TextField
              className={classes.TextFieldStyles}
              InputProps={{
                readOnly: true
              }}
              value={moment(expirationDate).format('DD/MM/YY')}
              // value={String(expirationDate)}
            />
          </div>
          <div className={classes.OfferLetterStyles}>
            <Typography className={classes.InfoText}>
              This offer is intended to be a summary of some of the most
              important terms and conditions of our agreement to enter into a
              loan transaction with you and it is subject to all requirements
              and conditions contained in the final loan documentation which
              will be provided in due course. <br />
              <br /> The Bank reserves the right to withdraw the offer contained
              herein if at any time before the Loan Amount is drawn down any of
              the information submitted in connection with the application is
              found in the opinion of the Bank to be incorrect or misleading or
              if any adverse changes in the circumstances of the Borrower(s)
              take(s) place which the Bank in its sole discretion ​determines
              to be unacceptable.
            </Typography>
          </div>
          <RadioGroup style={{ margin: '1rem 0' }}>
            <FormControlLabel
              value='I acknowledge that I read and understand the information provided.'
              control={
                <Radio
                  onChange={() => setReadDoc(!readDoc)}
                  checked={readDoc}
                />
              }
              label='I acknowledge that I read and understand the information provided.'
            />
          </RadioGroup>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem'
            }}
          >
            <Button
              disabled={!readDoc}
              Title='DECLINE'
              variant='subtitle1'
              className={classes.Button}
              BtnVariant='contained'
              onClick={() => {
                setIsDeclined(true)
                handleOpenModal()
              }}
              style={{ background: !readDoc ? '' : '#EB5757' }}
            />
            <Button
              disabled={!readDoc}
              Title='ACCEPT'
              variant='subtitle1'
              className={classes.Button}
              BtnVariant='contained'
              onClick={() => {
                setIsConfirmed(true)
                handleOpenModal()
              }}
            />
          </div>
        </Card>
      </Container>

      {isDeclined && (
        <ModalContainer
          open={openModal}
          onClose={() => setOpenModal(false)}
          width={'603px'}
          height={'auto'}
        >
          {!getDeclineReasons ? (
            <div className={classes.ModalContent}>
              <div style={{ textAlign: 'center' }}>
                <img src={Declined} alt='' width={88} />
              </div>
              <Typography>DECLINE OFFER</Typography>
              <Typography>
                You have reached this far with your application and we would be
                pleased to discuss the options available for you. We encourage
                you to chat with your lender to obtain further details of your
                loan before declining the offer.
              </Typography>
              <Button
                Title='TAKE ME TO CHAT'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='contained'
                onClick={() => navigate('/CustomerChat?q=issued-offer')}
              />
              <Button
                Title='GO BACK TO REVIEW'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='contained'
                onClick={() => {
                  setIsDeclined(false)
                  setOpenModal(false)
                }}
              />
              <Button
                Title='PROCEED TO DECLINE'
                variant='subtitle1'
                className={classes.LightButton}
                BtnVariant='contained'
                onClick={() => {
                  dispatch(hasDeclineReasonsIssuedOffer())
                  setGetDeclineReasons(true)
                }}
              />
            </div>
          ) : (
            <div className={classes.ModalContent}>
              <div style={{ textAlign: 'center' }}>
                <img src={SadFace} alt='' width={88} />
              </div>
              <Typography color={'#005FAA'} fontWeight={700}>
                OFFER DECLINED
              </Typography>
              <Typography>
                We are sorry to see that you decided to decline our offer.
                Please let us know more about your decision.
              </Typography>

              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  sx={{ top: '-9px', color: '#005FAA', fontSize: '0.95rem' }}
                  shrink={false}
                  id='select-declined-label'
                >
                  {selectedDeclinedReason === 0 && 'Select Reason'}
                </InputLabel>
                <Select
                  labelId='select-declined-label'
                  sx={{ width: '100%', my: 2 }}
                  style={{
                    display: 'block',
                    cursor: 'default',
                    border: '2px solid #005FAA',
                    borderRadius: '0.5rem'
                  }}
                  value={selectedDeclinedReason}
                  onChange={(e) => {
                    setselectedDeclinedReason(e.target.value)
                  }}
                >
                  {declinedReasons.map((reason, idx) => {
                    return (
                      <MenuItem key={reason.id} value={reason.id}>
                        {lettersList[idx]}. {reason.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              {selectedDeclinedReason === 7 && (
                <TextareaAutosize
                  className={classes.inputFont}
                  placeholder='Please specify'
                  style={{
                    padding: '1rem',
                    border: '2px solid #005FAA',
                    borderRadius: '0.5rem'
                  }}
                  minRows={5}
                  value={otherReasonMsg}
                  onChange={(e) => setOtherReasonMsg(e.target.value)}
                />
              )}
              <Button
                disabled={!selectedDeclinedReason}
                Title='SEND'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='contained'
                onClick={() => {
                  dispatch(
                    hasDeclinedIssuedOffer({
                      decline: true,
                      declinedReasonId: selectedDeclinedReason,
                      note: otherReasonMsg
                    })
                  )
                  navigate(`/?q=${sessionStorage.getItem('code')}`)
                }}
              />
            </div>
          )}
        </ModalContainer>
      )}

      {isConfirmed && (
        <ModalContainer
          open={openModal}
          onClose={() => setOpenModal(false)}
          width={'1112px'}
          height={'auto'}
        >
          <div
            className={classes.ModalContent}
            style={{ position: 'relative' }}
          >
            <div
              style={{
                backgroundColor: '#44C84E',
                height: '87px',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0
              }}
            ></div>
            <div
              style={{ textAlign: 'center', marginTop: '1.5rem', zIndex: 10 }}
            >
              <img src={Confirmed} alt='' width={88} />
            </div>
            <Typography
              color={'#005FAA'}
              fontWeight={700}
              style={{ margin: '1rem 0' }}
            >
              ACCEPT OFFER
            </Typography>
            <div style={{ height: '250px', overflowY: 'scroll' }}>

            <Typography className={classes.fontSizeText}>
              By accepting this offer, I acknowledge and agree that I will be
              liable for all costs and fees (including attorney's fees) incurred
              by the Bank in association with the preparation of the loan
              documents and the security documents (if any) and the enforcement
              of its rights even if I subsequently rescind my acceptance of the
              offer or decline to draw down on the loan.
              <br /> <br />I further agree that any costs and fees incurred by
              the Bank will be a debt owing by me to the Bank and the Bank shall
              have the right to pursue a claim against me for repayment of the
              debt and to enforce any legal right it may have against me for the
              repayment of the debt.
            </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '1rem'
              }}
            >
              <FormLabel className={classes.LabelStylesBold}>
                ENTER YOUR FULL NAME
              </FormLabel>
              <TextField
                style={{ background: '#fff', width: '100%' }}
                className={classes.TextFieldStyles}
                placeholder='John Smith'
                value={fullNameConfirm}
                onChange={(e) => setfullNameConfirm(e.target.value)}
              />
            </div>
            <div
              style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}
            >
              <Button
                style={{ flex: '1 1 0' }}
                Title='GO BACK'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='outlined'
                onClick={() => {
                  setIsConfirmed(false)
                  setOpenModal(false)
                }}
              />
              <Button
                style={{
                  flex: '1 1 0',
                  backgroundColor: `${!fullNameConfirm ? '#9E9E9E' : '#005FAA'}`,
                  color: 'white'
                }}
                Title='CONFIRM'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='contaned'
                backgroundColor={'#9E9E9E'}
                disabled={!fullNameConfirm}
                onClick={() => {
                  dispatch(
                    hasAcceptIssuedOffer({
                      fullName: `${fullNameConfirm}`,
                      accept: true
                    })
                  )
                  // requestedAmount >= 7000
                  //   ? navigate(`/Customer/ScheduleAppointment`)
                  //   : navigate(`/Customer/SignDocuments`)
                  navigate(`/?q=${sessionStorage.getItem('code')}`)
                }}
              />
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  )
}

export default IssuedOffer
