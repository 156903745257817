import Actions from '../Types'

const initialState = {
  current: [
    {
      name: '',
      statuses: [],
      amount: 0,
      term: 0,
      status: null,
      validityJobetter: '',
    },
  ],
  completed: [],
  secure: {
    current: [
      {
        name: '',
        statuses: [],
        amount: 0,
        term: 0,
        status: null,
        validityJobetter: '',
        loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
        warrantyTypeId: null
      },
    ],
    completed: [],
  },
  unsecure: {
    current: [
      {
        name: '',
        statuses: [],
        amount: 0,
        term: 0,
        status: null,
        validityJobetter: '',
        loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
        warrantyTypeId: null
      },
    ],
    completed: [],
  },
  debtrefinancing: {
    current: [
      {
        name: '',
        statuses: [],
        amount: 0,
        term: 0,
        status: null,
        validityJobetter: '',
        loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
        warrantyTypeId: null
      },
    ],
    completed: [],
  },
  pending: true,
}

const StatusesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.GETSTATUSES:
      return {
        ...state,
        ...payload
      }
    case Actions.INITIAL_GETSTATUSES:
      return initialState

    default:
      return state
  }
}

export default StatusesReducer
