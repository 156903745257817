import React, { useEffect, useState } from "react"
import { Interceptor } from "../../../Utils/Interceptor"
import { Box } from "@mui/material"
import ArrowButton from "../../ArrowButton/ArrowButton"
import MissingDocsUpload from "./MissingDocsUpload"
import Congratulations from "../../Congratulations/Congratulations";
import { useNavigate } from "react-router-dom"
import { useRef } from "react"
import Actions from '../../../Store/Types'
import { useDispatch } from 'react-redux'

const UplaodDocuments = () => {
  const AxiosInterceptor = Interceptor()
  const dispatch = useDispatch()
  const [status, setStatus] = useState(null)
  const [Files, setFiles] = useState([])
  const countPage = useRef(0)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  const navigate = useNavigate()
  useEffect(() => {
    ;(async function () {
  
      dispatch({ type: Actions.LOADING, payload: true })
      await AxiosInterceptor.get("/customer/loan-status").then((response) => {
        setStatus(response.data.status)
      }).finally(() => dispatch({ type: Actions.LOADING, payload: false }))

      await AxiosInterceptor.get("/schedule-appointment-missing-docs").then(
        (response) => {
          console.log('UploadDocuments.js')
          setFiles(response.data)
        }
      ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))

    })()
  }, [])
  return (
    <Box>
     { !Files?.ssnImage || !Files?.jobLetterImage ? (
         <MissingDocsUpload Files={Files} />
      ) : (
        <Congratulations/>
      )} 

      <ArrowButton
        link="/Customer/SignDocuments"
        // back="/Customer/ProofOfIncome"
        back={
          sessionStorage.getItem('warrantyType') === 'cash'
            ? '/Customer/LoanPurpose'
            : '/Customer/ProofOfIncome'
        }
      />
    </Box>
  )
}

export default UplaodDocuments
