import { createSlice } from '@reduxjs/toolkit'
// import Jwt from '../../auth/jwt/Jwt'

const initialState = {
  whiteList: [],
  user: {},
  code: ''
}

export const appWhiteListSlice = createSlice({
  name: 'appWhiteList',
  initialState,
  reducers: {
    setWhiteList: (state, action) => {
      state.whiteList = action.payload || []
    },
    setUser: (state, action) => {
      state.user = action.payload || {}
    },
    setCode: (state, action) => {
      state.code = action.payload || ''
    }
}
})

export const { setUser, setWhiteList, setCode } = appWhiteListSlice.actions

export default appWhiteListSlice.reducer
