import React from "react";
import Button from "@mui/material/Button";
import { LoadingButton } from '@mui/lab';
import { Typography, styled } from "@mui/material";
import { useStyles } from "./Style";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import clsx from "clsx";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor = "#F07F3C" }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    maxWidth: 450,
    filter: "drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))",
    marginLeft: "-13rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor,
    "&::before": {
      backgroundColor: backgroundColor,
    },
  },
}));

const Btn = ({
  className,
  Title,
  variant,
  Tooltiptitle,
  BtnVariant,
  backgroundColor, // = "#F07F3C !important"
  endIcon,
  startIcon,
  onClick,
  loading,
  style,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  return (
    <LightTooltip
      title={Tooltiptitle ? Tooltiptitle : ""}
      arrow
      placement="top-start"
      backgroundcolor={backgroundColor}
      open={open}
      onClick={() => setOpen(!open)}
    >
      <LoadingButton
        variant={BtnVariant}
        className={clsx(classes.Button, className)}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        style={style}
        loading={loading}
      >
        <Typography variant={variant} className={classes.btnTitle}>
          {Title}
        </Typography>
      </LoadingButton>
    </LightTooltip>
  );
};
export default Btn;
