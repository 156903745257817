import Actions from "../Types";

const initialState = {
  phone: "",
  code: "",
  codeError: false,
  isLoading: false,
  otpSuccess: false,
  referenceNumber: "",
  token: "",
};

const OtpValidationReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.OTPVALIDATION:
      return {
        ...state,
        referenceNumber: payload.referenceNumber,
        token: payload.token,
      };
    case Actions.OTPERROR:
      return {
        ...state,
        codeError: payload,
      };
    case Actions.LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case Actions.OTPSUCCESS:
      return {
        ...state,
        otpSuccess: true,
      };
    default:
      return state;
  }
};

export default OtpValidationReducer;
