import Actions from "../Types"

const initialState = {
  requestedAmount: 0, // number
  requestedTerms: 0, // number
  // debtCancellationSchemeRequested: false,
  debtCancellationSchemeFee: "", // number
  fees: "", // number
  totalAmount: 0,
  monthlyInstallment: "", // number
  // promoCode: "",
  monthlyInstallmentWithPromoCode: 0,
  createdDate: "",
  customerId: "",
  interestRate: 0,
  interestRateWithPromoCode: 0,
  referenceStatus: false,
  amount: 0,
  terms: 0,
  totalDebtCancellationSchemeFee: 0,
  isPromoValid: null,
  currentStep: 1,
  collateralAmount: 0,
  warrantyTypeId: null,
  landValue: null,
  legalFeesTypeId: null,
  billOfSale: null,
  totalLoanRequests: null,
  percentagePreQualifiedAccepted: null,
  currentLoanBalance: null,
  totalPreQualifiedLoanValue: null
}
const returnToInitialState = () => {
  return {
    requestedAmount: 0, // number
    requestedTerms: 0, // number
    // debtCancellationSchemeRequested: false,
    debtCancellationSchemeFee: "", // number
    fees: "", // number
    totalAmount: 0,
    monthlyInstallment: "", // number
    // promoCode: "",
    monthlyInstallmentWithPromoCode: 0,
    createdDate: "",
    customerId: "",
    interestRate: 0,
    interestRateWithPromoCode: 0,
    referenceStatus: false,
    amount: 0,
    terms: 0,
    totalDebtCancellationSchemeFee: 0,
    isPromoValid: null,
    currentStep: 1,
    collateralAmount: 0,
    loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
    warrantyTypeId: null,
    landValue: null,
    legalFeesTypeId: null,
    legalFees: null,
    totalPreQualifiedLoanValue: null,
    typeOfLoansRefSelected: [],
    currentMonthlyInstallment: null,
    billOfSale: null,
    totalLoanRequests: null,
    percentagePreQualifiedAccepted: null,
    currentLoanBalance: null
  }

}

const LoanRequestReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.LOANREQUEST:
      return {
        ...state,
        fees: payload.fees,
        monthlyInstallment: payload.monthlyInstallment,
        debtCancellationSchemeFee: payload.debtCancellationSchemeFee,
        requestedAmount: payload.requestedAmount,
        requestedTerms: payload.requestedTerms,
        referenceStatus: payload.referenceStatus,
        promoCode : payload.promoCode,
        totalDebtCancellationSchemeFee : payload.totalDebtCancellationSchemeFee,
        monthlyInstallmentWithPromoCode : payload.monthlyInstallmentWithPromoCode,
        currentStep: payload.currentStep,
        loanTypeId: payload.loanTypeId,
        warrantyTypeId: payload.warrantyTypeId,
        totalAmount: payload.totalAmount,
        landValue: payload.landValue,
        legalFeesTypeId: payload.legalFeesTypeId,
        legalFees: payload.legalFees,
        totalPreQualifiedLoanValue: payload.totalPreQualifiedLoanValue,
        typeOfLoansRefSelected: payload.typeOfLoansRefSelected,
        currentMonthlyInstallment: payload.currentMonthlyInstallment,
        billOfSale: payload.billOfSale,
        totalLoanRequests: payload.totalLoanRequests,
        percentagePreQualifiedAccepted: payload.percentagePreQualifiedAccepted,
        currentLoanBalance: payload.currentLoanBalance
      }

    case Actions.SETLOADNREQUEST:
      return {
        ...state,
        ...payload,
      }
    case Actions.SETAMOUNTDETAILS:
      return {
        ...state,
        amount: payload,
      }
    case Actions.SETTERMSTDETAILS:
      return {
        ...state,
        terms: payload,
      }
      case Actions.SETLEGALFEESTYPES:
        return {
          ...state,
          legalFeesTypeId: payload,
        }
    case Actions.SETISVALIDPROMOCODE:
      return {
        ...state,
        isPromoValid:  {...payload}
      }
    case Actions.PROMOCODEINVALID:
      return {
        ...state,
        promoCode: payload
      }
    case Actions.SETMONTHLYINSTALLMENTWITHPROMOCODE: 
      return {
        ...state,
        monthlyInstallmentWithPromoCode : payload
      }
    case Actions.CLEARLOANREQUESTSTATE :
        return returnToInitialState()
    case Actions.SETTOTALPREQUALIFIEDLOANVALUE :
      return {
        ...state,
        totalPreQualifiedLoanValue : payload
      }
     default:
      return state
  }
}


export default LoanRequestReducer
