import * as Yup from 'yup'

export const transformCurrencyValue = (value, originalValue) => {
  if (!value || !originalValue) return
  if (originalValue) {
    const numericValue = parseFloat(String(originalValue).replace(/,/g, '')) || 0
    return isNaN(numericValue) ? value : numericValue
  }
  return value
}

export const transformNumberValue = (value) => (isNaN(value) ? '' : value)

export const amountValidation = Yup.number()
  .transform(transformCurrencyValue)
  .min(1000, 'Must be greater than 1000')
  .required('Required')

export const termValidation = Yup.number()
  .transform(transformNumberValue)
  .min(12, 'Must be equal to or greater than 12 months')
  .required('Required')
