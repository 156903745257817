import React, { useRef, useState } from "react"
import { Box, Stack } from "@mui/material"
import ArrowButton from "../../ArrowButton/ArrowButton"
import { useStyles } from "./ScheduleAppointmentStyle3"
import Calendar from "../../../assets/Images/Calendar.png"
import { Typography } from "@material-ui/core"
import Button from "../../Button/Button"
import clsx from "clsx"
import { Link } from "react-router-dom"
import LocalStorageShedule from "../../../Utils/LocalStorageShedule"
import ModalRefFriend from "../SignDocuments/ModalRefFriend"
import { useEffect } from "react"
import moment from "moment"

const ScheduleThankYou = () => {
  const customer_schedule = JSON.parse(LocalStorageShedule.getShedule())
  // const sheduleData = JSON.parse(customer_schedule)
  const classes = useStyles()
  const inputFile = useRef(null)
  const [open, setOpenModal] = useState(false)
  const firstName = customer_schedule?.firstName
  const lastName = customer_schedule?.lastName
  const branch = customer_schedule?.branch
  const appointmentDate = customer_schedule?.appointmentDate
  const appointmentTime = customer_schedule?.appointmentTime

  const [showDearUser, setShowDearUser] = useState(false)

  useEffect(() => {
    if (firstName && branch) setShowDearUser(true)
    else setShowDearUser(false)
  }, [firstName])
  
  
  const handleOpen = () => {
    setOpenModal(true)
  }

  return (
    <Box>
      <ModalRefFriend open={open} setOpen={setOpenModal} link={`/?q=${sessionStorage.getItem('code')}`}/>
      {showDearUser && ( //! el componenete es mostrado en /Customer/ScheduleAppointment cuando es aprobado por mas de 7k pero no ha hecho la cita
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouText}>
            <strong>Dear {`${firstName} ${lastName}`}</strong> <br />
            Your appointment to sign the loan agreements is set. <br />
            Visit {` ${branch}`} branch on {moment(appointmentDate).format('DD-MM-YYYY')} {moment(appointmentTime).format('LT')} <br /> See you soon!
          </Typography>
          <Box className={classes.ThankYouBtn}>
            {/* <Link to="/" className={classes.Link}> */}
              <Button Title="THANK YOU" onClick={handleOpen}/>
            {/* </Link> */}
          </Box>
        </Box>
      </Box>
      )}

    </Box>
  )
}

export default ScheduleThankYou
