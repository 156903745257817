const unsecuredLinks = {
  proofOfIncome: {
    back: "/Customer/MonthlyCreditObligations",
    next: "/Customer/AssetsDeclaration",
  },
}

const vehicleLinks = {
  monthlyIncome: {
    back: '/Customer/vehicle-status',
    next: '/Customer/MonthlyCreditObligations'
  },
  proofOfIncome: {
    back: "/Customer/MonthlyCreditObligations",
    next: "/Customer/ScheduleAppointment",
  },
  monthlyCreditObligations: {
    back: '/Customer/monthlyIncome',
    next: '/Customer/AssetsDeclaration'
  },
  assetsDeclaration: {
    back: '/Customer/ProofOfIncome',
    next: '/Customer/ScheduleAppointment  '
  },
}

const cashLinks = {
  monthlyInstallments: {
    back: '/Customer/loan-collateral',
    next: '/Customer/monthlyIncome'
  },
  monthlyIncome: {
    back: '/Customer/MonthlyInstallments',
    next: '/Customer/MonthlyCreditObligations'
  },
  monthlyCreditObligations: {
    back: '/Customer/monthlyIncome',
    next: '/Customer/LoanPurpose'
  },
  loanPurpose: {
    back: '/Customer/MonthlyCreditObligations',
    next: '/Customer/ProofOfIncome'
  },
  proofOfIncome: {
    back: '/Customer/LoanPurpose',
    next: '/Customer/AssetsDeclaration'
  },
  assetsDeclaration: {
    back: '/Customer/ProofOfIncome',
    next: '/Customer/ScheduleAppointment'
  },
}

const landLinks = {
  monthlyIncome: {
    back: '/Customer/land-details',
    next: '/Customer/MonthlyCreditObligations'
  },
  monthlyCreditObligations: {
    back: '/Customer/monthlyIncome',
    next: '/Customer/ProofOfIncome'
  },
}

const customLinks = {
  unsecured: unsecuredLinks,
  secured: {
    vehicle: vehicleLinks,
    cash: cashLinks,
    land: landLinks,
  }
}

export const getLinks = (loanType = 'unsecure', warrantyType = '') => {
  if (loanType === 'secure') {
    return customLinks.secured[warrantyType]
  }
  return customLinks.unsecured
}
