export class Loan {
  #loanType = null
  #typesOfLoans = { secure: 'secure', unsecure: 'unsecure' }

  constructor() {
  }

  isValidLoanType (loan) {
    return this.#typesOfLoans.hasOwnProperty(loan)
  }

  }
