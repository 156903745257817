import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  fontSizeText: {
  '&.MuiTypography-root': {
    '@media (max-width: 500px)': {
      fontSize: '14px'
    }
  }
    
  },
  inputFont:{
    fontFamily: theme.fonts.family.primary,
  },
  Button: {
    '&:hover':{
      color: '#fff',
      backgroundColor: '#005FAA !important',
    }
  },

  LightButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#1976d2',
      '&:hover':{
        color: '#fff',
        backgroundColor: '#56AF31 !important',
      }
    }
  },
  SecondButton: {
    '&.MuiButtonBase-root': {
      height: '52.22px',
      width: '100%',
      justifyContent: 'flex-start',
      backgroundColor: '#44C84E',
      '&:hover': {
        backgroundColor: '#44C84E'
        // backgroundColor: '#F4EBFE !important',
        // color: theme.colors.primary.Darkblue
      },
      '& .MuiTypography-root': {
        fontSize: '2rem',
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: '0.2em',
        textAlign: 'left',
        textTransform: 'capitalize'
      },
      '@media (max-width: 1100px)': {
        margin: '0'
      },
      '@media (max-width: 500px)': {
        width: '100%',
        marginBottom: 20
      }
    }
  },
  CardStyles: {
    margin: '2rem 0',
    padding: '2rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  FormStyles: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    maxWidth: '500px',
    marginBottom: '2.5rem'
  },
  LabelStyles: {
    fontSize: '0.9rem !important'
  },
  LabelStylesBold: {
    fontSize: '0.9rem !important',
    fontWeight: '700'
  },
  TextFieldStyles: {
    '& .MuiInputBase-input': {
      fontSize: '0.9rem !important',
      // color: '#B2B2B2',
      padding: '0.8rem'
    }
  },
  OfferLetterStyles: {
    backgroundColor: '#F4EBFE',
    width: '100%'
  },
  OfferLetterTitle: {
    backgroundColor: '#005FAA',
    color: '#fff',
    padding: '.5rem',
    fontSize: '0.8rem',
    textAlign: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  OfferLetterContent: {
    padding: '2rem'
  },
  InfoText: {
    margin: '2rem 0 !important',
    padding: '2rem',
    backgroundColor: '#F4EBFE'
  },
  ModalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '1rem',
    gap: '1rem',
    textAlign: 'center'
  }
}))
