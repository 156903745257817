import React, { useState } from "react";
import MessageLogo from "../../assets/Images/MessageLogo.png";
import { Box, Typography, useTheme } from "@mui/material";
import { useStyles } from "./OtpPopUpStyle.js";
import Dialog from "@mui/material/Dialog";
import clock from "../../assets/Images/clock.png";
import VerificationInput from "../Inputs/VerificationInput";
import { otpValidation } from "../../Store/Actions/OtpValidation";

import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

const LoanPurposeOtp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [pin, setPin] = useState("");

  const phone = useSelector((state) => state.LetsMeetFormReducer.phone);
  const otpSuccess = useSelector((state) => state.OtpValidation.otpSuccess);

  const getOtp = () => {
    dispatch(otpValidation({ phone: phone, code: pin }));
  };
  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={false}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.PopupContainer}>
        <Box className={classes.PopupHeader}></Box>

        <Box className={classes.PopupMainSection}>
          <Box className={classes.PopupHeaderLogo}>
            <img className={classes.Logo} src={MessageLogo} alt="Logo" />
          </Box>

          <Typography className={classes.PopupText}>
            We need to validate your email address as it will be required on the
            other steps of your application. Email confirmation was sent to the
            email address provided at the beginning of your online loan
            application.
          </Typography>
          <Box className={classes.PopupExpireTime} sx={{ marginTop: "0px" }}>
            <img className={classes.ClockImage} src={clock} alt="clock" />
            <Typography className={classes.ExpireTimeText}>
              The code will expire in 15 minutes
            </Typography>
          </Box>
          <Typography
            className={classes.ExpireTimeText}
            sx={{ marginTop: "0px", marginBottom: "20px" }}
          >
            Enter the confirmation code now{" "}
          </Typography>
          <VerificationInput
            length={5}
            onChange={(e) => setPin(e)}
            sx={{
              borderRadius: "8px",
              fontSize: "16px",
              marginBottom: "20px",
            }}
          />
          <Box className={classes.PopupBtn}>
            <Button
              className={classes.PopupButton}
              onClick={getOtp}
              sx={{ fontSize: "13px" }}
            >
              VALIDATE CODE
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LoanPurposeOtp;
