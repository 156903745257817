export const LoanTermsDetails = [
  { id: 1, value: 12, terms: "12 months" },
  { id: 2, value: 18, terms: "18 months" },
  { id: 3, value: 24, terms: "24 months" },
  { id: 4, value: 36, terms: "36 months" },
  { id: 5, value: 48, terms: "48 months" },
  { id: 6, value: 60, terms: "60 months" },
]


export const LoanTermsDetailsFully = [
  { id: 1, value: 12, terms: "12 months" },
  { id: 2, value: 18, terms: "18 months" },
  { id: 3, value: 24, terms: "24 months" },
  { id: 4, value: 36, terms: "36 months" },
  { id: 5, value: 48, terms: "48 months" },
  { id: 6, value: 60, terms: "60 months" },
  { id: 9, value: 72, terms: "72 months" },
  { id: 10, value: 84, terms: "84 months" },
  { id: 11, value: 96, terms: "96 months" },
  { id: 12, value: 108, terms: "108 months" },
  { id: 13, value: 120, terms: "120 months" },
]