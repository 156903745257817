import { Box, Stack } from '@mui/material'
import { Field } from 'formik'
import React from 'react'
import SelectInput from './select-input'
import Currency from '../../../../Components/Inputs/currency2'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useStyles } from './Style'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeSelect, onChangeText, setStateErrors, setTempAssets } from '../store'

const errorMessages =
  'This field is mandatory. If you prefer delete the option (x)'
const AssetsGroup = ({
  input,
  lastOne = false,
  addInput = () => {},
  deleteInput = () => {},
  ID,
  formik
  // options = []
}) => {
  const { select, text } = input
  const { tempAssets: options, customerAssets, assets } = useSelector((state) => state.assetsDeclaration)
  const [errors, setErrors] = React.useState({
    select: false,
    text: false
  })
  const dispatch = useDispatch()

  const onChangeInput = (e, value, field) => {
    const { name } = field
    let inputValue = value
    if (value === "" || !value.length) {
      inputValue = '0'
    }
    formik.setFieldTouched(name)
    formik.setFieldValue(name, inputValue)
    dispatch(onChangeText({ id: ID, value: value }))
  }

  React.useEffect(() => {

    dispatch(setTempAssets())
  }, [customerAssets])

  const handleOnChange = (asset) => {
    if (!asset?.id) return
    const { id: assetId = null } = asset
    dispatch(onChangeSelect({ id: ID, assetId }))
  }

  React.useEffect(() => {
    const asset = {
      id: customerAssets[ID]?.id,
      assetId: customerAssets[ID]?.select?.assetId,
      amount: parseFloat(customerAssets[ID]?.text?.value)
    }

    if (asset?.assetId && !asset?.amount) return setErrors({ select: false, text: true })

    if (asset?.assetId && asset?.amount >= 0) return setErrors({ select: false, text: false })

    if (!asset?.assetId && asset?.amount >= 0) return setErrors({ select: true, text: false })

    if (!asset?.assetId && !asset?.amount) return setErrors({ select: false, text: false })

  }, [customerAssets, ID])

  React.useEffect(() => {
    dispatch(setStateErrors({[ID]: errors}))
  }, [errors])

  const classes = useStyles()

  const renderError = (isError) => {
    return (
      <div className={classes.SelectLable} style={{ color: 'red', fontFamily: 'sans-serif', fontSize: '12px' }}>
        {isError && errorMessages}
      </div>
    )
  }

  return (
    <div key={ID}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 2, md: 3 }}
        alignItems='start'
        mb={13}
      >
        <Stack
          width={{ xs: '100%', sm: '100%' }}
          gap={2}
          alignItems='start'
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Stack width={{ xs: '100%', sm: '100%' }}>
            <Field name={`assets-${ID}`}>
              {({ field, form, meta }) => (
                <SelectInput
                  {...field}
                  Label={'Assets'}
                  placeholder={'Select Assets declaration'}
                  options={options}
                  error={meta.touched && meta.error}
                  onChange={(e, value) => handleOnChange(value)}
                  value={{
                    ...assets.find((option) => option?.id === select?.assetId)
                  }}
                />
              )}
            </Field>
            {renderError(errors?.select)}
          </Stack>

          <Stack
            width={{ xs: '100%', sm: '100%' }}
            direction={{ xs: 'row', sm: 'row' }}
            gap={2}
          >
            <Box width={{ xs: '80%', sm: '90%' }}>
              <Field name={`amount-${ID}`}>
                {({ field, form, meta }) => (
                  <Currency
                    // {...field}
                    Lable={text?.label}
                    placeholder={text?.placeholder || '0.00'}
                    name={field.name}
                    error={meta.touched && meta.error}
                    onChange={(e, value) => onChangeInput(e, value, field)}
                    value={meta.value || 0}
                  />
                )}
              </Field>
              {renderError(errors?.text)}
            </Box>
            <Box width={{ xs: '20%', sm: '10%' }}>
              {lastOne && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    justifyContent: 'center'
                  }}
                >
                  <AddCircleIcon className={classes.PlusIcon} onClick={addInput} />
                  {ID !== 0 && (
                    <HighlightOffIcon
                      onClick={() => deleteInput(input?.id)}
                      className={classes.RemoveIcon}
                    />
                  )}
                </div>
              )}
              {!lastOne && (
                <HighlightOffIcon
                  onClick={() => deleteInput(input?.id)}
                  className={classes.RemoveIcon}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </div>
  )
}

export default AssetsGroup
