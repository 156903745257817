import { configureStore } from '@reduxjs/toolkit'
import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import LetsMeetFormReducer from "./Reducers/LetsMeetFormReducer"
import OtpValidation from "./Reducers/OtpValidationReducer"
import LoanRequestReducer from "./Reducers/LoanRequestReducer"
import GetMessagesReducer from "./Reducers/GetMessagesReducer"
import MakeScheduleReducer from "./Reducers/MakeScheduleReducer"
import ReferenceStatusReducer from "./Reducers/ReferenceStatusReducer"
import OtpEmailReducer from "./Reducers/OtpEmailReducer"
import ScheduleRequestReducer from './Reducers/YourrLoanRequestScheduleAppReducer'
import StatusesReducer from "./Reducers/StatusesReducer"
import UploadDocumentsReducer from "./Reducers/UploadDocumentsReducer"
import initialLoanProcess from "./../Pages/initial-loan-process/context/"
import cashLoanCollateral from "./../Pages/initial-loan-process/screen/loan-collateral/context/"
import vehicleBrand from "./context/getVehicleBrands"
import vehicleDetails from "./context/postVehicleDetails"
import issuedOffer from './context/getIssuedOffer'
import declineIssuedOffer from './context/declineIssuedOffer'
import acceptIssuedOffer from './context/acceptIssuedOffer'
import declinedReadons from './context/getDeclinedReasons'
import vehiclePictures from './context/sendVehicleImages'
import districtData from './context/getDistrict'
import urbanAreas from './context/getUrbanAreas'
import registrationSection from './context/getRegistrationSection'
import landDocs from './context/postLandDocs'
import creditCard from './context/postCreditCard'
import deleteLoanRequest from './context/deleteLoanRequest'
import canRefinance from './context/getCanDebtRefinance'
import loansToBeRefinanced from './context/getLoansToBeRefinanced'
import getLoanRequest from './context/getLoanRequest'
import steeps from '../Components/ProcessSteps/store'
import postVehicleDocs from './context/postVehicleDocs'
import whiteList from './context/white-list'

// ! non-secured (START)
import assetsDeclaration  from '../Components/Forms/AssetsDeclaration/store'
// ! non-secured (END)
const rootReducer = combineReducers({
  LetsMeetFormReducer: LetsMeetFormReducer,
  OtpValidation: OtpValidation,
  LoanRequestReducer: LoanRequestReducer,
  GetMessagesReducer: GetMessagesReducer,
  MakeScheduleReducer: MakeScheduleReducer,
  ReferenceStatusReducer: ReferenceStatusReducer,
  OtpEmailReducer: OtpEmailReducer,
  ScheduleRequestReducer: ScheduleRequestReducer,
  StatusesReducer: StatusesReducer,
  UploadDocumentsReducer: UploadDocumentsReducer,
  //* Nuevos reducers
  initialLoanProcess,
  cashLoanCollateral,
  vehicleBrand,
  vehicleDetails,
  issuedOffer,
  declineIssuedOffer,
  acceptIssuedOffer,
  declinedReadons,
  vehiclePictures,
  districtData,
  urbanAreas,
  registrationSection,
  landDocs,
  creditCard,
  deleteLoanRequest,
  canRefinance,
  loansToBeRefinanced,
  getLoanRequest,
  postVehicleDocs,
  whiteList,
  // ! non-secured (START)
  assetsDeclaration,
  // ! non-secured (END)
  steeps
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

const ConfigureStore = () => {
  const rootReducer = combineReducers({
    LetsMeetFormReducer: LetsMeetFormReducer,
    OtpValidation: OtpValidation,
    LoanRequestReducer: LoanRequestReducer,
    GetMessagesReducer: GetMessagesReducer,
    MakeScheduleReducer: MakeScheduleReducer,
    ReferenceStatusReducer: ReferenceStatusReducer,
    OtpEmailReducer: OtpEmailReducer,
    ScheduleRequestReducer: ScheduleRequestReducer,
    StatusesReducer: StatusesReducer,
    UploadDocumentsReducer: UploadDocumentsReducer,
  })

  const store = createStore(rootReducer, applyMiddleware(thunk))

  return { store }
}

export default store
// export default ConfigureStore
