import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useStyles } from './Style'

const FACES = [
  { bgcolor: '#EA344D', content: ': (', value: 1 },
  { bgcolor: '#FF7F1E', content: ': (', value: 2 },
  { bgcolor: '#FFCF2C', content: ': |', value: 3 },
  { bgcolor: '#ADDB19', content: ': )', value: 4 },
  { bgcolor: '#005FAA', content: ': )', color: '#FFF', value: 5 },
]

export const QuestionFace = ({ questionInf, dataForm, setData }) => {
  const classes = useStyles()
  const [levelColor, setLevelColor] = useState(0)

  const handleClick = (answer) => {
    setLevelColor(answer)
    setData({...dataForm, [questionInf.id]: {questionId: questionInf.id, answer: String(answer)}})
  }

  return (
    <>
      <Typography className={classes.QuestionTitle}>
        {questionInf?.question}
      </Typography>
      <Stack direction="row" className={classes.QuestionContenedor}>
        {FACES.map((face, i) => (
          <Avatar
            onClick={() => handleClick(face.value)}
            key={i}
            sx={{
              bgcolor: `${face.value <= levelColor ? face.bgcolor : ''}`,
              rotate: '90deg',
              color: `${face.color || 'black'}`,
              marginLeft: '10px',
              fontSize: '1.5rem',
              width: '50px',
              height: '50px',
            }}
          >
            <b>{face.content}</b>
          </Avatar>
        ))}
      </Stack>
    </>
  )
}

// export default QuestionFace
