import React from "react";
import Box from "@mui/material/Box";
import { useStyles } from "./EnrollNowPageStyle";
import EnrollNowPageLinks from "../../Components/EnrollNowPageLinks/EnrollNowPageLinks";
import { Stack } from "@mui/system";

const EnrollNowPage = () => {
  const classes = useStyles();
  return (
    <Stack direction="column" className={classes.MainContainer}>
      <EnrollNowPageLinks />
    </Stack>
  );
};

export default EnrollNowPage;
