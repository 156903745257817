import React, { useEffect, useState } from "react";
import { Box, styled, useTheme } from "@mui/material";
import InputBox from "./Input";

const CustomTextField = styled(InputBox)({
  "& .MuiInputBase-root": {
    marginTop: "unset",
    margin: "0px 4.74px",
    "& .MuiInputBase-input": {
      padding: "unset",
      width: "27.41px",
      height: "33.88px",
      background: "#E1E1E1",
      color: "black",
      fontSize: "17px",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  "& .MuiOutlinedInput-root": {
    fontWeight: 400,
    textAlign: "center",
    "& fieldset": {
      borderRadius: "0.5rem",
      color: "red",
    },
    "& legend": {
      display: "none",
    },
  },
});
const VerificationBox = ({ length, sx, onChange }) => {
  const [code, setCode] = useState([...Array(length)].map((x, i) => ""));
  const theme = useTheme();
  const NumberOfBox = [...Array(length)].map((x, i) => (i + 1) * 1);

  const inputFocus = (element) => {
    if (element.key === "Tab" || element.key === "Shift") {
      return;
    } else if (element?.key === "Delete" || element?.key === "Backspace") {
      const next = NumberOfBox[element?.target?.id] - 2;
      if (next > -1) {
        element.target.form.elements[next].focus();
      }
    } else {
      RegExp(/\d/).test(element.key) &&
        handleChange(element.key, element.target.id);
      const next = NumberOfBox[element.target.id];
      if (next <= 12 && element.target?.value !== "") {
        element?.target?.form?.elements[next]?.focus();
      }
    }
  };

  const handleChange = (value, index) => {
    code[index] = value[0];
    setCode([...code]);
  };

  useEffect(() => {
    onChange(code.join(""));
  }, [code]);

  return (
    <form>
      <Box display="flex" justifyContent="center">
        {NumberOfBox.map((item, index) => (
          <CustomTextField
            id={index.toString()}
            type="number"
            value={code[index]}
            onKeyUp={(e) => inputFocus(e)}
            onChange={(e) => handleChange(e.target.value, index)}
            inputProps={{
              style: {
                textAlign: "center",
                background: '#fff',
                border: '1px solid #73B2FF',
                width: '43px',
                height: '42px',
                filter: 'drop-shadow(2px 2px 2px rgba(40, 173, 247, 0.25))'
              },
            }}
            sx={{
              input: {
                color: theme.palette.primary.contrastText,
              },
              ...sx,
            }}
            key={index}
          />
        ))}
      </Box>
    </form>
  );
};
export default VerificationBox;
