import React from "react"
import { Typography } from "@mui/material"
import Success from "../../assets/Images/Success.png"
import Approved from "../../assets/Images/Approved.png"
import Cross from "../../assets/Images/Cross.png"
import { Box, Stack } from "@mui/system"
import { useSelector } from "react-redux"
import { useStyles } from "./ReferencePopUpStyle"

const InfoStatusReference = () => {
  const classes = useStyles()
  const statusCode = useSelector(
    (state) => state.ReferenceStatusReducer.referenceCode
  )
  const { referenceDeclined: declined, referenceFinalized: finalized } =
    useSelector((state) => state.ReferenceStatusReducer)

    const srcImage = declined ? Cross : Approved
  return (
    <Box m={6}>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={`${classes.ApprovedPoppupImage} ${declined && classes.Declined}`}>
            <img className={classes.ApprovedImage} src={srcImage} alt="" />
          </Box>
          {declined && (
            <Typography className={classes.ApprovedText}>
              Your loan was <strong>not approved.</strong> If you wish to get
              more details about the reason, please contact us at
              onlineloans@belizebank.com
            </Typography>
          )}
          {finalized && (
            <Typography className={classes.ApprovedText}>
              Your loan has been <strong>Finalized</strong>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InfoStatusReference
