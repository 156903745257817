import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasIssuedOffer = createAsyncThunk(
  'appIssuedOffer/issuedOffer',
  async () => {
    const response = await Jwt.getIssuedOffer()
    return response.data
  }
)

const initialState = {
    requestedAmount: 0,
    requestedTerms: 0,
    interestRate: 0,
    useOfProceed: '',
    document: null,
    status: 0,
    fees: null,
    expirationDate: null
}

export const appIssuedOfferSlice = createSlice({
  name: 'appIssuedOffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasIssuedOffer.fulfilled, (state, action) => {
      state.requestedAmount = action.payload.requestedAmount
      state.requestedTerms = action.payload.requestedTerms
      state.interestRate = action.payload.interestRate
      state.useOfProceed = action.payload.useOfProceed
      state.document = action.payload.document
      state.status = action.payload.status
      state.fees = action.payload.fees
      state.expirationDate = action.payload.expirationDate
    })
  }
})

// export const { handleGetBrands } = appIssuedOfferSlice.actions

export default appIssuedOfferSlice.reducer
