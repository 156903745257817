import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  TooltipContainer: {
    backgroundColor: "#fff !important",
  },

  TooltipWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
  },

  IconContainer: {
    height: 85,
    width: 60,
    backgroundColor: theme.colors.primary.LightGrey,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  Icon: {
    width: 31,
    height: 31,
  },

  Content: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontFamily: theme.fonts.family.tertiary,
      fontWeight: 300,
      lineHeight: 2,
      color: theme.colors.primary.Grey,
      width: 321,
      marginLeft: 10,
    },
  },
}));
