import { Card, Dialog } from '@mui/material'
import React from 'react'
import { useStyles } from './styles'

const ModalContainer = ({
  open,
  onClose,
  width,
  height,
  borderRadius,
  children
}) => {
  const classes = useStyles()
  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} className={classes.DialogStyles} maxWidth={width}>
      <Card
        style={{ width: '100%', maxWidth: width, height: height, borderRadius: borderRadius, overflow: 'hidden' }}
      >
        {children}
      </Card>
    </Dialog>
  )
}

export default ModalContainer
