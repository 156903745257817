export const LoanTermsDetails = [
  { id: 1, value: 12, key: "terms", label: "12 months" },
  { id: 2, value: 24, key: "terms", label: "24 months" },
  { id: 3, value: 36, key: "terms", label: "36 months" },
  { id: 4, value: 48, key: "terms", label: "48 months" },
  { id: 5, value: 60, key: "terms", label: "60 months" },
  { id: 6, value: 72, key: "terms", label: "72 months" },
  { id: 7, value: 84, key: "terms", label: "84 months" },
  { id: 8, value: 96, key: "terms", label: "96 months" },
  { id: 9, value: 108, key: "terms", label: "108 months" },
  { id: 10, value: 120, key: "terms", label: "120 months" },
  { id: 11, value: "TERMS", key: "terms", label: "OTHER"}
]


export const LoanAmountDetails = [
  { id: 1, value: 5000, key: "amount", label: "BZD 5,000" },
  { id: 2, value: 10000, key: "amount", label: "BZD 10,000" },
  { id: 3, value: 15000, key: "amount", label: "BZD 15,000" },
  { id: 4, value: 20000, key: "amount", label: "BZD 20,000" },
  { id: 5, value: 25000, key: "amount", label: "BZD 25,000" },
  { id: 6, value: 30000, key: "amount", label: "BZD 30,000" },
  { id: 7, value: 35000, key: "amount", label: "BZD 35,000" },
  { id: 8, value: 40000, key: "amount", label: "BZD 40,000" },
  { id: 9, value: 45000, key: "amount", label: "BZD 45,000" },
  { id: 10, value: 50000, key: "amount", label: "BZD 50,000" },
  { id: 11, value: "AMOUNT", key: "amount", label: "OTHER"}
]

