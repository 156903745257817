import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    appointmentMobile: {
        '&.MuiGrid-root' : {
        '@media (max-width: 900px)': {
          flexDirection: 'column',
          gap: '0.5rem'
        },
    },
      },
  NextButton: {
    width: "200px",
    
    height: 39.01,
    "&.MuiButtonBase-root": {
        backgroundColor: theme.colors.primary.Darkblue,
        "&:hover": {
            backgroundColor: theme.colors.secondary.Green,
        },
        "& .MuiTypography-root": {
            fontSize: 13,
            fontFamily: theme.fonts.family.primary,
            color: "White",
            fontWeight: 600,
            letterSpacing: "0.2em",
        },
    },
},

}));
