import { Stack } from '@mui/system'
import { useStyles } from './LoanHistoryStyles'
import DownloadIcon from '@mui/icons-material/Download'
import Button from '../../Components/Button/Button'
import { useMovil } from '../../hooks/useMovil'
import AlertInfo from '../Alert'
import EmployementVerification from '../../assets/pdf/Employment Verification Letter.pdf'
import SalaryAssignment from '../../assets/pdf/Salary Assignment.pdf'
const AcordionOtherDocs = () => {
  const classes = useStyles()
  const { movil, message } = useMovil('othermessage')

  const handleClickDownload = (doc, name) => {
    const url = doc
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="column" width={{ xs: '100%', sm: '49%' }} mt={2}>
        {!movil ? (
          <>
            <Button
              Title="Salary Assignment"
              variant="h7"
              className={classes.UploadFiles}
              onClick={() => handleClickDownload(SalaryAssignment, 'Salary Assignment Form')}
              endIcon={<DownloadIcon sx={{ fill: 'black !important' }} />}
            />
            <Button
              Title="Employment Verification Form"
              variant="h7"
              className={classes.UploadFiles}
              onClick={() => handleClickDownload(EmployementVerification, 'Employment Verification Form')}
              endIcon={<DownloadIcon sx={{ fill: 'black !important' }} />}
            />
          </>
        ): (<AlertInfo content={message} />)}
      </Stack>
    </Stack>
  )
}

export default AcordionOtherDocs
