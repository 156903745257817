import Actions from "../Types";

const initialState = {
  BankMessages: [],
};

const GetMessagesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.GETMESSAGES:
      return {
        ...state,
        BankMessages: payload.reverse(),
      };
    default:
      return state;
  }
};

export default GetMessagesReducer;
