import * as yup from "yup";

export const validationSchema = yup.object({
  salary: yup
  .mixed()
  .test("file-size", "The file cannot exceed 2MB ", function (value) {
    if (value) {
      var filesize = (value?.size / 1024 / 1024).toFixed(4);
      return filesize <= 2;
    } else {
      return true;
    }
  })
});
