import React, { Fragment, useRef, useEffect, useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import * as yup from 'yup'
import { Box, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useStyles } from './ScheduleAppointmentStyle'
import Button from '../../Button/Button'
import LoadingButton from '../../Button/LoadingButton'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CustomSelect from '../../Inputs/Select'
import Alert from '@mui/material/Alert'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { FormikProvider, useFormik, Field, FieldArray } from 'formik'
import { useNavigate } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import { baseUrl } from '../../../baseUrl'
import { useDispatch, useSelector } from 'react-redux'
import CustomFileInput from './CustomFileInput'
import VechiclePictures from './vehiclePictures'
import { hasVehiclePictures } from '../../../Store/context/sendVehicleImages'
import { hasVehicleDocs } from '../../../Store/context/postVehicleDocs'
import LandLoanData from './LandLoanData'
import { hasSecureLandDocs } from '../../../Store/context/postLandDocs'
import { hasCreditCardVerify } from '../../../Store/context/postCreditCard'
import ModalContainer from '../../AtomicDesign/atoms/ModalContainer/ModalContainer'
import ModalCreditCardComponent from './ModalCreditCars'
import Actions from '../../../Store/Types'

const MissingDocsUpload = ({ Files }) => {
  const { hasCreditCard } = useSelector((state) => state.creditCard)
  const classes = useStyles()
  const inputJobLetterImageRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputProofOfAddressImageRef = useRef(null)
  const inputSsnImageImageRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [customerPrevData, setCustomerPrevData] = useState({})
  const [proofSalaryPrevData, setProofSalaryPrevData] = useState({})
  const [loanPurposeId, setLoanPurposeId] = useState({})
  const [arrayFieldAmount, setArrayFieldAmount] = useState(0)
  const [hasJobLetter, setHasJobLetter] = useState(false)
  const filesRef = useRef([])
  const onButtonClick = (index, e) => {
    filesRef.current[index].click()
  }
  const AxiosInterceptor = Interceptor()
  const [schema, setSchema] = useState({})

  const [LoanPayments, setLoanPayments] = useState([])
  const [MortgageOrRent, setMortgageOrRent] = useState([])
  const [CreditCard, setCreditCard] = useState([])
  const [Overdraft, setOverdraft] = useState([])
  const [Other, setOther] = useState([])
  const [bankList, setBankList] = useState({})

  const getDocsMonthlyCreditObligation = async () => {
    await AxiosInterceptor.get('/banks').then((response) => {
      const newBankList = response.data.banks.map((bank) => [
        [bank.id],
        { name: bank.name }
      ])
      setBankList(Object.fromEntries(newBankList))
    }).then(() => dispatch({ type: Actions.LOADING, payload: false }))
    await AxiosInterceptor.get(
      `/monthly-credit-obligations/missing-file/Loan Payments`
    ).then((response) => setLoanPayments(response.data))
    await AxiosInterceptor.get(
      `/monthly-credit-obligations/missing-file/Mortgage or rent`
    ).then((response) => setMortgageOrRent(response.data))
    await AxiosInterceptor.get(
      `/monthly-credit-obligations/missing-file/Credit Card`
    ).then((response) => setCreditCard(response.data))
    await AxiosInterceptor.get(
      `/monthly-credit-obligations/missing-file/Overdraft`
    ).then((response) => setOverdraft(response.data))
    await AxiosInterceptor.get(
      `/monthly-credit-obligations/missing-file/Other`
    ).then((response) => setOther(response.data))
  }

  useEffect(() => {
    if (!Files.monthlyCreditObligations) {
      dispatch({ type: Actions.LOADING, payload: true })
      getDocsMonthlyCreditObligation().then(() => dispatch({ type: Actions.LOADING, payload: false }))
    }
  }, [])

  const validationSchema = yup.object(schema)
  useEffect(() => {
    getUserInformation()
  }, [])

  useEffect(() => {
    let shape = {}
    if (!Files?.ssnImage) {
      shape.ssnImage = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.proofOfAddressImage) {
      shape.proofOfAddressImage = yup.object({
        select: yup.object('').required('**Please Select option'),
        file: yup
          .mixed()
          .required('This is required')
          .test('file-size', 'The file cannot exceed 2MB ', function (value) {
            if (value) {
              var filesize = (value?.size / 1024 / 1024).toFixed(4)
              return filesize <= 2
            } else {
              return true
            }
          })
      })
    }
    if (!Files?.jobLetterImage) {
      shape.jobLetterImage = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.loanPurposeDocs) {
      shape.loanPurposeDocs = yup.array().of(
        yup
          .mixed()
          .required('This is required')
          .test('file-size', 'The file cannot exceed 2MB ', function (value) {
            let isValid = true
            if (value) {
              var filesize = (value?.size / 1024 / 1024).toFixed(4)
              isValid = filesize <= 2
            }
            return isValid
          })
      )
    }

    if (!Files?.districtId) {
      shape.districtId = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.landTitle) {
      shape.landTitle = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.landAppraisal) {
      shape.landAppraisal = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.frontPicture) {
      shape.frontPicture = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.backPicture) {
      shape.backPicture = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.interiorPicture) {
      shape.interiorPicture = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.mileagePicture) {
      shape.mileagePicture = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.purchaseAgreement) {
      shape.purchaseAgreement = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.purchaseAgreement) {
      shape.purchaseAgreement = yup
        .mixed()
        .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (LoanPayments) {
      shape.creditLoanpayment = yup.array().of(
        yup
          .mixed()
          .required('This is required')
          .test('file-size', 'The file cannot exceed 2MB ', function (value) {
            let isValid = true
            if (value) {
              var filesize = (value?.size / 1024 / 1024).toFixed(4)
              isValid = filesize <= 2
            }
            return isValid
          })
      )
    }

    setSchema(shape)
  }, [Files])

  const getUserInformation = async () => {
    await AxiosInterceptor.get('/customers').then((response) => {
      setCustomerPrevData(response.data)
    })
    await AxiosInterceptor.get('/income-proof-salary-users').then(
      (response) => {
        const idJobLetter = response?.date?.idJobLetter
        if (!idJobLetter === 2) setHasJobLetter(true)
        setProofSalaryPrevData(response.data)
      }
    )
    await AxiosInterceptor.get('/loan-purposes').then((response) => {
      setLoanPurposeId(response.data.id)
    })
  }

  const formik = useFormik({
    initialValues: {
      jobLetterImage: null,
      proofOfAddressImage: {
        file: null,
        select: ''
      },
      ssnImage: null,
      loanPurposeDocs: [''],
      frontPicture: null,
      backPicture: null,
      interiorPicture: null,
      mileagePicture: null,
      purchaseAgreementFile: null,
      enginePicture: null,
      sideOnePicture: null,
      sideTwoPicture: null,
      fullViewPicture: null,
      districtId: null,
      cityTownVillage: null,
      registrationSectionId: null,
      landAppraisal: null,
      landTitle: null
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const convertBase64WithFileName = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve({ file: fileReader.result, fileName: file.name })
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const sendData = (status) => {
    dispatch({
      type: 'UPLOADDOCUMENTS',
      payload: status
    })
  }

  useEffect(() => {
    if (formik.isSubmitting) sendData(false)
    // console.log(formik.isSubmitting)
  }, [formik.isSubmitting])

  const [setErrorMessageCredit, setsetErrorMessageCredit] = useState(false)

  const handleSubmit = (values) => {
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        if (response.data?.monthlyCreditObligations) {
          formik.setSubmitting(false)
          sendData(true)
          setsetErrorMessageCredit(false)
          console.log(values)

          uploadFiles(values).then(async function () {
            await changeStatus()
            formik.setSubmitting(false)
            // dispatch(hasCreditCardVerify())
            // if (hasCreditCard) {
            //   await changeStatus()
            //   formik.setSubmitting(false)
            navigate('/Customer/Congratulations', { replace: true })
            // } else {
            //   setIsModalOpen(true)
            // }
          })
        } else {
          formik.setSubmitting(false)
          setsetErrorMessageCredit(true)
          return
        }
      }
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    // console.log(values)
    // return
  }

  const changeStatus = async () => {
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.post('/update-loan-status/customer/send-to-review').finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const uploadFiles = async (values) => {
    let formData = {
      jobLetterImage: await convertBase64(values.jobLetterImage),
      proofOfAddressImage: {
        file: await convertBase64(values.proofOfAddressImage.file),
        select: values.proofOfAddressImage.select.id
      },
      ssnImage: await convertBase64(values.ssnImage),
      loanPurposeDocs: await Promise.all(
        values.loanPurposeDocs.map(async (file) => {
          let data = await convertBase64WithFileName(file)
          return data
        })
      )
    }

    let formVehicleData = {
      frontPictureName: values.frontPicture?.name,
      frontPicture: await convertBase64(values.frontPicture),
      backPictureName: values.backPicture?.name,
      backPicture: await convertBase64(values.backPicture),
      interiorPictureName: values.interiorPicture?.name,
      interiorPicture: await convertBase64(values.interiorPicture),
      mileagePictureName: values.mileagePicture?.name,
      mileagePicture: await convertBase64(values.mileagePicture),
      enginePictureName: values.Engine?.name,
      enginePicture: await convertBase64(values.Engine),
      sideOnePictureName: values.Side_1?.name,
      sideOnePicture: await convertBase64(values.Side_1),
      sideTwoPictureName: values.Side_2?.name,
      sideTwoPicture: await convertBase64(values.Side_2),
      fullViewPictureName: values.Full_View?.name,
      fullViewPicture: await convertBase64(values.Full_View),
      mechanicCertificateFile: await convertBase64(
        values.mechanicCertificateFile
      ),
      mechanicCertificateFileName: values.mechanicCertificateFile?.name,
      purchaseAgreementFile: await convertBase64(values.purchaseAgreement),
      purchaseAgreementFileName: values.purchaseAgreement?.name
    }

    let landDocsData = {
      districtId: await values.districtId,
      urbanAreaId: await values.cityTownVillage,
      registrationSectionId: await values.registrationSectionId,
      landAppraisal: await convertBase64(values.landAppraisal),
      landTitle: await convertBase64(values.landTitle),
      purchaseAgreement: await convertBase64(values.purchaseAgreement),
      street: await values.street,
      parcelOrLotNumber: await values.parcelOrLotNumber
    }

    dispatch({ type: Actions.LOADING, payload: true })
    let data = customerPrevData
    if (!Files?.ssnImage) {
      data = {
        ...data,
        proofOfSsnImg: formData.ssnImage
      }
    }
    if (!Files?.proofOfAddressImage) {
      data = {
        ...data,
        proofOfAddressImg: formData.proofOfAddressImage.file,
        typeOfDocument: formData.proofOfAddressImage.select
      }
    }
    await AxiosInterceptor.put(
      '/schedule-appointment-missing-personal-data',
      data
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    if (!Files?.jobLetterImage) {
      dispatch({ type: Actions.LOADING, payload: true })
      let jobLetterData = {
        ...proofSalaryPrevData,
        jobLetterFile: formData.jobLetterImage
      }
      await AxiosInterceptor.put(
        '/schedule-appointment-missing-job-letter',
        jobLetterData
      ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    }
    if (!Files?.loanPurposeDocs) {
      dispatch({ type: Actions.LOADING, payload: true })
      let data = {
        statementFile1: formData.loanPurposeDocs[0]
          ? formData.loanPurposeDocs[0].file
          : null,
        statementFile2: formData.loanPurposeDocs[1]
          ? formData.loanPurposeDocs[1].file
          : null,
        statementFile3: formData.loanPurposeDocs[2]
          ? formData.loanPurposeDocs[2].file
          : null,
        statementFileName1: formData.loanPurposeDocs[0]
          ? formData.loanPurposeDocs[0].fileName
          : null,
        statementFileName2: formData.loanPurposeDocs[1]
          ? formData.loanPurposeDocs[1].fileName
          : null,
        statementFileName3: formData.loanPurposeDocs[2]
          ? formData.loanPurposeDocs[2].fileName
          : null
      }
      await AxiosInterceptor.patch(`loan-purposes/${loanPurposeId}`, data).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    }

    if (customerPrevData.warrantyTypeId === 2) {
      if (
        !Files?.frontPicture ||
        !Files.backPicture ||
        !Files.interiorPicture ||
        !Files.mileagePicture
      ) {
        dispatch(
          hasVehiclePictures({
            frontPictureName: formVehicleData.frontPictureName,
            frontPicture: formVehicleData.frontPicture,
            backPicture: formVehicleData.backPicture,
            backPictureName: formVehicleData.backPictureName,
            interiorPicture: formVehicleData.interiorPicture,
            interiorPictureName: formVehicleData.interiorPictureName,
            mileagePicture: formVehicleData.mileagePicture,
            mileagePictureName: formVehicleData.mileagePictureName
          })
        )
      }

      if (!Files?.purchaseAgreement) {
        dispatch(
          hasVehicleDocs({
            mechanicCertificateFile: formVehicleData.mechanicCertificateFile,
            mechanicCertificateFileName:
              formVehicleData.mechanicCertificateFileName,
            purchaseAgreementFile: formVehicleData.purchaseAgreementFile,
            purchaseAgreementFileName: formVehicleData.purchaseAgreementFileName
          })
        )
      }
    }

    if (customerPrevData.warrantyTypeId === 3) {
      if (
        !Files?.districtId ||
        !Files?.cityTownVillage ||
        !Files?.registrationSectionId ||
        !Files?.landAppraisal ||
        !Files?.landTitle ||
        !Files?.purchaseAgreement
      ) {
        dispatch(
          hasSecureLandDocs({
            districtId: landDocsData.districtId,
            urbanAreaId: landDocsData.urbanAreaId,
            registrationSectionId: landDocsData.registrationSectionId,
            landAppraisal: landDocsData.landAppraisal,
            landTitle: landDocsData.landTitle,
            purchaseAgreement: landDocsData.purchaseAgreement,
            street: landDocsData.street,
            parcelOrLotNumber: landDocsData.parcelOrLotNumber
          })
        )
      }
    }

    if (customerPrevData.warrantyTypeId === 2) {
      if (
        !Files?.frontPicture ||
        !Files.backPicture ||
        !Files.interiorPicture ||
        !Files.mileagePicture
      ) {
        dispatch(
          hasVehiclePictures({
            frontPictureName: formVehicleData.frontPictureName,
            frontPicture: formVehicleData.frontPicture,
            backPicture: formVehicleData.backPicture,
            backPictureName: formVehicleData.backPictureName,
            interiorPicture: formVehicleData.interiorPicture,
            interiorPictureName: formVehicleData.interiorPictureName,
            mileagePicture: formVehicleData.mileagePicture,
            mileagePictureName: formVehicleData.mileagePictureName
          })
        )
      }

      if (!Files?.purchaseAgreement) {
        dispatch(
          hasVehicleDocs({
            mechanicCertificateFile: formVehicleData.mechanicCertificateFile,
            mechanicCertificateFileName:
              formVehicleData.mechanicCertificateFileName,
            purchaseAgreementFile: formVehicleData.purchaseAgreementFile,
            purchaseAgreementFileName: formVehicleData.purchaseAgreementFileName
          })
        )
      }
    }

    if (customerPrevData.warrantyTypeId === 3) {
      if (
        !Files?.districtId ||
        !Files?.cityTownVillage ||
        !Files?.registrationSectionId ||
        !Files?.landAppraisal ||
        !Files?.landTitle ||
        !Files?.purchaseAgreement
      ) {
        dispatch(
          hasSecureLandDocs({
            districtId: landDocsData.districtId,
            urbanAreaId: landDocsData.urbanAreaId,
            registrationSectionId: landDocsData.registrationSectionId,
            landAppraisal: landDocsData.landAppraisal,
            landTitle: landDocsData.landTitle,
            purchaseAgreement: landDocsData.purchaseAgreement,
            street: landDocsData.street,
            parcelOrLotNumber: landDocsData.parcelOrLotNumber
          })
        )
      }
    }
  }

  const onJobLetterImageRefClick = () => {
    inputJobLetterImageRef.current.click()
    formik.setFieldTouched('jobLetterImage')
  }
  const onProofOfAddressImageRefClick = () => {
    inputProofOfAddressImageRef.current.click()
    formik.setFieldTouched('proofOfAddressImage.file')
  }
  const onSsnImageImageRefClick = () => {
    inputSsnImageImageRef.current.click()
    formik.setFieldTouched('ssnImage')
  }

  return (
    <Box>
      <FormikProvider value={formik}>
        {(customerPrevData.warrantyTypeId === 2 ||
          customerPrevData.warrantyTypeId === 3) && (
          <Box className={classes.AlertVehicleUpload}>
            <Typography>
              Dear {sessionStorage.getItem('name')}, <br />
              If you have any pending documents to upload prior to the final
              submission, please feel free to resume your application as soon as
              you have obtained those documents. Rest assured that all your
              information and uploaded documents up to this point have been
              saved.
            </Typography>
          </Box>
        )}
        <Box className={classes.ScheduleAppointmentContainer}>
          <Typography className={classes.Heading}>
            {customerPrevData.warrantyTypeId === 2 ||
            customerPrevData.warrantyTypeId === 3
              ? 'PLEASE COMPLETE THE FOLLOWING'
              : 'THE FOLLOWING DOCUMENTS ARE REQUIRED. PLEASE UPLOAD THE REQUIRED DOCUMENTS IN ORDER TO PROCEED'}
          </Typography>

          {/* <Typography className={classes.Heading} style={{ color: 'red' }}>
            *Mandatory Upload
          </Typography> */}

          <Stack
            direction='column'
            s
            spacing={{ xs: 1, sm: 2 }}
            width='100%'
            mb={4}
          >
            {!Files?.ssnImage && (
              <>
                <label className={classes.UploadLabel}>
                  Social Security card*
                </label>
                <Button
                  Title='Copy of Social Security card'
                  variant='h7'
                  className={classes.UploadFiles}
                  endIcon={<AttachFileIcon />}
                  onClick={onSsnImageImageRefClick}
                />
                <Field name='ssnImage'>
                  {({ field, form, meta }) => (
                    <>
                      <input
                        {...field}
                        type='File'
                        accept='application/pdf,image/*'
                        onChange={(event) => {
                          form.setFieldValue(
                            'ssnImage',
                            event.currentTarget.files[0]
                          )
                        }}
                        value=''
                        style={{ display: 'none' }}
                        ref={inputSsnImageImageRef}
                        error={meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                        Only PDF and PNG files are permitted with a maximum of
                        2MB
                      </Typography>
                      {meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: 'red' }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                    </>
                  )}
                </Field>
              </>
            )}
          </Stack>

          {!Files?.proofOfAddressImage && (
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              mb={4}
            >
              <>
                <Field name='proofOfAddressImage.select'>
                  {({ field, form, meta }) => (
                    <CustomSelect
                      {...field}
                      Label='PLEASE SELECT ONE OPTION FOR PROOF OF ADDRESS'
                      placeholder='Select one option'
                      ApiUrl={`${baseUrl}/type-of-documents?filter={ "order": "position"}`}
                      error={meta.error}
                      // error={meta.touched && meta.error}
                      value={meta.value}
                      onChange={(e, value) => {
                        value &&
                          form.setFieldValue(
                            'proofOfAddressImage.select',
                            value
                          )
                      }}
                    />
                  )}
                </Field>
                <Stack
                  width={{ xs: '100%', sm: '100%' }}
                  style={{ marginTop: '24px' }}
                  direction='column'
                >
                  <Field name='proofOfAddressImage.file'>
                    {({ field, form, meta }) => (
                      <>
                        <Button
                          Title='Proof of address'
                          variant='h7'
                          className={classes.UploadFiles}
                          endIcon={<AttachFileIcon />}
                          onClick={onProofOfAddressImageRefClick}
                        />
                        <input
                          {...field}
                          type='File'
                          accept='application/pdf,image/*'
                          onChange={(event) => {
                            form.setFieldValue(
                              'proofOfAddressImage.file',
                              event.currentTarget.files[0]
                            )
                          }}
                          value=''
                          style={{ display: 'none' }}
                          ref={inputProofOfAddressImageRef}
                          error={meta.error}
                        />

                        <Typography
                          className={classes.UploadFileText}
                          style={{ marginBottom: '4px' }}
                        >
                          Only PDF and PNG files are permitted with a maximum of
                          2MB
                        </Typography>
                        {meta.error ? (
                          <span
                            className={classes.fileText}
                            style={{ color: 'red' }}
                          >
                            {meta.error}
                          </span>
                        ) : (
                          meta?.value?.name && (
                            <span className={classes.uploadFileName}>
                              {`${String(meta.value.name).substring(
                                0,
                                25
                              )} ...`}
                              <span>
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    formik.setFieldValue(field.name, null)
                                  }}
                                />
                              </span>
                            </span>
                          )
                        )}
                      </>
                    )}
                  </Field>
                </Stack>
              </>
            </Stack>
          )}

          {!Files?.jobLetterImage && (
            <Stack
              direction='column'
              spacing={{ xs: 1, sm: 2 }}
              width='100%'
              mb={4}
            >
              <>
                <label className={classes.UploadLabel}>PROOF OF INCOME*</label>
                <Button
                  Title='Job letter'
                  variant='h7'
                  className={classes.UploadFiles}
                  endIcon={<AttachFileIcon />}
                  onClick={onJobLetterImageRefClick}
                />
                <Field name='jobLetterImage'>
                  {({ field, form, meta }) => (
                    <>
                      <input
                        {...field}
                        type='File'
                        accept='application/pdf,image/*'
                        onChange={(event) => {
                          form.setFieldValue(
                            'jobLetterImage',
                            event.currentTarget.files[0]
                          )
                        }}
                        value=''
                        style={{ display: 'none' }}
                        ref={inputJobLetterImageRef}
                        error={meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                        Only PDF and PNG files are permitted with a maximum of
                        2MB
                      </Typography>
                      {meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: 'red' }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                    </>
                  )}
                </Field>
              </>
            </Stack>
          )}

          {!Files?.loanPurposeDocs && (
            <Stack width='100%' mt={6}>
              <FieldArray
                name='loanPurposeDocs'
                render={(arrayHelpers) => (
                  <Fragment>
                    {formik.values?.loanPurposeDocs.map((field, index) => (
                      <Stack key={index} mb={3}>
                        <Field name={`loanPurposeDocs.${index}`}>
                          {({ field, form, meta }) => {
                            return (
                              <>
                                <label className={classes.UploadLabel}>
                                  Loan Purpose*
                                </label>
                                <Stack
                                  className={classes.Mt16}
                                  direction='row'
                                  width={{ xs: '100%', sm: '100%' }}
                                  alignItems='center'
                                >
                                  <Button
                                    Title='Please upload your supporting documents i.e. quotations, invoices, bank statements'
                                    variant='h7'
                                    className={classes.UploadFiles}
                                    endIcon={<AttachFileIcon />}
                                    onClick={(e) => {
                                      onButtonClick(index, e)
                                      formik.setFieldTouched(field.name)
                                    }}
                                    style={{ width: '100%' }}
                                  />

                                  <input
                                    {...field}
                                    type='file'
                                    accept='application/pdf,image/*'
                                    onChange={(event) => {
                                      if (event.currentTarget.files) {
                                        form.setFieldValue(
                                          field.name,
                                          event.currentTarget.files[0]
                                        )
                                      }
                                      //formik.setFieldTouched(field.name);
                                    }}
                                    value=''
                                    style={{ display: 'none', width: '90%' }}
                                    ref={(el) => (filesRef.current[index] = el)}
                                  />
                                  {index === 0 ? (
                                    <AddCircleIcon
                                      className={classes.PlusIcon}
                                      onClick={() => {
                                        if (arrayFieldAmount < 2) {
                                          arrayHelpers.push('')
                                          setArrayFieldAmount(
                                            (prevData) => prevData + 1
                                          )
                                        }
                                      }}
                                    />
                                  ) : (
                                    <HighlightOffIcon
                                      className={classes.RemoveIcon}
                                      onClick={() => {
                                        arrayHelpers.remove(index)
                                        setArrayFieldAmount(
                                          (prevData) => prevData - 1
                                        )
                                      }}
                                    />
                                  )}
                                </Stack>
                                <Typography className={classes.UploadFileText}>
                                  Only PDF and PNG files are permitted with a
                                  maximum of 2MB
                                </Typography>
                                {meta.error ? (
                                  <p
                                    className={classes.UploadFileText}
                                    style={{ color: 'red', marginTop: 5 }}
                                  >
                                    {meta.error}
                                  </p>
                                ) : (
                                  meta?.value?.name && (
                                    <p className={classes.uploadFileName}>
                                      {`${String(meta.value.name).substring(
                                        0,
                                        25
                                      )} ...`}
                                      <span>
                                        <ClearIcon
                                          className={classes.deleteFileIcon}
                                          onClick={() => {
                                            formik.setFieldValue(field.name, '')
                                          }}
                                        />
                                      </span>
                                    </p>
                                  )
                                )}
                              </>
                            )
                          }}
                        </Field>
                      </Stack>
                    ))}
                  </Fragment>
                )}
              />
            </Stack>
          )}

          {LoanPayments.length > 0 && (
            <Stack width='100%' mt={6}>
              <Typography mb={2}>
                Loan Payments{' '}
                <small style={{ color: '#706F6F' }}>
                  {' '}
                  Upload your loan statement from the financial institution
                  selected. Statements retrieved from online banking are
                  accepted.
                </small>
              </Typography>
              <FieldArray
                // key={}
                name={`creditLoanpayment`}
                render={(arrayHelpers) => (
                  <Fragment>
                    {LoanPayments.map((loanpayment) => (
                      <CustomFileInput
                        formik={formik}
                        key={loanpayment.id}
                        id={loanpayment.id}
                        bankName={bankList[loanpayment.bankId]?.name}
                        amount={loanpayment.amount}
                        customerId={loanpayment.customerId}
                        data={loanpayment}
                        schema={schema}
                        setSchema={setSchema}
                      />
                    ))}
                  </Fragment>
                )}
              />
            </Stack>
          )}
          {MortgageOrRent.length > 0 && (
            <Stack width='100%' mt={6}>
              <Typography mb={2}>
                Mortgage{' '}
                <small style={{ color: '#706F6F' }}>
                  {' '}
                  Upload your loan statement from the financial institution
                  selected. Statements retrieved from online banking are
                  accepted.
                </small>
              </Typography>
              {MortgageOrRent.map((mortgageorrent) => (
                <FieldArray
                  key={mortgageorrent.id}
                  name={`$mortgageorrent${mortgageorrent.id}`}
                  render={(arrayHelpers) => (
                    <CustomFileInput
                      formik={formik}
                      key={mortgageorrent.id}
                      id={mortgageorrent.id}
                      bankName={bankList[mortgageorrent.bankId]?.name}
                      amount={mortgageorrent.amount}
                      customerId={mortgageorrent.customerId}
                      data={mortgageorrent}
                    />
                  )}
                />
              ))}
            </Stack>
          )}
          {CreditCard.length > 0 && (
            <Stack width='100%' mt={6}>
              <Typography mb={2}>
                Credit Card{' '}
                <small style={{ color: '#706F6F' }}>
                  {' '}
                  Upload your loan statement from the financial institution
                  selected. Statements retrieved from online banking are
                  accepted.
                </small>
              </Typography>
              {CreditCard.map((creditcard) => (
                <FieldArray
                  key={creditcard.id}
                  name={`$creditcard${creditcard.id}`}
                  render={(arrayHelpers) => (
                    <CustomFileInput
                      formik={formik}
                      key={creditcard.id}
                      id={creditcard.id}
                      bankName={bankList[creditcard.bankId]?.name}
                      amount={creditcard.amount}
                      customerId={creditcard.customerId}
                      data={creditcard}
                    />
                  )}
                />
              ))}
            </Stack>
          )}
          {Overdraft.length > 0 && (
            <Stack width='100%' mt={6}>
              <Typography mb={2}>
                Overdraft{' '}
                <small style={{ color: '#706F6F' }}>
                  {' '}
                  Upload your loan statement from the financial institution
                  selected. Statements retrieved from online banking are
                  accepted.
                </small>
              </Typography>
              {Overdraft.map((overdraft) => (
                <FieldArray
                  key={overdraft.id}
                  name={`$overdraft${overdraft.id}`}
                  render={(arrayHelpers) => (
                    <CustomFileInput
                      formik={formik}
                      key={overdraft.id}
                      id={overdraft.id}
                      bankName={bankList[overdraft.bankId]?.name}
                      amount={overdraft.amount}
                      customerId={overdraft.customerId}
                      data={overdraft}
                    />
                  )}
                />
              ))}
            </Stack>
          )}
          {Other.length > 0 && (
            <Stack width='100%' mt={6}>
              <Typography mb={2}>
                Other{' '}
                <small style={{ color: '#706F6F' }}>
                  {' '}
                  Upload your loan statement from the financial institution
                  selected. Statements retrieved from online banking are
                  accepted.
                </small>
              </Typography>
              {Other.map((other) => (
                <FieldArray
                  key={other.id}
                  name={`$other${other.id}`}
                  render={(arrayHelpers) => (
                    <CustomFileInput
                      formik={formik}
                      key={other.id}
                      id={other.id}
                      bankName={bankList[other.bankId]?.name}
                      amount={other.amount}
                      customerId={other.customerId}
                      data={other}
                    />
                  )}
                />
              ))}
            </Stack>
          )}

          {(!Files.purchaseAgreement ||
            !Files.frontPicture ||
            !Files.interiorPicture ||
            !Files.mileagePicture ||
            !Files.backPicture) &&
            customerPrevData.warrantyTypeId === 2 && (
              <VechiclePictures
                Files={Files}
                formik={formik}
                customerData={customerPrevData}
              />
            )}

          <Stack
            direction='column'
            s
            spacing={{ xs: 1, sm: 2 }}
            width='100%'
            mb={4}
          >
            {customerPrevData.warrantyTypeId === 3 &&
              (!Files.purchaseAgreement ||
                !Files.districtId ||
                !Files.cityTownVillage ||
                !Files?.landAppraisal ||
                !Files?.landTitle ||
                !Files.registrationSectionId) && (
                <LandLoanData Files={Files} formik={formik} />
              )}
          </Stack>

          {(customerPrevData.warrantyTypeId === 1 ||
            customerPrevData.warrantyTypeId === null) && (
            <Stack>
              <Alert
                icon={false}
                sx={{ backgroundColor: '#D3E4F6' }}
                style={{ padding: 30, marginTop: '40px' }}
              >
                In order to proceed, kindly obtain all the missing documents and
                upload them. If your documents are not readily available, you
                can easily retrieve your application via online banking or the
                BBL mobile app. All your information has been saved. Thank you.
              </Alert>
            </Stack>
          )}
          {/* <Box
            className={classes.SubmitBtn}
            onClick={() => {
              // Verificar si se ha seleccionado un archivo en el campo de archivo
              if (!formik.values.archivo) {
                // Mostrar un mensaje de error o realizar alguna otra acción
                console.log(
                  'Debes seleccionar un archivo antes de enviar el formulario'
                )
              } else {
                // Llamar a formik.submitForm() si se ha seleccionado un archivo
                formik.submitForm()
              }
            }}
            mb={4}
          >
            <LoadingButton Title="SUBMIT" loading={formik.isSubmitting} />
          </Box> */}
          <Box className={classes.SubmitBtn} onClick={formik.submitForm} mb={4}>
            <LoadingButton Title='SUBMIT' loading={formik.isSubmitting} />
          </Box>
          {setErrorMessageCredit && (
            <Alert severity='error'>
              You must upload all documents to continue
            </Alert>
          )}
        </Box>
      </FormikProvider>

      {/* modal credit card */}
      <ModalCreditCardComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        // formik={formik}
        handleOnclick={changeStatus}
      />
    </Box>
  )
}

export default MissingDocsUpload
