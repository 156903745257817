import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  previewBar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  skeletonText: {
    marginLeft: theme.spacing(2),
    width: '80%',
  },
}));

const EmailPreviewLoading = () => {
  const classes = useStyles();

  return (
    <Container>
      {[...Array(5)].map((_, index) => (
        <Card variant="outlined" className={classes.card} key={index}>
          <Box className={classes.previewBar}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton className={classes.skeletonText} />
          </Box>
        </Card>
      ))}
    </Container>
  );
}

export default EmailPreviewLoading;
