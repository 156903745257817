export const LabelList = [
  {
    id: "1",
    label: "MONTHLY LOAN PAYMENT",
  },
  {
    id: "2",
    label: "MONTHLY MORTGAGE",
  },
  {
    id: "3",
    label: "MONTHLY CREDIT CARD PAYMENT",
  },
  {
    id: "4",
    label: "MONTHLY OVERDRAFT PAYMENT",
  },
  {
    id: "5",
    label: "OTHER MONTHLY CREDIT OBLIGATIONS",
  },
  {
    id: "6",
    label: "RENT",
  }
];
