import React, { useEffect, useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import { Box } from '@mui/material'
import ArrowButton from '../../ArrowButton/ArrowButton'
import MissingDocsUpload from './missingDocsUpload'
import MakeAppointment from './MakeAppointment'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import Actions from '../../../Store/Types'
// import SetStatuseRequest from '../../../Store/Actions/setStatusRequest'
import AppointmentPending from './AppointmentPending'
import ModalCreditCardComponent from './ModalCreditCars'
import { FormikProvider, useFormik, Field, FieldArray } from 'formik'
import { Spinner } from '../../BackDrop'
// import ScheduleThankYou from './SheduleThankYou'

const ScheduleAppointment = () => {
  const AxiosInterceptor = Interceptor()
  const [status, setStatus] = useState(null)
  const [Files, setFiles] = useState([])
  const [pending, setPending] = useState(false)
  const dispatch = useDispatch()
  const countPage = useRef(0)
  const navigate = useNavigate()
  const [showScreen, setShowScreen] = useState(false)
  // const [showThanks, setShowThanks] = useState(false)
  const [hasAppointment, setHasAppointmet] = useState(false)
  const [appointmentData, setAppoinmentData] = useState({})
  const [customerData, setCustomerData] = useState({})
  const [showAppointment, setShowAppointment] = useState(false)
  // const { isSubmitForm } = useSelector((state) => state.UploadDocumentsReducer)

  // para el modal de credit card
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  // const formik = useFormik()

  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  const checkStatus = async () => {
    // dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get('/customer/loan-status')
      .then((response) => {
        setStatus(response.data.status)
        const firstName = response?.data?.firstName
        const lastName = response?.data?.lastName
        const warrantyTypeId = response?.data?.warrantyTypeId
        // ! AQUI
        setCustomerData({ firstName, lastName, warrantyTypeId })
      })
      // .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }
  const updateStatus = async () => {
    const endpoint = '/update-loan-status/customer/send-to-review'
    setPending(true)
    // dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.post(endpoint)
      .then((response) => {
        if (response?.data?.result) navigate('/Customer/Congratulations')
      })
      .finally(() => setPending(false))
  }
  const isStatusValid = (arr, status) => {
    return arr.includes(status)
  }
  const getMissingDocs = async () => {
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.get('/schedule-appointment-missing-docs')
      .then((response) => {
        const jobLetterImage = response?.data?.jobLetterImage
        const loanPurposeDocs = response?.data?.loanPurposeDocs
        const proofOfAddressImage = response?.data?.proofOfAddressImage
        const ssnImage = response?.data?.ssnImage
        const statusRequest = response?.data?.status
        const monthlyCreditObligations =
          response?.data?.monthlyCreditObligations
        const frontPicture = response?.data.frontPicture
        const backPicture = response?.data.backPicture
        const interiorPicture = response?.data.interiorPicture
        const mileagePicture = response?.data.mileagePicture
        const purchaseAgreement = response?.data.purchaseAgreement
        const districtId = response?.data.districtId
        const cityTownVillage = response?.data.cityTownVillage
        const registrationSectionId = response?.data.registrationSectionId
        const landTitle = response?.data.landTitle
        const landAppraisal = response?.data.landAppraisal
        if (
          jobLetterImage &&
          loanPurposeDocs &&
          monthlyCreditObligations &&
          proofOfAddressImage &&
          ssnImage &&
          frontPicture &&
          purchaseAgreement &&
          backPicture &&
          interiorPicture &&
          mileagePicture &&
          districtId &&
          cityTownVillage &&
          registrationSectionId &&
          landTitle &&
          landAppraisal &&
          isStatusValid([0], statusRequest)
        ) {
          updateStatus()
          // .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
          // dispatch(SetStatuseRequest())
          // setIsModalOpen(true)
        }

        if (isStatusValid([3], statusRequest))
          navigate('/Customer/ScheduleAppointment/MakeAppointment')
        if (isStatusValid([1], statusRequest)) navigate('/LoanHistory')
        setFiles(response.data)
      })
      .finally(() => {
        setShowScreen(true)
        // dispatch({ type: Actions.LOADING, payload: false })
      })
  }
  const getData = async () => {
    setTimeout(async function () {
      dispatch({ type: Actions.LOADING, payload: true })
      return Promise.all([checkStatus(), getMissingDocs()])
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    }, 3000)
  }

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get('/schedule-appointments/customer')
      .then((response) => {
        const id = response?.data?.id
        const branch = response?.data?.branch
        const appointmentDate = response?.data?.appointmentDate
        const appointmentTime = response?.data?.appointmentTime
        const branchName = response?.data?.branchName
        const customerId = response?.data?.customerId

        if (
          id &&
          branch &&
          appointmentDate &&
          appointmentTime &&
          branchName &&
          customerId
        ) {
          setAppoinmentData({
            branch,
            appointmentDate,
            appointmentTime,
            branchName
          })
          setHasAppointmet(true)
        } else {
          setShowAppointment(true)
        }
      })
      .catch(() => setShowAppointment(true))
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    getData().finally(() => {
      async function fetchData() {
        let response = false
        if (Files.ssnImage && Files.jobLetterImage && Files.loanPurposeDocs) {
          await AxiosInterceptor.post(
            '/update-loan-status/customer/send-to-review'
          )
          response = true
        }
        return response
      }
      // dispatch({ type: Actions.LOADING, payload: true })
      fetchData().then((value) => {
        if (value === true) {
          navigate('/Customer/Congratulations')
        }
      }).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      {' '}
      {pending && (<Spinner open={pending} />)}
      {hasAppointment &&
        status >= 0 &&
        Object.values(appointmentData).length > 0 && (
          <AppointmentPending
            customer={customerData}
            appointment={appointmentData}
          />
        )}
      {showScreen && (
        <>
          {showAppointment && (
            <>
              {status === 3 ? ( //! Cuando el cliente es aprobado status=3 con mas de 7k
                <MakeAppointment />
              ) : !Files?.ssnImage ||
                !Files?.jobLetterImage ||
                !Files.monthlyCreditObligations ||
                !Files?.loanPurposeDocs ||
                (customerData.warrantyTypeId === 2 && !Files?.frontPicture) ||
                (customerData.warrantyTypeId === 2 && !Files?.backPicture) ||
                (customerData.warrantyTypeId === 2 &&
                  !Files?.interiorPicture) ||
                (customerData.warrantyTypeId === 2 && !Files?.mileagePicture) ||
                (customerData.warrantyTypeId === 2 &&
                  !Files?.purchaseAgreement) ||
                (customerData.warrantyTypeId === 3 && !Files?.districtId) ||
                (customerData.warrantyTypeId === 3 &&
                  !Files?.cityTownVillage) ||
                (customerData.warrantyTypeId === 3 &&
                  !Files?.registrationSectionId) ||
                (customerData.warrantyTypeId === 3 && !Files?.landTitle) ||
                (customerData.warrantyTypeId === 3 &&
                  !Files?.purchaseAgreement) ||
                (customerData.warrantyTypeId === 3 && !Files?.landAppraisal) ? (
                <MissingDocsUpload Files={Files} />
              ) : (
                ''
              )}
            </>
          )}
          {status === 3 || hasAppointment ? (
            ''
          ) : (
            // <ArrowButton
            //   link="/Customer/LoanStatus"
            //   back={!isSubmitForm ? `/Customer/ProofOfIncome` : ''}
            // />
            <ArrowButton
              link={
                sessionStorage.getItem('loan') === 'unsecure'
                  ? '/Customer/LoanStatus'
                  : `/Customer/SignDocuments`
              }
              // back="/Customer/ProofOfIncome"
              back={'/Customer/AssetsDeclaration'}
              // link="/Customer/LoanStatus"
              // back={!isSubmitForm ? `/Customer/AssetsDeclaration` : ''}
            />
          )}
          {/* {showThanks && <ScheduleThankYou />} */}
        </>
      )}
      <ModalCreditCardComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        // formik={formik}
        handleOnclick={updateStatus}
      />
    </Box>
  )
}

export default ScheduleAppointment
