import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const EnrollLaterModal = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        "& > :not(style)": {
          width: "49.6rem",
          height: "25.34rem",
          backgroundColor: "lightgray",
          borderRadius: 2,
          justifyContent: "center",
          margin: "10rem",
          filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
          ["@media (max-width: 1000px)"]: {
            width: "100%",
            height: "auto",
          },
          ["@media (max-width: 500px)"]: {
            width: "90%",
            margin: "6rem",
          },
        },
      }}
    >
      <Paper elevation={3}>{props?.children}</Paper>
    </Box>
  );
};

export default EnrollLaterModal;
