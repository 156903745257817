import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasDeclineReasonsIssuedOffer = createAsyncThunk(
  'appDeclineReasonsIssuedOffer/issuedOffer',
  async (data) => {
    const response = await Jwt.getDeclineReasonsIssuedOffer(data)
    return response.data
  }
)

const initialState = {
    data: []
}

export const appDeclineReasonsIssuedOfferSlice = createSlice({
  name: 'appDeclineReasonsIssuedOffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasDeclineReasonsIssuedOffer.fulfilled, (state, action) => {
      state.data = action.payload
    }).addCase(hasDeclineReasonsIssuedOffer.pending, (state, action) => {
      state.pending = false
    })
  }
})


export default appDeclineReasonsIssuedOfferSlice.reducer
