import axios from "axios";
import { baseUrl } from "../baseUrl";
import LocalStorageService from "./LocalStoreServices";

const localStorageService = LocalStorageService.getService();

export const Interceptor = () => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers["Content-Type"] = config.headers
        ? config.headers["Content-Type"]
        : "application/json";
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use((response) => {
    return response;
  });

  return axiosInstance;
};
