import React from "react"
import { Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { useStyles } from "./ProductsAndServicesStyle"
import CardProductsAndServices from "../../Components/Card/CardProductsAndServices"
import { ProductsAndServicesDetails } from "./ProductsServicesDetails"
import { useEffect } from "react"
import { Interceptor } from "../../Utils/Interceptor"
import { useState } from "react"

export const ProductsAndServices = () => {
  const classes = useStyles()
  const [products, setProducts] = useState([])

  const AxiosInterceptor = Interceptor()

  useEffect(() => {
  AxiosInterceptor.get('/products')
  .then((response) => {
    // console.log(response.data)
    setProducts(response.data)
  })
  }, [])
  

  return (
    <Box className={classes.Container}>
      <Box className={classes.SelectOptionWrapper}>
        <Typography className={classes.SelectText}>
          LEARN ABOUT BELIZE BANK'S UNIQUE PRODUCTS AND SERVICES
        </Typography>
      </Box>
      <Box className={classes.CardWrapper}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          flexWrap={{md:"wrap"}}
          spacing={{lg:2}}
          justifyContent={{md:"space-evenly"}}
        >
          {products.map((product) => {
            return (
              <>
              {product.status === 'true' && (
                <CardProductsAndServices
                key={product.id}
                Title={product.title}
                image={product.image}
                contentCard={product.description}
                link={product.link}
                textLink={product?.textLink}
              />
              )}
              </>
            )
          })}
        </Stack>
      </Box>
    </Box>
  )
}
