import React from "react";
import Box from "@mui/material/Box";
import { useStyles } from "./LoanAmountTooltipStyle";
import { Typography } from "@mui/material";

const LoanAmountTooltip = () => {
  const classes = useStyles();
  return (
    <Box className={classes.TooltipContainer}>
      <Box className={classes.ContentContainer}>
        <Typography variant="subtitle1" className={classes.Content}>
          Online loans up to BZ$7,500 are approved within 1 business day if all
          the necessary documents are submitted promptly.{" "}
          <strong className={classes.StorngText}>
            It is easy, quick and secure!
          </strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoanAmountTooltip;
