import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  ProofOfIncomeContainer: {
    width: "100%",
    marginBottom: "186px",
  },

  UploadFiles: {
    "&.MuiButtonBase-root": {
      marginTop: "25px",
      justifyContent: "space-between",
      textTransform: "capitalize",
      height: "43px",
      border: " 1px solid #DADADA",
      color: "#B2B2B2",
      fontSize: "13px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.tertiary,
      "@media (max-width: 600px)": {
        marginTop: "0px",
      },
    },
  },
  UploadFileText: {
    "&.MuiTypography-root": {
      marginTop: "10px",
      color: "#706F6F",
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "18px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginBottom:15
  },
}));
