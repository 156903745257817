import React, { useRef } from 'react'
import { Box, Stack } from '@mui/material'
import { useStyles } from './MakeAppointmentStyle'
// import Button from '../Button/Button'
import Select from '../Inputs/Select'
import DatePicker from '../Inputs/DatePicker'
import TimePicker from '../Inputs/TimePicker'
import { baseUrl } from '../../baseUrl'
import { useState } from 'react'
import { useEffect } from 'react'
import Button from '@mui/material/Button'
import { Interceptor } from '../../Utils/Interceptor'
import toast, { Toaster } from 'react-hot-toast'
import { getMinHours, hoursIntervals, minDate } from '../../Utils/useMinDate'
import moment from 'moment'

const MakeAppointment = ({ data, setShowModal }) => {
  const AxiosInterceptor = Interceptor()
  const classes = useStyles()

  const [valueTime, setValueTime] = useState('')
  const [loading, setLoading] = useState(false)

  const [errorTime, setErrorTime] = useState(false)
  const [errorDate, setErrorDate] = useState(false)

  const [dataSchedule, setDataSchedule] = useState({
    branchId: '',
    date: minDate('14:00:00Z'),
    time: '',
  })

  const countPage = useRef(0)
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime })
  }, [valueTime])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (errorTime || errorDate) return toast.error("Please select a valid date and time.")
    setLoading(true)
    const endpoint = '/schedule-appointments'

    const sentData = {
      branch: dataSchedule.branchId,
      appointmentDate: moment(dataSchedule.date).format(),
      appointmentTime: moment(dataSchedule.time).format(),
      customerId: data.customerId,
      salaryLetter: data.salaryLetter,
    }
    if (!sentData.branch || !sentData.appointmentDate || !sentData.appointmentTime) {
      setLoading(false)
      toast.error('All fields are required, please complete them.')
      return
    }
    // if (!sentData.appointmentDate) return toast.error('Date required')
    // if (!sentData.appointmentTime) return toast.error('Time required')

    AxiosInterceptor.patch(`${endpoint}/${data.id}`, sentData)
      .then((response) => {
        toast.success('Your application was successfully resheduled.')
        setTimeout(() => {
          setShowModal(false)
        }, 2000)
      })
      .catch(() => {
        toast.error('An error occurred, please try again later.')
        // setShowModal(false)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Box>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <Box className={classes.ScheduleAppointmentContainer}>
          <Stack width="100%" mt={6}>
            <Select
              Label="SELECT YOUR PREFERRED BRANCH"
              placeholder="Dangriga Branch"
              style={{ backgroundColor: '#ffff' }}
              ApiUrl={`${baseUrl}/branches`}
              name="branch"
              onChange={(e, val) =>
                setDataSchedule({
                  ...dataSchedule,
                  branchId: val?.id,
                  branchName: val?.name,
                })
              }
              required
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
            mt={6}
          >
            <DatePicker
              Label="SELECT APPOINTMENT DATE"
              openTo="year"
              views={['year', 'month', 'day']}
              style={{ backgroundColor: '#ffff' }}
              disablePast={true}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              onError={(error) => setErrorDate(error)}
              disableFuture={false}
              value={dataSchedule.date}
              minDate={minDate('14:00:00Z')}
              name="appointmentDate"
            />
            <TimePicker
              Label=" SELECT APPOINTMENT TIME"
              placeholder="8:00AM"
              style={{ backgroundColor: '#ffff' }}
              name="appointmentTime"
              appointmentDate={dataSchedule.time}
              minTime={getMinHours(dataSchedule.date, hoursIntervals)}
              onError={(error) => setErrorTime(error)}
              setValueTime={setValueTime}
              minutesStep={60}
              onChange={(e) => setDataSchedule({ ...dataSchedule, time: e })}
            />
          </Stack>
          <Box className={classes.SubmitBtn} mb={4}>
            <Button type="submit" disabled={loading}>
              SUBMIT
            </Button>
            {/* <Button Title="SUBMIT" onClick={handleSubmit} type/> */}
          </Box>
        </Box>
      </form>
      {/* <Box>{showScheduleThankyou ? <SheduleThankYou /> : null}</Box> */}
    </Box>
  )
}
export default MakeAppointment
