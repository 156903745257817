import React, { useState, useEffect } from 'react'
import { useStyles } from './YourLoanRequestStyle'
import { Box, Stack } from '@mui/system'
import LoanDetails from '../../LoanDetails/LoanDetails'
import PersonalOnlineLoan from '../../PersonalOnlineLoan/PersonalOnlineLoan'
import LoanCalculator from '../../LoanCalculator/LoanCalculator'
import ArrowButton from '../../ArrowButton/ArrowButton'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetLoandRequest } from '../../../Store/Actions/GetLoandRequest'
import { useRef } from 'react'
import LocalStorageService from '../../../Utils/LocalStoreServices'
import Actions from '../../../Store/Types'
import Button from '../../Button/Button'
import { loanRequest } from '../../../Store/Actions/LoanRequest'
import { Interceptor } from '../../../Utils/Interceptor'

const YourLoanRequest = () => {
  const navigate = useNavigate()
  const AxiosInterceptor = Interceptor()
  const {
    requestedAmount,
    requestedTerms,
    amount: monto,
    terms: terminos
  } = useSelector((state) => state.LoanRequestReducer)
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer)

  const Location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const Navigate = useNavigate()
  const [amount, setAmount] = useState()
  const [terms, setTerms] = useState()
  const [error, setError] = useState('')
  const [errorTerms, setErrorTerms] = useState('')
  const [nextLink, setNextLink] = useState('#')
  const [showButtonLink, setShowButtonLink] = useState(true)
  const [showPersonal, setShowPersonal] = useState({
    show: false,
    message: ''
  })

  useEffect(() => {
    const formData = {
      requestedAmount: Number(amount),
      requestedTerms: Number(terms),
      // promoCode: promoCodeValue,
      interestRate: 1,
      // debtCancellationSchemeRequested: false
      loanTypeId:
      sessionStorage.getItem('loan') === 'secure'
        ? 2
        : sessionStorage.getItem('loan') === 'refinancing'
        ? 3
        : 1,
    }
    if (amount > 30000 && terms >= 12) {
      dispatch(loanRequest(formData, navigate))
    }

    if (amount >= 1000 && terms > 60) {
      dispatch(loanRequest(formData, navigate))
    }
  }, [terms, amount])

  const countPage = useRef(0)

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  useEffect(() => {
    if (dataSchedule.emailSent ?? false) {
      setShowButtonLink(false)
      LocalStorageService.clearToken()
    }
  }, [dataSchedule])

  useEffect(() => {
    if (requestedAmount > 30_000 || requestedTerms > 60) {
      setShowButtonLink(false)
    } else setShowButtonLink(true)
  }, [requestedAmount, requestedTerms])

  useEffect(() => {
    if (countPage.current === 0 && requestedAmount > 0 && requestedTerms > 60) {
      setNextLink('/Customer/monthlyIncome')
      return
    }
    if (amount > 0 && terms > 0) setNextLink('/Customer/monthlyIncome')
    else setNextLink('#')
  }, [amount, terms])

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch({ type: Actions.CLEARLOANREQUESTSTATE })
    dispatch(GetLoandRequest())
  }, [])
  useEffect(() => {
    setAmount(Number(monto))
  }, [monto])
  useEffect(() => {
    setTerms(Number(terminos))
  }, [terminos])
  return (
    <Box>
      {Location.pathname === '/Customer/yourLoanRequest/TermsAndConditions' ? (
        <Outlet />
      ) : (
        <Box>
          <Box className={classes.LoanContainer}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              width='100%'
            >
              <LoanDetails
                setAmount={setAmount}
                amount={Number(amount)}
                terms={Number(terms)}
                setTerms={setTerms}
                error={error}
                errorTerms={errorTerms}
              />
            </Stack>
            {amount || terms ? (
              <>
                {Number(amount) > 30000 && Number(terms) > 60 && (
                  <PersonalOnlineLoan
                    message={'$30,000 and more than 60 months'}
                    amount={amount}
                    terms={terms}
                  />
                )}

                {Number(amount) > 30000 && Number(terms) <= 60 && (
                  <PersonalOnlineLoan
                    message={'$30,000'}
                    amount={amount}
                    terms={terms}
                  />
                )}

                {Number(amount) <= 30000 && Number(terms) > 60 && (
                  <PersonalOnlineLoan
                    message={'60 months'}
                    amount={amount}
                    terms={terms}
                  />
                )}
                {Number(amount) <= 30000 && Number(terms) <= 60 && (
                  <LoanCalculator
                    amount={Number(amount)}
                    terms={Number(terms)}
                    setError={setError}
                    setErrorTerms={setErrorTerms}
                  />
                )}
              </>
            ) : (
              <LoanCalculator
                amount={Number(amount)}
                terms={Number(terms)}
                setError={setError}
                setErrorTerms={setErrorTerms}
              />
            )}
          </Box>

          {showButtonLink && (
            <ArrowButton
              onClick={() => {
                AxiosInterceptor.post('/customers/step', { currentStep: 3 })
                  .then((response) => {
                    console.log(response)
                  })
                  .catch((e) => console.log(e))
              }}
              link='/Customer/monthlyIncome'
            />
            //<ArrowButton link={nextLink} back="/Customer/letsMeet" />
          )}
        </Box>
      )}
    </Box>
  )
}

export default YourLoanRequest
