import React from 'react'
import ModalContainer from '../../AtomicDesign/atoms/ModalContainer/ModalContainer'
import { Typography } from '@mui/material'
import Button from '../../Button/Button'
import { useStyles } from './ScheduleAppointmentStyle'
import CreditCardImage from '../../../assets/Images/cc-loans.png'
import { useNavigate } from 'react-router-dom'

const ModalCreditCardComponent = ({
  isModalOpen,
  setIsModalOpen,
  showMessage,
  setShowMessage,
  formik,
  handleOnclick
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <ModalContainer
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      width={'auto'}
      height={'auto'}
    >
      <div className={classes.Modal}>
        {!showMessage ? (
          <>
            <img src={CreditCardImage} alt='' />
            <Typography>Do you wish to apply for a Credit Card?</Typography>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%'
              }}
            >
              <Button
                Title='YES, OF COURSE'
                variant='subtitle1'
                className={classes.Button}
                BtnVariant='contained'
                // onClick={() => dispatch(hasDeclinedIssuedOffer({ decline: true }))}
              />
              <Button
                Title='NOT AT THE MOMENT'
                variant='subtitle1'
                className={classes.LightButton}
                BtnVariant='contained'
                onClick={() => setShowMessage(true)}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              width: '100%'
            }}
          >
            <Typography>
              Customers can easily and securely apply for a Credit Card{' '}
             at any point in time via our digital banking platforms.{' '}
             Feel free to submit your application online.
            </Typography>
            <Button
              Title='FINISH'
              variant='subtitle1'
              className={classes.Button}
              BtnVariant='contained'
              onClick={() => {
                // changeStatus()
                handleOnclick()
                // formik.setSubmitting(false)
                navigate('/Customer/Congratulations', { replace: true })
                setIsModalOpen(false)
              }}
            />
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

export default ModalCreditCardComponent
