import React, { useEffect, useState } from "react"
import { useStyles } from "./PersonalOnlineLoanStyle"
import { Box, Typography } from "@material-ui/core"
import Select from "../Inputs/Select"
import DatePicker from "../Inputs/DatePicker"
import TimePicker from "../Inputs/TimePicker"
import Button from "../Button/Button"
import { Stack } from "@mui/system"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ScheduleRequest } from "../../Store/Actions/ScheduleRequest"
import Alert from "@mui/material/Alert"
import ScheduleThankYou from "./SheduleThankYou"
import moment from "moment"
import { baseUrl } from "../../baseUrl"
import { minTime, minDate, getMinHours } from "../../Utils/useMinDate"
import { toast, Toaster } from "react-hot-toast"

const loadTime = (today) => {
  const newTime = minTime(moment(today).format('YYYY-MM-DDTHH:mm:ss'))
  if (newTime !== null) {
    return newTime
  }
}

const intervalHours = [
    `${moment().format('YYYY-MM-DD')}T08:00:00`,
    `${moment().format('YYYY-MM-DD')}T09:00:00`,
    `${moment().format('YYYY-MM-DD')}T10:00:00`,
    `${moment().format('YYYY-MM-DD')}T11:00:00`,
    `${moment().format('YYYY-MM-DD')}T12:00:00`,
    `${moment().format('YYYY-MM-DD')}T13:00:00`,
    `${moment().format('YYYY-MM-DD')}T14:00:00`,
]

const PersonalOnlineLoan = ({ message, amount, terms}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [showScheduleThankyou, setShowScheduleThankyou] = useState(false)
  const [valueTime, setValueTime] = useState(loadTime(moment().format()))
  const [errorTime, setErrorTime] = useState(false)
  const [errorDate, setErrorDate] = useState(false)

  const [dataSchedule, setDataSchedule] = useState({
    branchId: "",
    date: minDate('14:00:00Z'),
    time: '',
  })

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime })
  }, [valueTime])


  const handleSubmit = () => {

    console.log(amount, terms)

    if (!(Number(amount) >= 1000)) return toast.error('Please select an amount greater than or equal to 1000')
    if (!(Number(terms) >= 12)) return toast.error('Please select a term greater than or equal to 12')
    if (errorTime || errorDate) return toast.error("Please select a valid date and time.")

    if (dataSchedule.branchId === "" || dataSchedule.date === "") {
      return toast.error("Please complete all the required fields.")
    }
    const data = {
      branch: dataSchedule.branchId,
      appointmentDate: moment(dataSchedule.date).format(),
      appointmentTime: moment(dataSchedule.time).format()
    }
    const responde = dispatch(ScheduleRequest({ ...data }))
    responde.then(setShowScheduleThankyou(true))
  }

  return (
    <Box mt={4}>
      <Toaster />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.OnlineLoanContainer}
      >
        <Box className={classes.OnlineLoanText}>
          <Typography>
            Personal online loans higher than {message} will require in-branch
            verification and approval. Please feel free to schedule your
            appointment NOW.
          </Typography>
        </Box>
        <Stack
          direction="column"
          spacing={3}
          mt={4}
          className={classes.OnlineLoanInput}
        >
          <Select
            Label="SELECT YOUR PREFERRED BRANCH"
            placeholder="Dangriga Branch"
            style={{ backgroundColor: "#ffff" }}
            ApiUrl={`${baseUrl}/branches`}
            name="branchId"
            required
            onChange={(e, value) =>
              setDataSchedule({ ...dataSchedule, branchId: value.id })
            }
          />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <DatePicker
              Label="SELECT APPOINTMENT DATE"
              openTo="year"
              views={["year", "month", "day"]}
              style={{ backgroundColor: "#ffff" }}
              disablePast={true}
              minDate={minDate('14:00:00Z')}
              onError={(error) => setErrorDate(error)}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              disableFuture={false}
              value={dataSchedule.date}
              name="appointmentDate"
            />
            <TimePicker
              Label=" SELECT APPOINTMENT TIME"
              placeholder="8:00AM"
              style={{ backgroundColor: "#ffff" }}
              name="appointmentTime"
              minutesStep={30}
              appointmentDate={dataSchedule.time}
              minTime={getMinHours(dataSchedule.date, intervalHours)}
              onError={(error) => setErrorTime(error)}
              onChange={(e) => {
                setValueTime(e)
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      {!showScheduleThankyou ? (
        <Box className={classes.Btn}>
          <Link
            // to="/NonCustomerPage/ScheduleAppointment"
            className={classes.Links}
          >
            <Button
              className={classes.ScheduleBtn}
              Title="SUBMIT"
              BtnVariant="contained"
              style={{backgroundColor:"#275DA6"}}
              onClick={handleSubmit}
            />
          </Link>
        </Box>
      ) : null}
      {showScheduleThankyou ?
        <Box>
          <Alert icon={false} sx={{ backgroundColor: "#D3E4F6" }} style={{ padding: 30, marginTop: '40px' }}>
            <strong className={classes.title}>To ensure a smooth processing of your loan request, please bring along the following documents :</strong> <br />
            <ul>
              <li className={classes.li}>Social Security Card</li>
              <li className={classes.li}> Proof of Income <span className={classes.span}>(Utility bill, Driver's license, Property Tax
                Documents,  Employment letter, or Other Bank Statement)</span></li>
              <li className={classes.li}> Employment letter <span className={classes.span}>(issued within the last 6 months)</span></li>
              <li className={classes.li}> Two recent pay slips</li>
              <li className={classes.li}> Tax return <span className={classes.span}>(for self-employed)</span></li>
            </ul>
          </Alert>
          <Box>
            <ScheduleThankYou />
          </Box>
        </Box> : null}
    </Box>

  )
}

export default PersonalOnlineLoan
