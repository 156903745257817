import { makeStyles } from "@material-ui/core/styles";
import SmilingBg from "../../../assets/Images/SmilingBg.png";

export const useStyles = makeStyles((theme) => ({
  LoanStatusContainer: {
    width: "100%",
  },
  WelcomeMessageContainer: {
    width: "100%",
    height: "144.15px",
    borderRadius: "10px",
    paddingLeft: "",
    display: "flex",
    alignItems: "center",
    backgroundImage: "url(" + SmilingBg + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  SmilingImageContainer: {
    width: "110px",
    height: "110px",
    marginLeft: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#ffff",
  },
  SmilingImage: {
    width: "108px",
    height: "108px",
    filter: " drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  WelcomeMessage: {
    "&.MuiTypography-root": {
      marginLeft: "42.35px",
      fontSize: "32px",
      fontWeight: "800",
      color: "#575756",
      lineHeight: "44px",
      fontFamily: theme.fonts.family.secondary,
      ["@media (max-width: 600px)"]: {
        marginLeft: "20px",
      },
    },
  },
  Details: {
    width: "303px",
    height: "96px",
    marginTop: "59px",
    marginBottom: "20px",
    "& .MuiTypography-root": {
      fontSize: "23.37px",
      lineHeight: "32px",
      fontWeight: "500",
      color: "#575756",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  LoanStatusSection: {
    width: "100%",
  },
  LoanStatusSectionHeader: {
    height: "62px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EAF2FB",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "37px",
    letterSpacing: "0.1em",
    color: "#005FAA",
    fontFamily: theme.fonts.family.primary,
  },
}));
