import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  ScheduleAppointmentContainer: {
    width: "100%",
  },
  ApprovedPoppup: {
    backgroundColor: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width: 900px)": {
      padding: "0 20px",
    },
  },
  UploadFileText: {
    "&.MuiTypography-root": {
      marginTop: "5px",
      color: "#706F6F",
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "18px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    width:500,
    marginBottom:15
  },
  fileText: {
    width: "460px",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "17.75px",
    fontFamily: theme.fonts.family.secondary,
    color: "#706F6F",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  ApprovedText: {
    "&.MuiTypography-body1": {
      width: "715px",
      marginBottom: "25.11px",
      color: "#706F6F",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "120%",
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      "@media (max-width: 900px)": {
        width: "100%",
      },
    },
  },
  ApprovedPoppupImage: {
    width: "111.14px",
    height: "111.14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffff",
    borderRadius: "50%",
    marginBottom: "17px",
    marginTop: "25.66px",
  },
  ApprovedImage: {
    width: "90px",
    height: "90px",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  UploadFiles: {
    "&.MuiButtonBase-root": {
      width: "100%",
      justifyContent: "space-between",
      height: "43px",
      border: " 1px solid #DADADA",
      color: "#B2B2B2",
      fontSize: "13px",
      paddingLeft: "30px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  SubmitBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
    "& .MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  ThankYouPoppup: {
    width: "808px",
    margin: "66px auto",
    backgroundColor: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 900px)": {
      width: "auto",
      padding: "0px 20px",
    },
  },
  ThankYouText: {
    "&.MuiTypography-body1": {
      width: "513px",
      marginBottom: "25.11px",
      color: "#706F6F",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "120%",
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      "@media (max-width: 800px)": {
        width: "100%",
      },
    },
  },
  ThankYouBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "36px",
    marginTop: "20px",
    "& .MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  Link: {
    textDecoration: "none",
  },
  AlertBoxContainer: {
    "& .MuiDialog-paper": {
      width: "1000px",
      borderRadius: "10px",
      marginTop: "100px",
      marginRight: "48px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      backgroundColor: "#F4EBFE",
      
      maxWidth: '100% !important'
    },
    
  },
}))
