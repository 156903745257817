import Actions from "../Types";

const initialState = {
  isSubmitForm: false,
};

const UploadDocumentsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.UPLOADDOCUMENTS:
      return {
        ...state,
        isSubmitForm: payload,
      };
    default:
      return state;
  }
};

export default UploadDocumentsReducer;
