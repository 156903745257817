import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  AlertVehicleUpload: {
    marginBottom: '5%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    background: theme.colors.primary.lightPink,
    borderRadius: '0.3125rem',
    fontFamily: theme.fonts.family.primary,
    color: theme.colors.primary.Grey,
    padding: '1.5rem'
  },
  LoanContainer: {
    // width: "850.4px",
    margin: "0 auto 70px auto",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
  },
  ProceedContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  Button: {
    width: 176,
    height: 39,
    "&.MuiButtonBase-root": {
      marginBottom: 30,
      backgroundColor: theme.colors.primary.Darkblue,
      color: 'white',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
  },

  continueButton: {
    width: 176,
    height: 39,
    "&.MuiButtonBase-root": {
      marginBottom: 30,
      backgroundColor: theme.colors.primary.Darkblue,
      color: 'white',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        // fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      "&.Mui-disabled": {
        backgroundColor: '#9E9E9E'
      }
    },
    ".makeStyles-Button-18.MuiButtonBase-root": {
      backgroundColor: '#9E9E9E'
    }
  }
}));
