import { Typography } from '@material-ui/core'

const Title = ({Text, color, margin, fontSize = '14px', fontWeight = '700', marginBottom = '0px', aling= 'center'}) => {
     return (
        <Typography 
        style={
         {color: color, 
         fontSize: fontSize, 
         fontWeight: fontWeight, 
         textAlign: aling, 
         marginTop: margin, 
         marginBottom: marginBottom
         }}>{Text}</Typography>
     )
   }
   
   export default Title
   