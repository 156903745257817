import React from "react";
import { Box } from "@mui/system";
import Error from "../../assets/Images/Error.png";
import { useStyles } from "./ErrorContainerStyle";

const ErrorContainer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.ErrorContainer}>
      <img className={classes.ErrorImage} src={Error} alert="" />
    </Box>
  );
};

export default ErrorContainer;
