import { Box, Stack } from '@mui/system'
import React from 'react'
import Button from '../Button/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useStyles } from './ArrowButtonStyle'
import { Link, useLocation } from 'react-router-dom'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import clsx from 'clsx'
import { Interceptor } from '../../Utils/Interceptor'
import { useState } from 'react'
import { useEffect } from 'react'
import { toast, Toaster } from 'react-hot-toast'

const ArrowButton = ({
  link,
  back,
  onClick,
  onClickBackBtn,
  disabled = false,
  msg = '',
  showBtnNext = true,
}) => {
  const classes = useStyles()
  const Location = useLocation()
  const AxiosInterceptor = Interceptor()
  const [countNotifications, setCount] = useState(0)

  // console.log(disabled)
  const errorMsg = () => toast.error(msg)
  const getNotifications = () => {
    AxiosInterceptor.get('/customer/notifications')
      .then((response) => {
        const notification = response.data.notifications || 0
        sessionStorage.setItem('custNtf', notification)
        setCount(notification)
      })
      .catch(() => {
        sessionStorage.setItem('custNtf', 0)
        setCount(0)
      })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <Stack
      direction={
        Location.pathname === '/Customer/LoanStatus' ? 'row-reverse' : 'column'
      }
      justifyContent={
        Location.pathname === '/Customer/LoanStatus'
          ? 'space-between'
          : 'column'
      }
      alignItems="end"
      mt={10}
    >
      <Toaster />
      {Location.pathname === '/Customer/letsMeet/opt' ||
      Location.pathname === '/Customer/ProofOfIncome' ||
      Location.pathname === '/Customer/LoanPurpose' ||
      Location.pathname === '/Customer/MonthlyCreditObligations' ||
      Location.pathname === '/Customer/monthlyIncome' ||
      Location.pathname === '/Customer/yourLoanRequest' ||
      Location.pathname === '/Customer/ScheduleAppointment' ||
      // Location.pathname === '/Customer/SignDocuments' ||
      Location.pathname === '/Customer/MonthlyInstallments' ||
      Location.pathname === '/Customer/vehicle-status' ||
      Location.pathname === '/Customer/loans-to-be-refinanced' ||
      Location.pathname === '/Customer/refinance-request' ||
      Location.pathname === '/Customer/loan-calculation' ||
      Location.pathname === '/Customer/land-details' ||
      Location.pathname === '/Customer/AssetsDeclaration' ||
      Location.pathname === '/Customer/SignDocuments' ? (
        ''
      ) : (
        <>
          <Stack
            className={clsx`(${
              Location.pathname === '/Customer/LoanStatus'
                ? classes.MsgIconTwo
                : classes.MsgIcon
            }, classes.main)`}
            alignSelf="end"
          >
            <Link to="/CustomerChat">
              {countNotifications > 0 && (
                <p
                  style={{
                    backgroundColor: 'green',
                    borderRadius: '50%',
                    color: '#fff',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'end',
                    fontWeight: 400,
                    fontSize: '20px',
                    textAnchor: 'none',
                  }}
                >
                  {countNotifications}
                </p>
              )}
              <Box className={clsx(classes.TriangleLeft, classes.main)}></Box>
              {countNotifications === 0 && <EmailOutlinedIcon />}
            </Link>
          </Stack>
        </>
      )}
      {Location.pathname === '/Loan-Status' ? (
        ''
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent={
              Location.pathname === '/Customer/yourLoanRequest'
                ? 'flex-end'
                : 'space-between'
            }
            alignItems="center"
            spacing={1}
            mb={3}
            width="100%"
          >
            {Location.pathname === '/Customer/yourLoanRequest' ||
            Location.pathname === '/LoanHistory' ? (
              ''
            ) : (
              <Link to={back} className={classes.Links} replace>
                <Button
                onClick={onClickBackBtn}
                  className={classes.BackButton}
                  startIcon={
                    <ArrowBackIosNewIcon className={classes.BackArrow} />
                  }
                  Title="Back"
                />
              </Link>
            )}

            {Location.pathname === '/Customer/LoanStatus' ||
            Location.pathname === '/Customer/ScheduleAppointment' ||
            Location.pathname === '/LoanHistory' ? (
              ''
            ) : (
              <>
                {showBtnNext && (
                  <Link
                    to={disabled ? '#' : link}
                    className={classes.Links}
                    onClick={disabled ? errorMsg : onClick}
                    replace
                  >
                    <Button
                      disabled={disabled}
                      className={classes.NextButton}
                      endIcon={
                        <ArrowForwardIosIcon className={classes.NextArrow} />
                      }
                      Title="Next"
                    />
                  </Link>
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default ArrowButton
