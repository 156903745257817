import React from 'react'
import { Alert } from '@mui/material'
import { Check, Warning } from '@mui/icons-material'
// import { useStyles } from './LoanHistoryStyles'

const AlertInfo = ({ content, style = {marginBottom: 22}, severity = 'warning'  }) => {
  return (
    <Alert
      icon={ severity === 'warning' ? <Warning fontSize="medium"  style={{ fill: 'coral' }} /> : <Check fontSize="medium"/>}
      severity={severity}
      style={{ ...style }}
    >
      {content}
    </Alert>
  )
}

export default AlertInfo
