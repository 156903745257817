import React, { useRef } from "react"
import { Box, Stack } from "@mui/material"
import ArrowButton from "../../ArrowButton/ArrowButton"
import { useStyles } from "./SignDocumentStyle"
import Calendar from "../../../assets/Images/Grupo 1001.svg"
import { Typography } from "@material-ui/core"
import Button from "../../Button/Button"
import clsx from "clsx"
import { Link } from "react-router-dom"
import LocalStorageShedule from "../../../Utils/LocalStorageShedule"
import ModalRefFriend from "./ModalRefFriend"
import { useState } from "react"

const ScheduleThankYou = () => {
  const customer_schedule = JSON.parse(LocalStorageShedule.getShedule())
  // const sheduleData = JSON.parse(customer_schedule)
  const [openModal, setOpen] = useState(false)
  const classes = useStyles()
  const inputFile = useRef(null)
  const firstName = customer_schedule?.firstName
  const lastName = customer_schedule?.lastName
  const branch = customer_schedule?.branch
  const appointmentTime = customer_schedule?.appointmentTime

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Box>
      <ModalRefFriend open={openModal} setOpen={setOpen} link={`/?q=${sessionStorage.getItem('code')}`}/>
      
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouText}>
            <strong>Dear {`${firstName} ${lastName}`}</strong> <br />
            Your appointment has been successfully scheduled. Please visit
            {` ${branch}`} branch on {new Date(appointmentTime).toLocaleString("es-es",{ hour12: true })} Thank you for
            giving us the opportunity to serve you.
          </Typography>
          <Box className={classes.ThankYouBtn}>
            {/* <Link to="/" className={classes.Link}> */}
              <Button Title="THANK YOU" onClick={handleOpen} />
            {/* </Link> */}
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default ScheduleThankYou
