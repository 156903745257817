import React, { useEffect, useState } from 'react'
import { Grid, FormLabel, Button, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { Box } from '@mui/system'
import { Input } from '../components'
import Spinner from '../../Pages/components/custom-spinner'
import { Interceptor } from '../../Utils/Interceptor'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { hasVehiclePictures } from '../../Store/context/sendVehicleImages'
import { getVehicleDocs, hasVehicleDocs } from '../../Store/context/postVehicleDocs'
// styles
import { useStyles } from './styles'
// redux
import { useSelector, useDispatch } from 'react-redux'
import { hasVehicleBrand } from '../../Store/context/getVehicleBrands'
import { hasVehicleDetails } from '../../Store/context/postVehicleDetails'
import { hasDistrictData } from '../../Store/context/getDistrict'
import ArrowButton from '../../Components/ArrowButton/ArrowButton'
import VechiclePictures from '../../Components/Forms/ScheduleAppointment/vehiclePictures'
import Currency from '../../Components/Inputs/currency2'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material'
import InfoIcon from '../../assets/Images/icon-blue.png'
import Actions from '../../Store/Types'

const vehicleStatusList = ['New', 'Used']

const VehicleStatus = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const AxiosInterceptor = Interceptor()
  const { brands } = useSelector((state) => state.vehicleBrand)
  const {
    status,
    vehicleBrandId,
    vehicleModel,
    vehicleValue,
    productionYear,
    mileage,
    pending,
    name,
    districtId,
    address
  } = useSelector((state) => state.vehicleDetails)

  const [showTooltip, setShowTooltip] = useState(false)

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#0F5CAC',
      fontSize: 14,
      maxWidth: 261,
      filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
      padding: '1rem'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      '&::before': {
        backgroundColor: 'white'
      }
    }
  }))

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const { backPictureName, frontPictureName, interiorPictureName, mileagePictureName } = useSelector((state) => state.vehiclePictures)

  const { data } = useSelector((state) => state.districtData)

  const [productionYears, setProductionYears] = useState([])
  const [selectedVehicleBrand, setSelectedVehicleBrand] = useState(null)
  const [selectedVehicleStatus, setSelectedVehicleStatus] = useState(null)
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null)
  const [selectedVehicleValue, setSelectedVehicleValue] = useState(null)
  const [selectedVehicleYear, setSelectedVehicleYear] = useState(null)
  const [selectedVehicleMilage, setSelectedVehicleMilage] = useState(null)
  const [sellersName, setSellersName] = useState('')
  const [sellersAddress, setSellersAddress] = useState('')
  const [sellersDistrict, setsellersDistrict] = useState(null)
  const [customerPrevData, setCustomerPrevData] = useState('')
  const [Files, setFiles] = useState([])
  const [schema, setSchema] = useState({})

  const validationSchema = yup.object(schema)
  const { mechanicCertificateFile, mechanicCertificateFileName, purchaseAgreementFile, purchaseAgreementFileName } = useSelector((state) => state.postVehicleDocs)

  const formik = useFormik({
    initialValues: {
      frontPicture: null,
      backPicture: null,
      interiorPicture: null,
      mileagePicture: null,
      purchaseAgreementFile: null,
      purchaseAgreementFileName: '',
      mechanicCertificateFile: null,
      mechanicCertificateFileName: '',
      enginePicture: null,
      sideOnePicture: null,
      sideTwoPicture: null,
      fullViewPicture: null
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  useEffect(() => {
    formik.setFieldValue('purchaseAgreementFile', purchaseAgreementFile)
    formik.setFieldValue('mechanicCertificateFile', mechanicCertificateFile)
    formik.setFieldValue('purchaseAgreementFileName', purchaseAgreementFileName)
    formik.setFieldValue('mechanicCertificateFileName', mechanicCertificateFileName)
  }, [
      purchaseAgreementFile,
      mechanicCertificateFile,
      purchaseAgreementFileName,
      mechanicCertificateFileName
    ])

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  function isBase64(str) {
    try {
      return str.split(',')[0].split(';')[1] === 'base64'
    } catch (e) {
        return false;
    }
}



const uploadFiles = async (values) => {
    console.log("Es el archivo mechanique un base64?", isBase64(values.mechanicCertificateFile)); // true
    // console.log("VALUES", values)
    let formVehicleData = {
      frontPictureName: values.frontPicture?.name,
      frontPicture: await convertBase64(values.frontPicture),
      backPictureName: values.backPicture?.name,
      backPicture: await convertBase64(values.backPicture),
      interiorPictureName: values.interiorPicture?.name,
      interiorPicture: await convertBase64(values.interiorPicture),
      mileagePictureName: values.mileagePicture?.name,
      mileagePicture: await convertBase64(values.mileagePicture),
      enginePictureName: values.Engine?.name,
      enginePicture: await convertBase64(values.Engine),
      sideOnePictureName: values.Side_1?.name,
      sideOnePicture: await convertBase64(values.Side_1),
      sideTwoPictureName: values.Side_2?.name,
      sideTwoPicture: await convertBase64(values.Side_2),
      fullViewPictureName: values.Full_View?.name,
      fullViewPicture: await convertBase64(values.Full_View),
      mechanicCertificateFile: isBase64(values.mechanicCertificateFile) ? values.mechanicCertificateFile : await convertBase64(
        values.mechanicCertificateFile
      ),
      mechanicCertificateFileName: values.mechanicCertificateFile?.name,
      purchaseAgreementFile: isBase64(values.purchaseAgreementFile) ? values.purchaseAgreementFile : await convertBase64(values.purchaseAgreement),
      purchaseAgreementFileName: values.purchaseAgreement?.name
    }

    if (customerPrevData.warrantyTypeId === 2) {
        dispatch(
          hasVehiclePictures({
            frontPictureName: formVehicleData.frontPictureName,
            frontPicture: formVehicleData.frontPicture.length
              ? formVehicleData.frontPicture
              : null,
            backPicture: formVehicleData.backPicture.length
              ? formVehicleData.backPicture
              : null,
            backPictureName: formVehicleData.backPictureName,
            interiorPicture: formVehicleData.interiorPicture.length
              ? formVehicleData.interiorPicture
              : null,
            interiorPictureName: formVehicleData.interiorPictureName,
            mileagePicture: formVehicleData.mileagePicture.length
              ? formVehicleData.mileagePicture
              : null,
            mileagePictureName: formVehicleData.mileagePictureName,
            enginePictureName: formVehicleData.enginePictureName,
            enginePicture: formVehicleData.enginePicture.length
            ? formVehicleData.enginePicture
            : null,
            sideOnePictureName: 'side-one-picture',
            sideOnePicture: formVehicleData.sideOnePicture.length
            ? formVehicleData.sideOnePicture
            : null,
            sideTwoPictureName: 'side-two-picture',
            sideTwoPicture: formVehicleData.sideTwoPicture.length
            ? formVehicleData.sideTwoPicture
            : null,
            fullViewPictureName: 'full-view-picture',
            fullViewPicture: formVehicleData.fullViewPicture.length
            ? formVehicleData.fullViewPicture
            : null,
          })
        )
      

   
        dispatch(
          hasVehicleDocs({
            mechanicCertificateFile: formVehicleData.mechanicCertificateFile
              .length
              ? formVehicleData.mechanicCertificateFile
              : null,
            mechanicCertificateFileName:
              formVehicleData.mechanicCertificateFileName,
            purchaseAgreementFile: formVehicleData.purchaseAgreementFile.length
              ? formVehicleData.purchaseAgreementFile
              : null,
            purchaseAgreementFileName: formVehicleData.purchaseAgreementFileName
          })
        )
      
    }
  }

  const handleSubmit = (values) => {
    dispatch({ type: Actions.LOADING, payload: true })
    AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        // if (response.data?.monthlyCreditObligations) {
          // formik.setSubmitting(false)

          uploadFiles(values)
        // } else {
        //   formik.setSubmitting(false)
        //   return
        // }
      }
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    // console.log(values)
    // return
  }

  useEffect(() => {
    let shape = {}

    if (!Files?.frontPicture) {
      shape.frontPicture = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.backPicture) {
      shape.backPicture = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.interiorPicture) {
      shape.interiorPicture = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }
    if (!Files?.mileagePicture) {
      shape.mileagePicture = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    if (!Files?.purchaseAgreement) {
      shape.purchaseAgreement = yup
        .mixed()
        // .required('This is required')
        .test('file-size', 'The file cannot exceed 2MB ', function (value) {
          if (value) {
            var filesize = (value?.size / 1024 / 1024).toFixed(4)
            return filesize <= 2
          } else {
            return true
          }
        })
    }

    setSchema(shape)
  }, [Files])

  const getUserInformation = async () => {
    await AxiosInterceptor.get('/customers').then((response) => {
      setCustomerPrevData(response.data)
    })
  }

  const getMissingDocs = async () => {
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        setFiles(response.data)
      }
    ).finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const getData = async () => {
    setTimeout(async function () {
      dispatch({ type: Actions.LOADING, payload: true })
      return Promise.all([getMissingDocs()])
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
    }, 3000)
  }

  const getYearsList = () => {
    let currentYear = new Date().getFullYear()
    let startYear = 1960
    let years = []

    while (startYear <= currentYear) {
      years.push(startYear++)
    }
    setProductionYears(years.sort((a, b) => b - a))
  }

  useEffect(() => {
    getUserInformation()
    getData()
    getYearsList()

    dispatch(hasDistrictData())
  }, [])

  useEffect(() => {
    dispatch(hasVehicleBrand())
    if (brands.length) {
      dispatch(hasVehicleDetails({}))

      if (!pending && status !== null) {
        setSelectedVehicleStatus(status)
        setSelectedVehicleBrand(vehicleBrandId)
        setSelectedVehicleModel(vehicleModel)
        setSelectedVehicleValue(vehicleValue)
        setSelectedVehicleYear(productionYear)
        setSelectedVehicleMilage(mileage)
        setSellersName(name)
        setSellersAddress(address)
        setsellersDistrict(districtId)
      }

      // dispatch(hasVehicleDocs({}))
      dispatch(hasVehiclePictures({})).then((res) => {

      })

      
    }

    // dispatch(hasGetVehiclePictures())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands.length, pending, status])

  useEffect(() => {
    dispatch(getVehicleDocs())
  }, [])

  const onChangeInput = (e, value) => {
    let inputValue = value
    if (value === '' || !value.length) {
      inputValue = '0'
    }
    setSelectedVehicleValue(inputValue)
    dispatch(hasVehicleDetails({ vehicleValue: Number(inputValue) }))
  }

  return !pending ? (
    <Box className={classes.Container}>
      <Typography className={classes.RedTitle}>*Mandatory Upload</Typography>
      <Grid
        container
        sx={{
          '@media (max-width: 800px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%'
            }
          }}
        >
          <FormLabel className={classes.Titles}>VEHICLE STATUS* <span onClick={toggleTooltip}>
                  <LightTooltip title={`Select NEW only when the vehicle mileage does not exceed 40 miles and the vehicle is not older than 3 years.`}
                  arrow
                  placement='right'
                  open={showTooltip}>
                  <img
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleTooltip()
                      }}
                      src={InfoIcon}
                      alt=''
                    />
              </LightTooltip>
                </span></FormLabel>
          <FormControl className={classes.sizeVehicleStatus}>
            <InputLabel
              sx={{ padding: '1rem 0.25rem' }}
              shrink={false}
              id='select-status-label'
            >
              {selectedVehicleStatus === null && 'Select vehicle status'}
            </InputLabel>

            <Select
              labelId='select-status-label'
              sx={{ width: '100%', my: 2 }}
              id={`select-status`}
              value={selectedVehicleStatus}
              onChange={(e) => {
                setSelectedVehicleStatus(status || e.target.value)
                dispatch(hasVehicleDetails({ status: e.target.value }))
              }}
              style={{ display: 'block', cursor: 'default' }}
            >
              {vehicleStatusList.map((status, idx) => (
                <MenuItem
                  value={status}
                  className={classes.SelectionItem}
                  key={idx}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%'
            }
          }}
        >
          <FormLabel className={classes.Titles}>VEHICLE BRAND*</FormLabel>
          <FormControl className={classes.sizeVehicleStatus}>
            <InputLabel
              sx={{ padding: '1rem 0.25rem' }}
              shrink={false}
              id='select-brand-label'
            >
              {selectedVehicleBrand === null && 'Select vehicle brand'}
            </InputLabel>
            <Select
              sx={{ width: '100%', my: 2 }}
              labelId='select-brand-label'
              id={`select-brand`}
              value={selectedVehicleBrand}
              defaultValue='Select Vehicle Status'
              style={{ display: 'block', cursor: 'default' }}
              onChange={(e) => {
                setSelectedVehicleBrand(Number(e.target.value))
                dispatch(
                  hasVehicleDetails({ vehicleBrandId: Number(e.target.value) })
                )
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    height: '190px !important'
                  }
                }
              }}
            >
              {brands.map((brand) => (
                <MenuItem
                  value={brand.id}
                  className={classes.SelectionItem}
                  key={brand.id}
                >
                  {brand.brandName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {selectedVehicleBrand && (
          <Grid
            item
            xs={3}
            sx={{
              mt: 4,
              '@media (max-width: 800px)': {
                maxWidth: '100%'
              }
            }}
          >
            <FormLabel className={classes.Titles}>VEHICLE MODEL*</FormLabel>
            <Input
              value={selectedVehicleModel}
              className={classes.Input}
              sx={{ width: '40%' }}
              type='text'
              onChange={(e) => {
                setSelectedVehicleModel(e.target.value)
                dispatch(hasVehicleDetails({ vehicleModel: e.target.value }))
              }}
              placeholder='Enter vehicle model'
            />
          </Grid>
        )}
        <Grid
          item
          xs={6}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%'
            }
          }}
        >
          <FormLabel className={classes.Titles}>ESTIMATED VEHICLE VALUE*</FormLabel>
          <Currency
            value={selectedVehicleValue}
            className={classes.InputValue}
            type='number'
            // onChange={(e) => {
            //   setSelectedVehicleValue(e.target.value)
            //   dispatch(
            //     hasVehicleDetails({ vehicleValue: Number(e.target.value) })
            //   )
            // }}
            onChange={(e, value) => onChangeInput(e, value)}
            placeholder='Enter vehicle value'
          />
          {/* <Input
            value={selectedVehicleValue}
            className={classes.InputValue}
            type='text'
            onChange={(e) => {
              setSelectedVehicleValue(e.target.value)
              dispatch(
                hasVehicleDetails({ vehicleValue: Number(e.target.value) })
              )
            }}
            placeholder='Enter vehicle value'
          /> */}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%',
              marginTop: '4rem'
            }
          }}
        >
          <FormLabel className={classes.Titles}>PRODUCTION YEAR*</FormLabel>
          <FormControl
            sx={{
              width: '90%',
              '@media (max-width: 800px)': {
                width: '100%'
              }
            }}
          >
            <InputLabel
              sx={{ padding: '1rem 0.25rem' }}
              shrink={false}
              id='select-year-label'
            >
              {selectedVehicleYear === null && 'Select production year'}
            </InputLabel>
            <Select
              labelId='select-year-label'
              sx={{ width: '100%', my: 2 }}
              id={`select-year`}
              value={selectedVehicleYear}
              onChange={(e) => {
                setSelectedVehicleYear(Number(e.target.value))
                dispatch(
                  hasVehicleDetails({ productionYear: Number(e.target.value) })
                )
              }}
              style={{ display: 'block', cursor: 'default' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    height: '190px !important'
                  }
                }
              }}
            >
              {productionYears.map((status, idx) => (
                <MenuItem
                  value={status}
                  className={classes.SelectionItem}
                  key={idx}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%',
              marginBottom: '5rem'
            }
          }}
        >
          <FormLabel className={classes.Titles}>MILEAGE*</FormLabel>
          <Input
            value={selectedVehicleMilage}
            className={classes.Input}
            type='string'
            min={0}
            max='999999'
            onChange={(e) => {
              const value = e.target.value
              console.log(value)
              if (value.length > 1 && Number(value[0]) === 0) return
              if (!/^\d*$/.test(value) || Number(value) < 0) {
                return
              }
              if(e.target.value.length > 6) {
                return
              }
              setSelectedVehicleMilage(e.target.value)
              dispatch(hasVehicleDetails({ mileage: Number(e.target.value) }))
            }}
            placeholder='Enter vehicle mileage'
          />
        </Grid>
      </Grid>
      <Grid style={{ margin: '4rem 0' }}>
        <FormikProvider value={formik}>
          <VechiclePictures
            vehiclePictures={{frontPictureName, backPictureName, interiorPictureName, mileagePictureName}}
            Files={Files}
            formik={formik}
            customerData={customerPrevData}
          />
        </FormikProvider>
      </Grid>

      <Grid
        container
        style={{
          justifyContent: 'space-between',
          marginTop: '2rem',
          flexWrap: 'wrap !important'
        }}
        sx={{
          '@media (max-width: 800px)': {
            flexDirection: 'column'
          }
        }}
      >
        <Grid item xs={12}>
          <Button variant='contained' className={classes.FullButton}>
            SELLER'S INFORMATION
          </Button>
        </Grid>

        <Grid
          item
          xs={3.8}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%'
            }
          }}
        >
          <FormLabel className={classes.Titles}>SELLER'S NAME*</FormLabel>
          <Input
            value={sellersName}
            className={classes.Input}
            type=''
            onChange={(e) => {
              setSellersName(e.target.value)
              dispatch(hasVehicleDetails({ name: e.target.value }))
            }}
            placeholder={`Seller's Name`}
          />
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%',
              marginTop: '3.5rem'
            }
          }}
        >
          <FormLabel className={classes.Titles}>SELLER'S ADDRESS*</FormLabel>
          <Input
            value={sellersAddress}
            className={classes.Input}
            type=''
            onChange={(e) => {
              setSellersAddress(e.target.value)
              dispatch(hasVehicleDetails({ address: e.target.value }))
            }}
            placeholder={`Seller's Address`}
          />
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            mt: 4,
            '@media (max-width: 800px)': {
              maxWidth: '100%',
              marginTop: '3.5rem'
            }
          }}
        >
          <FormLabel className={classes.Titles}>SELLER'S DISTRICT*</FormLabel>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel
              sx={{ padding: '1rem 0.25rem' }}
              shrink={false}
              id='select-district-label'
            >
              {sellersDistrict === null && `Select Seller's District`}
            </InputLabel>
            <Select
              labelId='select-district-label'
              sx={{ width: '100%', my: 2 }}
              id={`select-district`}
              value={sellersDistrict}
              onChange={(e) => {
                setsellersDistrict(Number(e.target.value))
                dispatch(
                  hasVehicleDetails({ districtId: Number(e.target.value) })
                )
              }}
              style={{ display: 'block', cursor: 'default' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    height: '190px !important'
                  }
                }
              }}
            >
              {data.map((district) => (
                <MenuItem
                  value={district.id}
                  className={classes.SelectionItem}
                  key={district.id}
                >
                  {district.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography
            style={{
              backgroundColor: '#F4EBFE',
              textAlign: 'center',
              padding: '1rem',
              marginTop: '2rem',
              color: '#706F6F',
              fontSize: '14px'
            }}
          >
            The seller’s information will be important for us to issue your
            approval letter which may be required for the transfer of title.
          </Typography>
        </Grid>
      </Grid>
      <ArrowButton
        // onClickBackBtn={() => handleBackBtn()}
        onClick={() => {
          formik.submitForm()
          dispatch(
            hasVehicleDetails({
              status: selectedVehicleStatus,
              vehicleBrandId: Number(selectedVehicleBrand),
              vehicleModel: selectedVehicleModel,
              vehicleValue: Number(setSelectedVehicleValue),
              productionYear: Number(selectedVehicleYear),
              mileage: Number(selectedVehicleMilage)
            })
          )
        }}
        link={'/Customer/monthlyIncome'}
        back='/Customer/loan-calculation'
        msg='Fill required fields.'
        disabled={
          selectedVehicleStatus &&
          selectedVehicleBrand &&
          selectedVehicleModel &&
          selectedVehicleYear &&
          selectedVehicleMilage &&
          sellersName &&
          sellersAddress &&
          sellersDistrict
            ? false
            : true
        }
      />
    </Box>
  ) : (
    <Spinner open={pending} />
  )
}

export default VehicleStatus
