import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Jwt from '../../auth/jwt/Jwt'

export const hasLoansToBeRefinanced = createAsyncThunk(
  'appLoansToBeRefinanced/LoansToBeRefinanced',
  async () => {
    const response = await Jwt.getLoansToBeRefinanced()
    return response.data
  }
)

const initialState = {
    loansSelected: [],
    loansForRefinance: [],
    totalPreQualifiedLoanValue: 0,
    preQualifiedNewFund: null
}

export const appLoansToBeRefinancedSlice = createSlice({
  name: 'appLoansToBeRefinanced',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hasLoansToBeRefinanced.fulfilled, (state, action) => {
      state.loansSelected = action.payload.loansSelected
      state.loansForRefinance = action.payload.loansForRefinance
      state.totalPreQualifiedLoanValue = action.payload.totalPreQualifiedLoanValue
      state.preQualifiedNewFund = action.payload.preQualifiedNewFund
    })
  }
})

export default appLoansToBeRefinancedSlice.reducer
